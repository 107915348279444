import React from "react";

const ProgressIcon = () => {
  return (
    <>
   <svg xmlns="http://www.w3.org/2000/svg"  id="Group_2" data-name="Group 2" width="16.6" height="16" viewBox="0 0 16.6 16">
  <defs>
    <clipPath id="clipPath">
      <rect id="Rectangle_1" data-name="Rectangle 1" width="16.6" height="16" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_1" data-name="Group 1" clipPath="url(#clipPath)">
    <path id="Path_1" data-name="Path 1" d="M12.1,0H4.4A4.24,4.24,0,0,0,.2,2.9,8.807,8.807,0,0,0,0,4.6V9.1a4.282,4.282,0,0,0,1.3,3.2,4.325,4.325,0,0,0,2.6,1.3v1a1.387,1.387,0,0,0,.7,1.2,1.445,1.445,0,0,0,.7.2,1.884,1.884,0,0,0,.8-.2l3.2-2.2h2.8a4.678,4.678,0,0,0,1.7-.2,4.284,4.284,0,0,0,2.8-4.2V4.6A4.279,4.279,0,0,0,12.1,0m3.2,9.2a2.954,2.954,0,0,1-2,3,4.121,4.121,0,0,1-1.2.1h-3c-.2,0-.3,0-.4.1L5.3,14.7c0,.1-.1.1-.2,0V13.1c0-.4-.2-.7-.6-.7a3.168,3.168,0,0,1-2.3-.9,3.013,3.013,0,0,1-.9-2.3V4.6c0-.5.1-.9.1-1.3a2.875,2.875,0,0,1,3-2h7.7a3.021,3.021,0,0,1,3.2,3.3Z" fill="currentColor"/>
    <path id="Path_2" data-name="Path 2" d="M11.9,7.785a.894.894,0,0,1-.63-.26.914.914,0,0,1-.26-.62.937.937,0,0,1,.07-.34.972.972,0,0,1,.19-.29.893.893,0,0,1,.97-.19.774.774,0,0,1,.28.19.837.837,0,0,1,.2.29.937.937,0,0,1,.07.34.864.864,0,0,1-.27.62.774.774,0,0,1-.28.19.8.8,0,0,1-.34.07" fill="currentColor"/>
    <path id="Path_3" data-name="Path 3" d="M8.6,7.785a.888.888,0,0,1-.89-.88.937.937,0,0,1,.07-.34.972.972,0,0,1,.19-.29.926.926,0,0,1,1.26,0,.972.972,0,0,1,.19.29.934.934,0,0,1,.06.34.886.886,0,0,1-.25.62.91.91,0,0,1-.29.19.829.829,0,0,1-.34.07" fill="currentColor"/>
    <path id="Path_4" data-name="Path 4" d="M5.2,7.785a.8.8,0,0,1-.34-.07.91.91,0,0,1-.29-.19.9.9,0,0,1-.26-.62.937.937,0,0,1,.07-.34.972.972,0,0,1,.19-.29.91.91,0,0,1,.29-.19.893.893,0,0,1,.97.19.972.972,0,0,1,.19.29.937.937,0,0,1,.07.34.9.9,0,0,1-.26.62.91.91,0,0,1-.29.19.829.829,0,0,1-.34.07" fill="currentColor"/>
  </g>
</svg>
    </>
  );
};

export default ProgressIcon;
