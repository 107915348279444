
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material';
import React, { useContext, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { SavingsGoalValidation } from "../../../common/utils";
import { HeaderFooterContext, StepsContext, LessonStepContext, UserContext } from "../../../Context/Provider";

const SquareHandle = styled('span')(({ theme }) => ({
    display: 'inline-block',
    width: 16,
    height: 16,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
  }));

const PercentageSavePiece = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);
  const { authUser, setAuthUser } = useContext(UserContext);

  const getData = (data: any) => {

    formAction({data});
    triggerStep(true)
  };

  const [percentage, setPercentage] = useState(15);
  const [income, setIncome] = useState(authUser?.yearly_income);
  const [savings,setSavings] = useState(percentage * income/100)

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPercentage(newValue as number);
    setSavings((newValue as number) * income / 100)
  };


  return (
    <div className="mt-4">
    <div className="ml-6 mr-6">
    <Slider
        valueLabelDisplay="on"
        aria-label="custom thumb label"
        value={percentage}
        onChange={handleChange}
        aria-labelledby="income-slider"        
        min={0}
        max={100}
        valueLabelFormat={percentage+ "%"}
      />
      </div>
      <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]">
        if you make ${income.toLocaleString()} each year, you'll contribute ${savings.toLocaleString()} to your retirment plan.
      </div>
      <Button
        removeBottomSpace={true}
        onClick={() => getData({percentage})}
        label="ADD TO PLAN"
        left={true}
      />
    </div>
  );
};

export default PercentageSavePiece;
