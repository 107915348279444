import NextButton from "./accountForms/NextButton"
import Question1Piece, { returnQuestion1Value } from "./investPlanForms/question1Piece";
import Question2Piece, { returnQuestion2Value } from "./investPlanForms/question2Piece";
import Question3Piece, { returnQuestion3Value } from "./investPlanForms/question3Piece";
import Question4Piece, { returnQuestion4Value } from "./investPlanForms/question4Piece";
import Question5Piece, { returnQuestion5Value } from "./investPlanForms/question5Piece";
import Question6Piece, { returnQuestion6Value } from "./investPlanForms/question6Piece";
import ConfirmPlanPiece, { returnConfirmPlanValue } from "./investPlanForms/ConfirmPlanPiece";
import ChooseNewPiece, { returnNewPieceValue } from "./investPlanForms/ChooseNewPiece";

import { getAPIData, postAPIData } from "../../utils/apiClient";


export const investPlanSlides = [
  {
    title: "How to invest your retirement savings",
    key: "start",
    subtitle: "This quiz will help you determine how to invest by assessing your risk tolerance.",
    body: "After opening an account, you will need to choose how to invest your savings. All of the questions in this quiz will be asking about your feelings, attitudes, and desired outcomes.",
    body2:"",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:false,
    currentStep: 0,
    children: (
      <div>
        <NextButton />
      </div>
    ),
    choice: (num: number) => {
      return ("risk-profiles")
    },
  },
  
  {
    title: "Risk Tolerance Profiles",
    key: "risk-profiles",
    subtitle: "",
    body: "Because ALL investments involve risk, including losing your principal, we will be asking questions to evaluate your tolerance for risk. At the end of this quiz, we will identify your tolerance to risk through one of these categories and will build an investment plan based on that.",
    body2:"",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:false,
    currentStep: 0,
    children: (
      <div>
        <li>Very Conservative (conservation of capital)</li>
        <li>Conservative (income)</li>
        <li>Moderate (balanced)</li>
        <li>Aggressive (growth)</li>
        <li>Very Aggressive (aggressive growth)</li>
        <NextButton />
      </div>
    ),
    choice: (num: number) => {
      return ("question-1")
    },
  },
  {
    title: "When do you expect to start drawing on this money?",
    key: "question-1",
    subtitle: "",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <Question1Piece data={{}}
          formAction={({ data }: any) => {
            const investPlan = {
              question1: returnQuestion1Value(data.question1Answer)
            }
            localStorage.setItem(
              "investPlan",
              JSON.stringify(investPlan)
            );
          }} />
      </div>
    ),
    choice: (num: number) => {
      return ("question-2")
    },
  },
  {
    title: "What do you want your investment to do?",
    key: "question-2",
    subtitle: "",
    body: "While considering ALL investments are risky, and the bigger the risk, the bigger the reward.",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <Question2Piece data={{}}
          formAction={({ data }: any) => {
            const storedInvestPlan = localStorage.getItem("investPlan") &&
              JSON.parse(localStorage.getItem("investPlan") || "");

            const investPlan = {
              question2: returnQuestion2Value(data.question2Answer)
            }

            Object.assign(investPlan, storedInvestPlan)

            localStorage.setItem(
              "investPlan",
              JSON.stringify(investPlan)
            );
          }} />
      </div>
    ),
    choice: (num: number) => {
      return ("question-3")
    },
  },
  {
    title: "Assuming normal stock market conditions for the next decade, what do you want to see this investment do?",
    key: "question-3",
    subtitle: "",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <Question3Piece data={{}}
          formAction={({ data }: any) => {
            const storedInvestPlan = localStorage.getItem("investPlan") &&
              JSON.parse(localStorage.getItem("investPlan") || "");
            const investPlan = {
              question3: returnQuestion3Value(data.question3Answer)
            }
            Object.assign(investPlan, storedInvestPlan)
            localStorage.setItem(
              "investPlan",
              JSON.stringify(investPlan)
            );
          }} />
      </div>
    ),
    choice: (num: number) => {
      return ("question-4")
    },
  },
  {
    title: "If the stock market performs unusually poor over the next decade, what do you want to see this investment do?",
    key: "question-4",
    subtitle: "",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <Question4Piece data={{}}
          formAction={({ data }: any) => {
            const storedInvestPlan = localStorage.getItem("investPlan") &&
              JSON.parse(localStorage.getItem("investPlan") || "");
            const investPlan = {
              question4: returnQuestion4Value(data.question4Answer)
            }
            Object.assign(investPlan, storedInvestPlan)
            localStorage.setItem(
              "investPlan",
              JSON.stringify(investPlan)
            );
          }} />
      </div>
    ),
    choice: (num: number) => {
      return ("question-5")
    },
  },
  {
    title: "How do you feel about the next three years' performance of this investment?",
    key: "question-5",
    subtitle: "",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <Question5Piece data={{}}
          formAction={({ data }: any) => {
            const storedInvestPlan = localStorage.getItem("investPlan") &&
              JSON.parse(localStorage.getItem("investPlan") || "");
            const investPlan = {
              question5: returnQuestion5Value(data.question5Answer)
            }
            Object.assign(investPlan, storedInvestPlan)
            localStorage.setItem(
              "investPlan",
              JSON.stringify(investPlan)
            );
          }} />
      </div>
    ),
    choice: (num: number) => {
      return ("question-6")
    },
  },
  {
    title: "How do you feel about the next three months' performance of this investment?",
    key: "question-6",
    subtitle: "",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <Question6Piece data={{}}
          formAction={({ data }: any) => {
            const storedInvestPlan = localStorage.getItem("investPlan") &&
              JSON.parse(localStorage.getItem("investPlan") || "");
            const investPlan = {
              question6: returnQuestion6Value(data.question6Answer)
            }
            Object.assign(investPlan, storedInvestPlan)
            localStorage.setItem(
              "investPlan",
              JSON.stringify(investPlan)
            );
          }} />
      </div>
    ),
    choice: (num: number) => {
      return ("confirm-plan")
    },
  },
  {
    title: "How to invest your retirement savings",
    key: "confirm-plan",
    subtitle: "Your Risk Tolerance",
    body: "Here is what we determined from your answers.",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <ConfirmPlanPiece data={{}}
          formChoice={returnChoice}
          formAction={({ data }: any) => {

            const value = returnConfirmPlanValue(data.confirmPlan)
            localStorage.setItem(
              "confirmPlan",
              JSON.stringify(value)
            );
          }} />
      </div>
    ),
    choice: (num: number) => {
      const confirm = localStorage.getItem("confirmPlan") &&
        JSON.parse(localStorage.getItem("confirmPlan") || "");

      if (confirm === 1) {
        updateRiskTolerance()

        return ("finish")
      }
      return ("new-piece")
    },
  },
  {
    title: "Which risk level matches you more closely?",
    key: "new-piece",
    subtitle: "",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <ChooseNewPiece data={{}}
          formAction={({ data }: any) => {

            let storedInvestPlan = localStorage.getItem("investPlan") &&
              JSON.parse(localStorage.getItem("investPlan") || "");

              storedInvestPlan = {
                ...storedInvestPlan,
                choosenResult: returnNewPieceValue(data.newPiece)

              }
            localStorage.setItem(
              "investPlan",
              JSON.stringify(storedInvestPlan)
            ); 

          }} />
      </div>
    ),
    choice: (num: number) => {
      return ("confirm-plan")
    },
  },

  {
    title: "Perfect, we'll add your risk tolerance to your plan.",
    key: "finish",
    subtitle: "",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack:true,
    currentStep: 0,
    children: (
      <div>
        <NextButton />
      </div>
    ),
    choice: (num: number) => {
      return ("dashboard")
    },
  },

]

export function returnChoice() {

  let investPlan = localStorage.getItem("investPlan") &&
    JSON.parse(localStorage.getItem("investPlan") || "");
  let closestValue = 0;

  
  if (investPlan?.choosenResult) {

    return (investPlan.choosenResult)
  }


  const { question1, question2, question3, question4, question5, question6 } = investPlan;
  const average = (question1 + question2 + question3 + question4 + question5 + question6) / 6;
  closestValue = Math.round(average);

  investPlan = {
    ...investPlan,
    calculatedResult: closestValue,
    choosenResult: closestValue
  }

  localStorage.setItem(
    "investPlan",
    JSON.stringify(investPlan)
  );


  return closestValue;

}





async function updateRiskTolerance () {
  const amount = localStorage.getItem("investPlan") &&
  JSON.parse(localStorage.getItem("investPlan") || "");

  const payload = {
      risk_profile: amount.choosenResult
  }
  
  const res = await postAPIData({
    url: "v1/users/update-ss",
    data: payload,        
  });
  const lesson_payload = {
    invest_profile: true
  }

  const res2 = await postAPIData({
    url: "v1/users/update-lesson",
    data: lesson_payload,        
  });

  localStorage.removeItem("investPlan");


}  