import React from "react";
import check from "../../assets/white-checkmark.png"
import { Outlet, useNavigate } from "react-router-dom";

const DashBoardTasks = ({ taskContent }: { taskContent?: any }) => {
  const navigate = useNavigate();

  function handleClick(lesson: string) {

    navigate("/lesson",  {
      state: {
        lessonId: {lesson},
      }      
    });    
  }
  

  return (
    <div>
      <h2 className="text-[#0B4547] text-[24px] lg:text-[30px] font-bold tracking-[-0.02em] mb-[17px]">
        My Tasks
      </h2>
      <div>
        {taskContent?.map((e: any, i: any) => {
          return (
            <div key={i}>

            <div className="shadow-task rounded-[6px] flex bg-white mb-[10px] border-l-[4px] border-l-[#EA743C] p-[16px] hover:opacity-75 cursor-pointer"

            >
              {!e?.complete &&
              <h4 className="text-base font-medium font-satoshi tracking-[-0.02em] text-[#161616]" onClick={() => handleClick(e.lessonId)}>
                {e?.title}
              </h4>        
              }
              {e?.complete &&
              <h4 className="text-base font-light font-satoshi tracking-[-0.02em] text-[#D3D3D3]" onClick={() => handleClick(e.lessonId)}>
                {e?.title}
              </h4>        
              }
              
              <div className="flex justify-between items-center mt-[-5px]">
                <p className="text-[14px] font-normal font-satoshi tracking-[-0.02em] text-[#161616]">
                  {e?.doneBy}
                </p>

                <div className="flex items-center">


                  {e?.niaId ? 
                    e?.complete ? 
                    <button className="w-[36px] h-[36px] bg-[#EA743C] shadow-user rounded-full ml-[20px] text-white">
                    <img src={check} className="mr-[20px]" />

                  </button>
                    : <button className="w-[36px] h-[36px] bg-[#EA743C] shadow-user rounded-full ml-[20px] text-white">
                    {e?.niaId}
                  </button>
                  : ""
                  }


                </div>

              </div>
          
            </div>
        
          </div>
          );
        })}
      </div>
    </div>
  );
};


export default DashBoardTasks;
