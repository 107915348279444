//import { Link } from "react-router-dom";
//import { useAuth } from "context/user";
import { Outlet, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../Context/Provider";
import { Link } from 'react-router-dom';

import { signOut } from "../cognitoAuth";

function Header() {
    //const { authUser } = useAuth();
    const { authUser, setAuthUser } = useContext(UserContext);
    

    return (
        <>
        <div className="flex justify-between items-center max-w-[1350px] px-[15px] w-full ml-auto mr-auto pt-[20px]">
        <div className="lg:min-w-[124px]"><Link to="https://www.niagrowth.com/" className="cursor-pointer"> <img
                        className="h-[30px]"
                        src="/assets/nia.svg"
                        alt="logo"
                    ></img>
                    </Link></div>
        
        <div className="bg-[#0B4547] rounded-[110px] py-[3px] px-[4px] hidden lg:block">
            <ul className="flex items-center rounded-[110px] gap-[25px]">
                <li>
                    <a className="transition-all hover:bg-white shadow-active hover:text-[#0B4547] !bg-white !text-[#0B4547] !font-bold   text-[16px]  rounded-[110px] cursor-pointer font-normal tracking-[-0.02em] text-white font-satoshi block py-[9.5px] px-[27px]">Home</a>
                </li>
                <li>
                    <a href="about-us" className="transition-all hover:bg-white hover:text-[#0B4547]  text-[16px] rounded-[110px]  cursor-pointer font-normal tracking-[-0.02em] text-white font-satoshi block py-[9.5px] px-[27px]">About Us</a>
                </li>
                <li>
                    <a href="learn" className="transition-all hover:bg-white hover:text-[#0B4547]  text-[16px] rounded-[110px]  cursor-pointer font-normal tracking-[-0.02em] text-white font-satoshi block py-[9.5px] px-[27px]">Learn</a>
                </li>
                <li>
                    <a href="partners" className="transition-all hover:bg-white hover:text-[#0B4547]  text-[16px] rounded-[110px]  cursor-pointer font-normal tracking-[-0.02em] text-white font-satoshi block py-[9.5px] px-[27px]">Partners</a>
                </li>
            </ul>
        </div>
        
        <div className="flex gap-6">
        {/* <div className="hidden lg:block"><a className="tracking-[-0.02em]  font-medium pb-[8px] text-base text-[#161616]  cursor-pointer font-satoshi hover:underline-offset-[10px] transition-all underline underline-offset-[12px]" >Get the free guide</a></div> */}
        {/* <div className="hidden lg:block"><a className="tracking-[-0.02em]  font-medium pb-[8px] text-base text-[#161616]  cursor-pointer font-satoshi hover:underline-offset-[10px] transition-all underline underline-offset-[12px]" onClick={signOut}>Sign Out</a></div> */}
        {authUser ? 
        <div className=""><a className="tracking-[-0.02em]  font-medium pb-[8px] text-base text-[#161616]  cursor-pointer font-satoshi hover:underline-offset-[10px] transition-all underline underline-offset-[12px]" onClick={signOut}>Sign Out</a></div> :
        <div className=""><Link className="tracking-[-0.02em]  font-medium pb-[8px] text-base text-[#161616]  cursor-pointer font-satoshi hover:underline-offset-[10px] transition-all underline underline-offset-[12px]" to="/auth/sign-in">Sign In</Link></div> 
        
        }
        </div>
        
        {/* <button className="lg:hidden">
                        <img
                            src={"/assets/menu.png"}
                            alt="menu"
                            className="h-[2.25rem]"
                        />
                    </button>                                                                     */}
        
        </div>
    
        </>
    );
}

export default Header;
