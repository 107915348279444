import React from 'react';
import ReactDOM from 'react-dom/client';
import { calculateSocialSecurityAdjustments, calculateSocialSecurity, getBirthYear} from './socialSecurityMath';
import { ProposalInfo,Constants,Rates, LifestyleOptions, LifestyleAdjustments, HousingOptions,HousingAdjustments } from './common/settings';





export function annualIncome(amount:number,unit:string) {
  return( unit === "Weekly" ? 
  amount * 52 :
  unit === "Monthly" ?
  amount * 12 :
  amount
  )
}


export function calculateTaxSavings(futureSave: number) {
    return futureSave * Rates.TAX_RATE    
}

export function MagicProposal(age: number, ss:number,retirementAge:number,sideJobIncome:number, futureSaving:number, currentSavings:number) {
  let socialSecurityInfo = calculateSocialSecurityAdjustments(ss, retirementAge,getBirthYear(age),sideJobIncome)
  let haveBreakdown = figureHave(socialSecurityInfo,futureSaving,currentSavings,sideJobIncome,retirementAge,age) 
  return haveBreakdown
}


export function figureHave(socialSecurityInfo: { base: number; retAgeAdjustment: number; sidejobAdjustment: number; totalSocialSecurity: number; },
  futureSave: number, savings: number,sideJob: number,retirementAge: number, Age: number) {
  let haveBreakdown = { 
      yearsToRetirement :getYearsToRetirement(retirementAge, Age),
      yearsAfterRetirement: retirementAge >= Age ? getYearsAfterRetirement(Constants.LIFEEXPECTANCY, retirementAge) :1,
      sideJob : sideJob,
      socialSecurityTotal : calculateSocialSecurity(socialSecurityInfo),
      currentSavingsTotal : 0,
      futureSavingsTotal : 0,        
      totalSavingsPerYear: 0,
      futurePerYearAfterRetirement : 0,
      totalHavePerYear : 0
  }

  let savingsBreakdown = figureSavingsPerYear(futureSave,savings,haveBreakdown.yearsToRetirement,haveBreakdown.yearsAfterRetirement,Rates.INTEREST_RATE,Rates.INTEREST_RATE_AFTER_RETIREMENT,Rates.INFLATION_RATE)
  haveBreakdown.currentSavingsTotal = savingsBreakdown.currentSavingsTotal
  haveBreakdown.futureSavingsTotal = savingsBreakdown.futureSavingsTotal
  haveBreakdown.totalSavingsPerYear = savingsBreakdown.perYearAfterRetirement
  haveBreakdown.futurePerYearAfterRetirement = savingsBreakdown.fvPerYearAfterRetirement

  haveBreakdown.totalHavePerYear = Math.round(haveBreakdown.socialSecurityTotal + 
                          haveBreakdown.sideJob + 
                          haveBreakdown.totalSavingsPerYear)
  
  return haveBreakdown
}



export function figureSavingsPerYear(savingsPerYear:number,
                savings:number,
                yearsToRetirement:number,
                YearsAfterRetirement:number, 
                interestRate:number,
                interestRateAfterRetirement:number,
                inflationRate:number) {
	
    // savings per year
    let savingsBreakdown = {
        futureSavingsTotal : 0,
        currentSavingsTotal : 0,
        combinedSavings : 0,
        fvPerYearAfterRetirement :0,
        perYearAfterRetirement : 0
    } 

    savingsBreakdown.futureSavingsTotal = futureValue(0,interestRate/100, yearsToRetirement,savingsPerYear)
    savingsBreakdown.currentSavingsTotal = futureValue(savings,interestRate/100,yearsToRetirement,0)
    savingsBreakdown.combinedSavings = savingsBreakdown.futureSavingsTotal + savingsBreakdown.currentSavingsTotal;

    //how long will the pile of money last
    savingsBreakdown.fvPerYearAfterRetirement = -1 * PMT(interestRateAfterRetirement/100,YearsAfterRetirement,savingsBreakdown.combinedSavings,0,0)
    savingsBreakdown.perYearAfterRetirement = (presentValue(savingsBreakdown.fvPerYearAfterRetirement,inflationRate/100,yearsToRetirement))
    


    return(savingsBreakdown)
}

export function figureWant(income:number,lifestyle:string,housing:string) {
  
  const lifestyleAmount = LifestyleAdjustments[LifestyleOptions.indexOf(lifestyle)]
  const housingAmount = HousingAdjustments[HousingOptions.indexOf(housing)]
  
  return(income + (income * (lifestyleAmount+housingAmount)))
}
 

export function getYearsToRetirement (retirementAge: number, age: number) {
    return (age < retirementAge ? (retirementAge - age):1)    
}

export function getYearsAfterRetirement(lifeExpectancy: number, retirementAge: number) {
    return (retirementAge < lifeExpectancy? (lifeExpectancy - retirementAge) : 1)
}


function futureValue(principal:number, returnRate:number, years:number, annualPayments:number) {
    let futureValue = principal * Math.pow(1 + returnRate, years);
    futureValue += annualPayments * ((Math.pow(1 + returnRate, years) - 1) / returnRate);
    return futureValue;
}

function presentValue(amount:number, inflationRate:number, years:number) {
    let pv = amount / Math.pow(1 + inflationRate, years);
    return pv;
}


function PMT(rate:number, nper:number, pv:number, fv:number, type:number) {
    if (!fv) {
      fv = 0;
    }
    if (!type) {
      type = 0;
    }
  
    if (rate == 0) {
      return -(pv + fv) / nper;
    }
  
    const pvif = Math.pow(1 + rate, nper);
    let pmt = rate / (pvif - 1) * -(pv * pvif + fv);
  
    if (type == 1) {
      pmt /= (1 + rate);
    };
  
    return pmt;
  }

  export function returnYearlyIncome(income?: number, unit?: string) {
    const incomeNumber = Number(income);
    if (!incomeNumber) return 0;
  
    if (unit === "Monthly") {
      return incomeNumber * 12;
    }
  
    if (unit === "Yearly") {
      return incomeNumber;
    }
  
    if (unit === "Weekly") {
      return incomeNumber * 52;
    }
    return 0;
  }
  
  export function returnRangeText(data: any) {
    let ssAdjustmentText = "";
    let ssSideJobAdjustmentText = "";

    if (data?.socialSecurityInfo?.retAgeAdjustment < 1) {
      ssAdjustmentText =
        "Early Retirement reduction $" +
        Math.round(
          data?.socialSecurityInfo?.base *
            (1 - data?.socialSecurityInfo?.retAgeAdjustment)
        );
    } else if (data?.socialSecurityInfo?.retAgeAdjustment > 1) {
      ssAdjustmentText =
        "Late Retirement Credit $" +
        Math.round(
          -1 *
            data?.socialSecurityInfo?.base *
            (1 - data?.socialSecurityInfo?.retAgeAdjustment)
        );
    }
  
    if (data?.socialSecurityInfo?.sidejobAdjustment > 0) {
      ssSideJobAdjustmentText +=
        "Retirement job reduction $" +
        Math.round(data?.socialSecurityInfo?.sidejobAdjustment);
    }
    const totalHavePerYear = figureHave(data?.socialSecurityInfo,
      data?.futureSaving,
      data?.currentSavings,
      data?.postRetirementJob,data?.retirementAge,data?.ageBounds?.Age).totalHavePerYear  
    const totalWant = Math.round(figureWant(Number(data?.Income),data?.Lifestyle,data?.Housing))
  


    return {
      ssAdjustmentText,
      ssSideJobAdjustmentText,
      totalHavePerYear,
      totalWant
    };
  }