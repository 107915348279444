import { useState, useContext } from "react";
import { getAPIData, postAPIData } from "../../utils/apiClient";
import { Button } from "../../components/Button";
import CreateAccountPiece from "../lessonContent/accountForms/currentAccounts";
import TenthPiece from "../onBoardingForms/TenthPiece";
import { HeaderFooterContext, StepsContext, LessonStepContext } from "../../Context/Provider";
import NextButton from "../lessonContent/accountForms/NextButton"
import TaxStatus from "../lessonContent/accountForms/TaxStatus"
import SingleEmployerPlan from "../lessonContent/accountForms/SingleEmployerPlanPiece"
import MarriedEmployerPlan from "../lessonContent/accountForms/MarriedEmployerPlanPiece"
import IraAGIPiece from "../lessonContent/accountForms/IraAGIPiece"
import IraCommitment from "./accountForms/IraCommitment";
import SavingsGoal from "./accountForms/SavingsGoal";
import BusinessIncome from "./accountForms/BusinessIncome";
import Employees from "./accountForms/Employees";
import {returnTaxStatusValue} from "../lessonContent/accountForms/TaxStatus"

export const accountSlideOrder = [
    "start",
    "second"
]


export const accountSlides = [
    {
        title:"Choose the Right Retirement Account",
        key:"start",
        subtitle:"",
        body:"Welcome to our Guided Session! We will help you plan for retirement grow your wealth, and select an account that can help maximize your tax benefits.",
        body2:"",
        link:"",
        linkText:"",
        image:"/assets/screen-puzzle.svg",
        hideBack:false,        
        currentStep:0,
        children: (
            <div>
                <NextButton/>
          </div>
          ),
          choice: (num: number) => {
            return("current-accounts")
        },

    },
    {
        title:"Which accounts do you already have?",
        key:"current-accounts",
        subtitle:"Check all accounts that you have open even if you are not actively contributing to them.",
        body: "Tax-advantaged retirement accounts are designed to help individuals save for retirement while enjoying certain tax advantages.",
        body2: "",
        image:"/assets/screen-puzzle.svg",
        hideBack:true,        
        currentStep:0,
        helpText:"",
        helpTextBody:"",
        children: (
            <div>
            
            <CreateAccountPiece data={{}}
             formAction={({ data }: any) => {                          
              const accountChoice = {
                retAccts: {...data}
              }
               localStorage.setItem(
                 "accountChoice",
                 JSON.stringify(accountChoice)
               );

             }} />
            </div>
          ),

          choice: (num: number) => {
            const accts = localStorage.getItem("accountChoice") &&
            JSON.parse(localStorage.getItem("accountChoice") || "");  
            if (!accts.retAccts.checkboxes[0] && !accts.retAccts.checkboxes[1]) {
              return("ira-path") //they have no iras
            } else if (!accts.retAccts.checkboxes[2] && !accts.retAccts.checkboxes[3])  {
              return("sep-solo-path") //no sep or solo ira
            }
            //they have it all!
            return ("complete-path")
            
            
        },

    },
    {
        title:"Traditional IRA vs. Roth IRA",
        key:"ira-path",
        subtitle:"Understanding the Key Differences",
        body:"Traditional IRA: Contributions made with pre-tax dollars, tax-deferred growth, taxed upon withdrawal, RMDs (required minimum distributions) at age 72, eligibility based on income and participation. ",
        body2:"Roth IRA: Contributions made with after-tax dollars, tax-free growth, tax-free qualified withdrawals, no RMDs (required minimum distributions), income limits for direct contributions, backdoor option for higher earners.",
        image:"/assets/screen-puzzle.svg",
        hideBack:true,        
        currentStep:0,

        children: (
          <div>
            <NextButton/>
          </div>
          ),
        choice: (num: number) => {
            return("ira-tax-status")
        },
    },

  {
    title:"You are set up already",
    key:"complete-path",
    subtitle:"You have all of the accounts set up.",
    body:"Time to get saving!",
    image:"/assets/screen-puzzle.svg",
    hideBack:true,        
    currentStep:0,
    children: (            
      <div>
      <NextButton/>
      </div>
      ),
    choice: (num: number) => {
      updateLessonStatus()
        return("dashboard")
    },
},
{
  title:"What is your tax filing status?",
  key:"ira-tax-status",
  subtitle:"We use this to determine your account eligibility.",
  body:"Once we know what accounts you might qualify for, we will explain how they work. You can choose which one to add to your plan.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (
    <div>
    
    <TaxStatus data={{}}
     formAction={({ data }: any) => {    
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
              JSON.parse(localStorage.getItem("accountChoice") || "");

      const accountChoice = {
        ...storedAccountChoice,
        taxStatus: data.taxStatus
      }
       localStorage.setItem(
         "accountChoice",
         JSON.stringify(accountChoice)
       );


      
     }} />
    </div>
  ),
  choice: (num: number) => {
    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");  

    
    if (returnTaxStatusValue(accts.taxStatus) === 1) {
      return ("single-employer-plan")
    }
    return("married-employer-plan")
  },
},
{
  title:"Are you able to contribute to an employer retirement plan?",
  key:"single-employer-plan",
  subtitle:"File status: single",
  body:"Are you able to commit to an employer retirement plan?",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (
    <div>
    
    <SingleEmployerPlan data={{}}
     formAction={({ data }: any) => {            
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
              JSON.parse(localStorage.getItem("accountChoice") || "");

      const accountChoice = {
         ...storedAccountChoice,
        employerPlan: data.employerPlan
      }
      
       localStorage.setItem(
         "accountChoice",
         JSON.stringify(accountChoice)
       );
     }} />
    </div>
  ),
  choice: (num: number) => {
    // const status = localStorage.getItem("taxStatus") &&
    // JSON.parse(localStorage.getItem("taxStatus") || "");           

      return("ira-agi")
  },
},
{
  title:"Are you able to commit to an employer retirement plan?",
  key:"married-employer-plan",
  subtitle:"File status: married",
  body:"We are only interested in whether this is an option for you, not whether you have opened one or not.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (
    <div>
    
    <MarriedEmployerPlan data={{}}
     formAction={({ data }: any) => { 
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
              JSON.parse(localStorage.getItem("accountChoice") || "");

      const accountChoice = {
        ...storedAccountChoice,
        employerPlan: {...data}
      }
       localStorage.setItem(
         "accountChoice",
         JSON.stringify(accountChoice)
       );
   
  
     }} />
    </div>
  ),
  choice: (num: number) => {
    // const status = localStorage.getItem("taxStatus") &&
    // JSON.parse(localStorage.getItem("taxStatus") || "");           

    return("ira-agi")
    },
},
{
  title:"What is your AGI?",
  key:"ira-agi",
  subtitle:"",
  body: "Adjusted Gross Income (AGI) is the total income earned by an individual or household minus specific deductions allowed by the tax code, used to determine taxable income. We use this range to determine your eligibility for this type of retirement account.",
  body2:"Your Adjusted Gross Income (AGI) is on line 11 of Form 1040.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (
    <div>    
         
    <IraAGIPiece data={{}}
     formAction={({ data }: any) => {    
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
      JSON.parse(localStorage.getItem("accountChoice") || "");

    const accountChoice = {
    ...storedAccountChoice,
    iraAccountLimits: {...data}
    }      
       localStorage.setItem(
         "accountChoice",
         JSON.stringify(accountChoice)
       );       
     }} />
    </div>
  ),
  choice: (num: number) => {
    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");  


    if (accts.iraAccountLimits?.traditional && accts.iraAccountLimits?.roth) {
      return("both-ira")
    } else if (accts.iraAccountLimits?.traditional === true) {            
      return("traditional-only")
    }
    //note: roth-only should not happen; you can't have a roth but not a traditional because income limits
    
    return ("no-ira")
  },
},
{
  title:"You qualify for a Traditional IRA",
  key:"traditional-only",
  subtitle:"",
  body:"It looks like you may be eligible to contribute to a Traditional IRA, but not a Roth IRA.",
  body2:"We'll explain more about Traditional IRA's on the next screen.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>    
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
    return("traditional")     
  },
},
{
  title:"You qualify for a both a Traditional and Roth IRA",
  key:"both-ira",
  subtitle:"",
  body:"It looks like you may be eligible to either type of IRA.",
  body2:"We'll explain more about both types on the next screens.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>    
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
    return("traditional")     
  },
},
{
  title:"Traditional IRA Information",
  key:"traditional",
  subtitle:"",
  body:"Traditional individual retirement accounts (IRAs)offers the advantage of pre-tax contributions, allowing you to lower your taxable income by the amount you contribute to your account. This means that you can reduce the portion of your income subject to taxation.",
  body2:"A Traditional IRA allows for pre-tax contributions, lowering your taxable income. The funds grow tax-deferred until retirement withdrawals, which are taxed as ordinary income. At age 73, required minimum distributions (RMDs) begin, regardless of financial need.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
         

    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");  

    if (accts.iraAccountLimits.roth === true) {
      return("roth")
    }
    return ("ira-commitment")
  },
},
{
  title:"Roth IRA Information",
  key:"roth",
  subtitle:"",
  body:"A Roth IRA is a retirement savings account where you contribute money that has already been taxed, and any future withdrawals are generally tax-free. It allows your savings to grow over time without incurring additional taxes on the earnings, providing potential tax advantages during retirement.",
  body2:"Individuals who anticipate being in a higher tax bracket during retirement or those seeking tax-free withdrawals in retirement may benefit more from a Roth IRA. Additionally, younger individuals with a longer time horizon for investment growth often find a Roth IRA advantageous due to the potential for tax-free compounded earnings over the years.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
      return("ira-commitment")
  },
},
{
  title:"No IRA Option",
  key:"no-ira",
  subtitle:"",
  body:"It looks like you might not qualify for any IRA, but we may still have an option for you.",
  body2:"Take a look at our advanced plans - a SEP IRA or Solo 401k.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || ""); 
    
    if (!accts.retAccts.checkboxes[2] && !accts.retAccts.checkboxes[3]) {      
      return("sep-solo-path")
    }
    updateLessonStatus()
    return("dashboard")
  },
},
{
  title:"Which account will you open?",
  key:"ira-commitment",
  subtitle:"",
  body:"Shall we add information about an IRA to your plan?",
  body2:"If you aren't sure yet, you can add the information and decide later.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <IraCommitment data={{}}
     formAction={({ data }: any) => {    
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
              JSON.parse(localStorage.getItem("accountChoice") || "");

      const accountChoice = {
        ...storedAccountChoice,
        iraCommitment: data.picked
        }      

       localStorage.setItem(
         "accountChoice",
         JSON.stringify(accountChoice)
       );
       
       updateAccountChoice()
     }} />
    </div>    
    ),
  choice: (num: number) => {
    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");  

    if (!accts.retAccts.checkboxes[2] && !accts.retAccts.checkboxes[3]) {      
      return("sep-solo-path")
    }
    updateLessonStatus()
    return("dashboard")
  },
},
{
  title:"Advanced Retirement Accounts",
  key:"sep-solo-path",
  subtitle:"Have you heard about SEP-IRA and Solo 401(k) plans?",
  body:"They're fantastic retirement savings options for self-employed individuals, offering incredible tax advantages!",
  body2:"We'll ask a few questions to see which might be a fit for you.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
        <NextButton/>
    </div>
    ),
    choice: (num: number) => {
      return("savings-goal")
  },
},
{
  title:"Would you like to save more than $7000 per year for retirement?",
  key:"savings-goal",
  subtitle:"",
  body2:"Most financial advisors commonly recommend saving around 15% of your income for retirement to ensure a secure and comfortable future.",
  image:"/assets/screen-puzzle.svg",
  link2:"https://www.fidelity.com/viewpoints/retirement/how-much-money-should-I-save",
  link2text:"Most financial advisors",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div> <SavingsGoal data={{}}
    formAction={({ data }: any) => {     
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
              JSON.parse(localStorage.getItem("accountChoice") || "");

      const accountChoice = {
        ...storedAccountChoice,
        savingMore: data.savingMore
      }             
      localStorage.setItem(
        "accountChoice",
        JSON.stringify(accountChoice)
      );       
    }} />

    </div>
    ),
  choice: (num: number) => {

    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");  

    if (accts.savingMore === "Yes") {
      return("advanced-info")
    }
      return("not-ready")
  },
},
{
  title:"Great Start",
  key:"not-ready",
  subtitle:"We understand you may not be ready for this information",
  body:"We will have it here for when you are ready!",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {  
    updateLessonStatus()
      return("dashboard")
  },
  
},
{
  title:"Level Up Your Retirement Game: SEP-IRA and Solo 401(k)",
  key:"advanced-info",
  subtitle:"",
  body:"SEP-IRA and Solo 401(k) plans are truly exciting retirement savings options for self-employed individuals.",
  body2:"With SEP-IRA and Solo 401(k) plans, you can contribute substantial amounts, up to $66,000 per year or up to 20% of your income, based on your earnings, providing an exciting opportunity to supercharge your retirement savings.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
      return("business-income")
  },  
},
{
  title:"Do you have a business that produces income (profit)?",
  key:"business-income",
  subtitle:"",
  body:"",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <BusinessIncome data={{}}
    formAction={({ data }: any) => {           
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
      JSON.parse(localStorage.getItem("accountChoice") || "");

      const accountChoice = {
      ...storedAccountChoice,
      businessIncome: data.businessIncome
      }
            
      localStorage.setItem(
        "accountChoice",
        JSON.stringify(accountChoice)
      );       
    }} />
    </div>
    ),
  choice: (num: number) => {
    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");  

    if (accts.businessIncome === "Yes") {
      return("employees")
    }
    return("not-ready")
  },  
},
{
  title:"Do you have any employees, besides yourself and your spouse?",
  key:"employees",
  subtitle:"",
  body:"",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <Employees data={{}}
    formAction={({ data }: any) => {     
      
      const storedAccountChoice = localStorage.getItem("accountChoice") &&
      JSON.parse(localStorage.getItem("accountChoice") || "");

      let accountChoice = {
      ...storedAccountChoice,
      employees: data.employees
      }

      if (data.employees === "Yes") {
        accountChoice = {
          ...accountChoice,
          advancedCommitment: "Sep IRA"
          }      
  
      } else {
        accountChoice = {
          ...accountChoice,
          advancedCommitment: "Solo 401k"
          }      
          
      }
    
      localStorage.setItem(
        "accountChoice",
        JSON.stringify(accountChoice)
      ); 
      
      updateAccountChoice()
    }} />
    </div>
    ),
  choice: (num: number) => {

    const accts = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");  

    if (accts.employees === "Yes") {      
      return("sep-choice")
    }
    return("solo-choice")
  },  
},
{
  title:"We recommend you explore a SEP IRA.",
  key:"sep-choice",
  subtitle:"",
  body:"We’ve added this to your plan. You can access your plan from your dashboard.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
    updateLessonStatus()
      return("dashboard")
  },  
},
{
  title:"We recommend you explore a Solo 401k",
  key:"solo-choice",
  subtitle:"",
  body:"We've added this recommendation to your plan. You can access your plan from your dashboard.",
  image:"/assets/screen-puzzle.svg",
  hideBack:true,        
  currentStep:0,
  children: (            
    <div>
    <NextButton/>
    </div>
    ),
  choice: (num: number) => {
    updateLessonStatus()
      return("dashboard")
  },  
},
]


function choice(num: number): string {
    if (num === 1) {
      return "Choice 1";
    } else if (num === 2) {
      return "Choice 2";
    } else {
      return "Invalid choice";
    }
  }

  
async function updateAccountChoice () {
  const result = localStorage.getItem("accountChoice") &&
  JSON.parse(localStorage.getItem("accountChoice") || "");


  const payload = {
      advanced_suggestion:result?.advancedCommitment || "",
      ira_suggestion:result?.iraCommitment || ""
  }
  
  
  const res = await postAPIData({
    url: "v1/users/update-ss",
    data: payload,        
  });

  localStorage.removeItem("accountChoice");

}  

async function updateLessonStatus() {
  
  const lesson_payload = {
    account_choice: true
  }

  const res2 = await postAPIData({
    url: "v1/users/update-lesson",
    data: lesson_payload,        
  });

}