import { FormControlLabel, Radio } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { IraCommitmentValidation } from "../../../common/utils";
import { LessonStepContext } from "../../../Context/Provider";
import { IRA_LIMITS, Rates, ProposalInfo, RetirementInfo, HousingAdjustments, LifestyleAdjustments } from "../../../common/settings";
import { returnTaxStatusValue } from "./TaxStatus";
import {returnMarriedEmployerPlanValue} from "./MarriedEmployerPlanPiece"
import {returnSingleEmployerPlanValue} from "./SingleEmployerPlanPiece"




const IraCommitment = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);

  const [selectedValue, setSelectedValue] = useState<any>(
    data?.acctChoice || ""
  );
  const [defaultValues, setDefaultValues] = useState<any>({
    acctChoice: data?.acctChoice || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(IraCommitmentValidation),
  });
  
  const getData = (data: any) => {
  

    if (data.acctChoice === "Traditional") {
        data.picked = "Traditional"        
    } else if (data.acctChoice === "Roth") {
        data.picked = "Roth"
    } else if (data.acctChoice === "Neither"){
        data.picked = "None"
    } else {
      data.picked = "Both"
    }
    formAction({ data });
    triggerStep(true)
  };
  const [optionValues,setOptionValues] = useState<any>([])


  useEffect(() => {
    const iraQualify = localStorage.getItem("accountChoice") &&
    JSON.parse(localStorage.getItem("accountChoice") || "");           
    let choices = []

    if (iraQualify.iraAccountLimits?.traditional) {
        choices.push("Traditional IRA")
    }
    if (iraQualify.iraAccountLimits?.roth) {
        choices.push("Roth IRA")
    }
    choices.push("Neither")
    if (iraQualify.iraAccountLimits?.traditional && iraQualify.iraAccountLimits?.roth) {
      choices.push("Please include information for both")
    }
    setOptionValues(choices)

},[])


  return (
    <div>
      <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]"></div>
      {optionValues?.map((e: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <FormControlLabel
              className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="acctChoice"
                  render={({ field, ...props }) => {
                    return (
                      <Radio
                        checked={e === selectedValue}
                        onChange={() => {
                          field .onChange(e);
                          setSelectedValue(e);                                                    
                        }}
                      />
                    );
                  }}
                />
              }
              label={e}
            />
          </React.Fragment>
        );
      })}
      <span>
        {errors?.["acctChoice"] && (
          <p className="text-green-600 text-[12px] mt-[5px]">
            {errors?.["acctChoice"]?.message?.toString() || ""}
          </p>
        )}
      </span>
      <Button
        removeBottomSpace={true}
        onClick={handleSubmit(getData)}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default IraCommitment;
