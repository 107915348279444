import React from 'react'
import classNames from 'classnames';

const RoundedButton = ({ onClick, text, variant, buttonClasses }: { onClick?: () => void; text?:any; variant?: "small" | "medium" | "large", buttonClasses?: string }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={classNames(
          "bg-[#0B4547] text-white hover:opacity-75 py-[11px] px-[20px] font-medium transition-all text-base cursor-pointer text-center tracking-[-0.02em] rounded-[62px]",
          {
            "py-[8px]": variant === "small",
            "px-[15px]": variant === "small",
            "text-sm": variant === "small"
          },
          buttonClasses
        )}
      >
        {text}
      </button>
    </div>
  );
};

export default RoundedButton