import * as yup from "yup";
import {Boundaries} from "../common/settings"
import { number } from "yup";

const IncomeUnitBoundaries = {
  Yearly: Boundaries?.MININCOME,
  Monthly: Boundaries?.MININCOME/12,
  Weekly: Boundaries?.MININCOME/52  
};

export const SavingsValidation = yup.object().shape({  
  value: yup
    .number()
    .typeError("Number is required")
});

export const FirstPieceValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  age: yup
    .number()
    .typeError("Age is required")
    .min(Boundaries.MINAGE, "You must be " + Boundaries?.MINAGE?.toString() + " years or older")
    .max(Boundaries.MAXAGE),
});
export const SecondPieceValidation = yup.object().shape({
  unit: yup.string().required("Unit is required"),
  expense: yup
    .number()
    .typeError("Income is required.")
    .min(Boundaries.MININCOME, "Our calculations require a minimum of $"+Boundaries?.MININCOME?.toString())  

    .test({
      name: "income-min",
      test: function(this:any) {
        const unit : keyof typeof IncomeUnitBoundaries = this.parent.unit
        const numberAmount = this.parent.income        

        if (numberAmount >= IncomeUnitBoundaries[unit]) return true;
        
        return this.createError({
          path: "unit",
          message: "Our calculations require a minimum of $"+Boundaries?.MININCOME?.toString() + " per year",
        })
      }})
    .max(Boundaries.MAXINCOME, "Maximum value is $"+Boundaries?.MAXINCOME?.toString()),
});
export const ThirdPieceValidation = yup.object().shape({
  range: yup
    .number()
    .typeError("Retirement savings are required")
    .min(0, "Retirement savings should be a positive."),
  other: yup
    .number()
    .typeError("Other savings value required")
    .min(0, "Other savings should be positive."),
});
export const NinethPieceValidation = yup.object().shape({
  selectOption: yup.string().required("Please select an option to continue"),
});
export const EighthPieceValidation = yup.object().shape({
  selectOption: yup.string().required("Please select an option to continue"),
});
export const TenthPieceValidation = yup.object().shape({
  selectOption: yup.string().required("Please select an option to continue"),
});

export const FifteenthPieceValidation = yup.object().shape({
  socialsecurity: yup.number().typeError("Social Security is required.").min(1, 'Minimum value should be 1')
});

export const CurrentAccountValidation = yup.object().shape({  
  checkboxes: yup.array().of(yup.boolean().required()),

});

export const TaxStatusValidation = yup.object().shape({
  taxStatus: yup.string().required("Please select an option to continue"),
});

export const EmployerStatusValidation = yup.object().shape({
  employerPlan: yup.string().required("Please select an option to continue"),
});

export const IraAGIValidation = yup.object().shape({
  agiLimit: yup.string().required("Please select an option to continue"),
});

export const IraCommitmentValidation = yup.object().shape({
  acctChoice: yup.string().required("Please select an option to continue"),
});

export const SavingsGoalValidation = yup.object().shape({
  savingMore: yup.string().required("Please select an option to continue"),
});

export const BusinessIncomeValidation = yup.object().shape({
  businessIncome: yup.string().required("Please select an option to continue"),
});

export const EmployeesValidation = yup.object().shape({
  employees: yup.string().required("Please select an option to continue"),
});

export const Question1Validiation = yup.object().shape({
  question1Answer: yup.string().required("Please select an option to continue"),
});


export const Question2Validiation = yup.object().shape({
  question2Answer: yup.string().required("Please select an option to continue"),
});


export const Question3Validiation = yup.object().shape({
  question3Answer: yup.string().required("Please select an option to continue"),
});

export const Question4Validiation = yup.object().shape({
  question4Answer: yup.string().required("Please select an option to continue"),
});

export const Question5Validiation = yup.object().shape({
  question5Answer: yup.string().required("Please select an option to continue"),
});


export const Question6Validiation = yup.object().shape({
  question6Answer: yup.string().required("Please select an option to continue"),
});

export const ConfirmPlanValidation = yup.object().shape({
  confirmPlan: yup.string().required("Please select an option to continue"),
});

export const newPieceValidation = yup.object().shape({
  newPiece: yup.string().required("Please select an option to continue"),
});