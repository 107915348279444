import React from "react";
import { Button } from "../Button";

const SixthPiece = ({ formAction }: { formAction: Function }) => {
  return (
    <div>
      <Button
        onClick={() => {
          formAction();
        }}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default SixthPiece;
