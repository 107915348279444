import Slider from "@mui/material/Slider";
import React from "react";
import { Tooltip } from "@mui/material";

const RangeSlider = ({
  title,
  subtitle,
  bottomLeftText,
  bottomRightText,
  lastLeftText,
  lastRightText,
  tooltip,
  min,
  max,
  unit,
  defaultValue,
  step,
  name,
  data,
  touched,
  setTouched = () => {},
  setData = () => {},
  disabled,
}: {
  title?: string;
  subtitle?: string;
  bottomLeftText?: string;
  bottomRightText?: string;
  lastLeftText?: string;
  lastRightText?: string;
  tooltip?:string;
  min?: number;
  max?: number;
  unit?: string;
  defaultValue?: number;
  step?: number;
  name: string;
  touched?: any;
  setTouched?: Function;
  data?: any;
  setData?: Function;
  disabled?:any;
}) => {
  const numberData = (sliderData: any) => {
    if (!unit) {
      return sliderData;
    }
    if (sliderData >= 1000) {
      sliderData = sliderData / 1000;
      if (sliderData >= 1000) {
        sliderData = sliderData / 1000 + "M";
      } else {
        sliderData = Number(sliderData.toFixed(1)) + "k";
      }
    }
    let x = `${unit}${sliderData}`;
    return x;
  };
  const SliderData = (sliderData: any) => {
    setTouched(true);
    setData({ ...data, [name]: sliderData });
  };
  return (
    <div className="lg:max-w-[558px]">
      <div className="flex items-center justify-between mb-[30px]">
        <div>
          <p className="tracking-[-0.02em] font-medium  text-[12px] text-[#161616] mb-[2px]">
            {title}
          </p>
          <h5 className=" font-bold  text-[16px] text-[#0B4547] mb-[2px]">
            {subtitle}
          </h5>
        </div>
        <Tooltip title={tooltip || " "}>
        <img src="/assets/message-question.svg" alt="question" />
      </Tooltip>        
      </div>

      <Slider
        valueLabelDisplay="on"
        aria-label="custom thumb label"
        value={+data?.[name] >= 0 ? +data?.[name] : defaultValue}
        step={step}
        min={min}
        max={max}
        onChange={(_, value) => SliderData(value)}
        valueLabelFormat={numberData}
        disabled={disabled || false}
      />
      <div className="flex items-center justify-between">
        <span className="tracking-[-0.02em] font-medium  text-base text-[10px] text-[#161616]">
          {bottomLeftText}
        </span>
        <span className="tracking-[-0.02em] font-medium text-base text-[10px] text-[#161616]">
          {bottomRightText}
        </span>
      </div>
      <div className="flex items-center justify-between">
        <span className="tracking-[-0.02em] font-normal  text-base text-[10px] text-[#161616]">
          {lastLeftText}{" "}
        </span>
        <span className="tracking-[-0.02em] font-normal  text-base text-[10px] text-[#161616]">
          {lastRightText}
        </span>
      </div>
    </div>
  );
};

export default RangeSlider;
