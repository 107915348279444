import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { CurrentAccountValidation } from "../../../common/utils";
import FormGroup from '@mui/material/FormGroup';
import { common } from '@mui/material/colors';
import { LessonStepContext } from "../../../Context/Provider";





const Arr: any = [
    "Traditional IRA",
    "Roth IRA",
    "SEP IRA",
    "Solo 401K"
  ];

const CreateAccountPiece = ({
    data,
    formAction,
  }: {
    data?: any;
    formAction: Function;
  }) => {


const { stepFlag, triggerStep } = useContext(LessonStepContext);

    const [selectedValue, setSelectedValue] = useState<any>(
      data?.checkboxes || [false,false,false,false]
    );
    const [defaultValues, setDefaultValues] = useState<any>({
      checkboxes: data?.checkboxes || [false,false,false,false],
    });

    const {
      unregister,
      register,
      handleSubmit,
      setValue,
      getValues,
      setError,
      clearErrors,
      control,
      reset,
      watch,
      formState: { errors, isValid },      
    } = useForm<any>({
      shouldUnregister: true,
      mode: "onSubmit",
      defaultValues: defaultValues,
      resolver:yupResolver(CurrentAccountValidation),
    });

    const [boxes,setBoxes] = useState([false,false,false,false])

    function flipBoxes (i:number) {

        const updatedBoxes = [...boxes]
        updatedBoxes[i] = !updatedBoxes[i]  
        setBoxes(updatedBoxes)
    }

    const getData = (data: any) => {
        data.checkboxes = boxes
      formAction({ data });
      triggerStep(true)
    };
    
    return (
      <div>
        <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]"></div>
        <FormGroup>
        {Arr?.map((e: any, i: any) => {
          return (

            <React.Fragment key={i}>
            <FormControlLabel
              className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="checkboxes"
                  render={({ field, ...props }) => {
                    return (
                        <Checkbox color="primary" onChange={()=> {
                            flipBoxes(i)
                        }}/>        
                    );
                  }}
                />
              }
              label={e}
            />
            </React.Fragment>

            
          );
        })}
        </FormGroup>   
        <span>
          {errors?.["selectOption"] && (
            <p className="text-green-600 text-[12px] mt-[5px]">
              {errors?.["selectOption"]?.message?.toString() || ""}
            </p>
          )}
        </span>

        <Button
          removeBottomSpace={true}
          onClick={handleSubmit(getData)}
          label="NEXT"
          left={true}
        />

      </div>
    );
  };
  
  export default CreateAccountPiece;
  