import { useState, useEffect, useContext } from "react";
import { Screen } from "../../common/Screen";
import { Button } from "../../components/Button";
import FirstPiece from "../../components/onBoardingForms/FirstPiece";
import SecondPiece from "../../components/onBoardingForms/SecondPiece";
import ThirdPiece from "../../components/onBoardingForms/ThirdPiece";
import { HeaderFooterContext, StepsContext } from "../../Context/Provider";
import FourthPiece from "../../components/onBoardingForms/FourthPiece";
import SixthPiece from "../../components/onBoardingForms/SixthPiece";
import SeventhPiece from "../../components/onBoardingForms/SeventhPiece";
import EighthPiece, {returnSideGig} from "../../components/onBoardingForms/EighthPiece";
import SurveyPiece, {returnSurveyChoice} from "../../components/onBoardingForms/SurveyPiece";
import NinethPiece, {returnHousingValue} from "../../components/onBoardingForms/NinethPiece";
import FifthPiece from "../../components/onBoardingForms/FifthPiece";
import TenthPiece, { returnLifestyleValue } from "../../components/onBoardingForms/TenthPiece";
import TwelfthPiece from "../../components/onBoardingForms/TwelfthPiece";
import { useNavigate } from "react-router-dom";
import FourteenthPiece from "../../components/onBoardingForms/FourteenthPiece";
import { MagicProposal, getYearsAfterRetirement, getYearsToRetirement, returnYearlyIncome } from "../../magicMath";
import PieChart from "../../components/PieChart"
import {LIFESTYLE_BUTTON_TEXT,HOUSING_BUTTON_TEXT} from "../../components/MagicContainer"
import { googleSignIn, facebookSignIn, signUp } from "../../cognitoAuth";
import { getAPIData, postAPIData } from "../../utils/apiClient";
import { UserContext } from '../../Context/Provider';
import { RetirementInfo, ProposalInfo } from "../../common/settings";
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom';
import { Layout } from "../../common/Layout";

import { calculateSocialSecurityAdjustments} from "../../socialSecurityMath"
import {figureSavingsPerYear} from "../../magicMath"
import { Constants, Rates} from "../../common/settings";



const CurrentStepData = (local: any, total: any) => {
  if (local < 0 || local >= total) {
    return 0;
  }
  return +local;
};
const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const screenOrder = [
  "welcome",
  "reason",
  "name",
  "income",
  "savings",
  "puzzle",
  "chart",
  // "account",
  "feel",
  "sidejob",
  "housing",
  "lifestyle",
  "social",
  "finish"
]

export const Welcome = () => {
  const { authUser, setAuthUser } = useContext(UserContext);
  const [step, setStep] = useState(
    CurrentStepData(localStorage.getItem("CurrentStep"), steps?.length)
  );
  const navigate = useNavigate();
  const { stepsData, setStepsData } = useContext(StepsContext);
  const { show, setShow } = useContext(HeaderFooterContext);

  const screens = [
    {
      //step 1
      title: "Nia -  Experience a different kind of Financial Freedom Calculator",
      key:"welcome",
      subtitle: "We're so happy you are thinking about your future!",
      body: "Being self-employed can complicate planning, but Nia can help with our tools for freelancers.",
      image: "/assets/welcome-1.svg",
      hideBack: false,
      children: (
        <div>
        <Button
          fullWidth={true}
          label="NEXT"
          onClick={() => changeStep(nextStep("welcome"))}
        />
        <div className="relative font-satoshi text-white text-[15px]">
        Returning user? <Link to="/auth/sign-in">Sign in here.</Link>
        </div>
        </div>
      ),
    },
    {
      title: "How can Nia help?",
      key:"reason",
      body: "",
      subtitle: "Our services are designed to help freelancers secure their future by planning for retirement and save taxes. Which interests you the most?",
      retirementPlan:
        "",
      image: "/assets/screen-puzzle.svg", 
      children: (
        <SurveyPiece
          data={stepsData?.step10}
          formAction={({ data }: any) => {
            
            //if (process.env.REACT_APP_AWS_BRANCH === "main") {}
            ReactGA.event({
              category: "survey",
              action: "select",
              label: returnSurveyChoice(data.selectOption),
            });
              
            
        
            setStepsData({ ...stepsData, step1: { ...data } });
            localStorage.setItem(
              "StepsData",
              JSON.stringify({ ...stepsData, step1: { ...data } })
            );
            
            changeStep(nextStep("reason"));
          }}
        />
      ),
    },
    

    {
      //step 3
      title: "Let’s determine your foundation and goals",
      key: "name",
      subtitle: "Now that you know a little more about us, we’d like to know a little more about you!",
      image: "/assets/screen-puzzle2.svg",
      children: (
        <FirstPiece data={stepsData?.step3} formAction={createProfile} />
      ),
    },
    {
      //step 4
      title: "What is your gross income?",
      key:"income",
      subtitle: "Can you tell us more about your income?",
      image: "/assets/screen-puzzle-step-4.svg",
      estimates:
        "It is important for you to be as accurate as you can when estimating your income.",
      // repetitive, should consider removing "expensesDetails" text below due to tooltip for "helpTextBody"
      expensesDetails:
        "",
      helpTextBody:
        "Quick tip: Living expenses include housing, food, spending money, medical bills, family expenses, travel, etc., not business expenses or taxes.",
      children: (
        <SecondPiece data={stepsData?.step4} formAction={addExpenses} />
      ),
    },
    {
      //step 5
      title: "How much have you saved for retirement so far?",
      key: "savings",
      subtitle: "This will help us get an accurate picture of your foundation.",      
      image: "/assets/screen-puzzle-step-5.svg",
      estimates:
        "Hey, don't worry! It can be any number, including zero- we just need to know so we can build the best possible picture of your retirement needs!",
      inputDetails:
        "Hey, don't worry! It can be any number, including zero- we just need to know so we can build the best possible picture of your retirement needs!",
      children: (
        <ThirdPiece
          data={stepsData?.step5}
          formAction={retirementDetails}
          helpText="If you have an IRA, 401K, or any other type of savings go ahead and fill that in. If not, don't worry, you can leave this field blank!"
        />
      ),
    },
    {
      //step 6
      title: "Lets Build This Puzzle",
      key: "puzzle",
      subtitle: "Great job! This hard part is over (not too bad, right?)",
      body: "Give us a moment as we gather your puzzle pieces and put them together to show you a picture of what retirement can look like for you with Nia!",
      image: "/assets/screen-puzzle-step-6.gif",
      children: <FourthPiece formAction={() => changeStep(nextStep("puzzle"))} />,
    },
    {
      //step 7
      title: "Voila!",
      key: "chart",
      subtitle: "This is just a snapshot of what retirement can look like for youbased on your current situation. With Nia's help, it can look even better!",
      body: "Keep going to see what Nia can do for you!", 
      image: "/assets/screen-puzzle-step-8.svg",
      disclamer:"",
      handleChart: (
          <PieChart data={stepsData}/>
          ),
        children: (
        <SixthPiece        
          formAction={customProfile}
        />
      ),
    }, //      disclamer:"(Disclamer: It is not real magic, just some great math, excellent planning, and passion for helping people like you!)",

    {  
      //step 8
      title: "Set up your account",
      key:"account",
      body: authUser? "You've logged in, please continue to the next step" : "While we work on your puzzle pieces, you can work on setting up your account! Having an account will allow you to save your personalized puzzle, adjust it with time, and make sure you are always on track!",
      image: "/assets/screen-step-7.svg",
      children: (
        <FifthPiece
          hostUIsignUp={hostUIsignUp}
          // signup={formSignup}
          next={() => changeStep(nextStep("account"))}
          signupGoogle={google}
          signupFB={facebook}
        />
      ),
    },
    {
      // step 9
      title: "How did that feel?",
      key: "feel",
      subtitle: "If you loved it, great. You've begun the journey toward a comfortable retirement! If it looked not-so-great, don't stress! We are here to take the pressure off and help you get where you want to be.",
      body: "Now let's see how working with Nia can help you achieve that comfortable retirement. We are going to gather a bit more information so we can give you the best possible plan options that will get you to your goals. This will only take 2 minutes and you don’t need any forms! We will walk you through how to estimate everything.",
      image: "/assets/screen-step-9.svg",
      planOptions:
        "",
      safetyInfo:
        "Don't worry about the safety of this information- we are fully encrypted so you can put together your retirement puzzle in peace!",
      formsInfo:
        "",
      children: (
        <SeventhPiece
          formAction={() => {
            changeStep(nextStep("feel"));
          }}
        />
      ),
    },
    {
      // step 10
      title: "How do you envision your retired life?",
      key:"sidejob",
      body: "",
      subtitle: "Modern Retirees today have options for staying active, such as working part-time, volunteering, or pursuing adventure. Choose all that apply. ",
      retirementPlan:
        "",
      image: "/assets/screen-puzzle-step-10.svg",
      helpText:
        "Retirement doesn’t mean you’re no longer creating or being passionate- if you see yourself still bringing in income, let us know so we can factor that into your plan!",
      children: (
        <EighthPiece
          data={stepsData?.step10}
          formAction={({ data }: any) => {
            setStepsData({ ...stepsData, step10: { ...data } });
            localStorage.setItem(
              "StepsData",
              JSON.stringify({ ...stepsData, step10: { ...data } })
            );
            changeStep(nextStep("sidejob"));

          }}
        />
      ),
    },
    {
      // step 11
      title: "At 67, what do you want your housing situation look like?",
      key:"housing",
      subtitle: "Will you rent, buy, or move locations? Apartment, condo, house?",
      image: "/assets/screen-puzzle-step-11.svg",
      helpText:
        "Housing takes up a big chunk of living expenses, so knowing what this expense will look like for you in retirement helps us better estimate your needs.",
      children: (
        <NinethPiece
          data={stepsData?.step11}
          formAction={({ data }: any) => {
            setStepsData({
              ...stepsData,
              step11: { ...data },
            });
            localStorage.setItem(
              "StepsData",
              JSON.stringify({
                ...stepsData,
                step11: { ...data },
              })
            );
            changeStep(nextStep("housing"));

          }}
        />
      ),
    },
    {
      // step 12
      title:
        "Now that we have established your housing plans, lets talk about your other expenses in retirement.",
      key:"lifestyle",
      subtitle: "Will you cook more in retirement, thus saving on food costs? Will you pick up a new hobby that will require buying equipment? Will you buy less/more clothes? Will you have more/less health bills? Will your kids be independent by then? Will you travel frequently?",
      image: "/assets/screen-puzzle-step-12.svg",
      helpText:
        "Think about your spending trends today- do they look the same in retirement, or a little different?",
      children: (
        <TenthPiece
          data={stepsData?.step12}
          formAction={({ data }: any) => {
            setStepsData({
              ...stepsData,
              step12: { ...data },
            });
            localStorage.setItem(
              "StepsData",
              JSON.stringify({
                ...stepsData,
                step12: { ...data },
              })
            );
            changeStep(nextStep("lifestyle"));             

          }}
        />
      ),
    },
    
    {
      // step 14
      title: "How much do you think you will get from social security every month in retirement?",
      key: "social",
      subtitle: "Figuring out your expected amount is a little tricky - you can do so by checking your contributions at ssa.gov",
      link: "https://ssa.gov/",
      linkText:"ssa.gov",
      link2:"https://www.nasdaq.com/articles/heres-how-much-social-security-benefits-are-age-and-income-level-2018-08-21",
      link2text:"this link",
      helpIcon:true,
      helpText:"estimates from Motley Fool",
      body: "In general those making 60k for 10 years can expect about $2100 per month starting at 67. Those averaging 100k would receive about $2600.",
      body2: "We don’t need the exact number, just an estimate. If you are unsure, you can reference the table on this at this link.",
      //estimates: "We dont need the exact number, just an estimate. If you are unsure, you can reference the table on this link.",
      image: "/assets/screen-puzzle-step-5.svg",
      children: (
        <FourteenthPiece data={stepsData?.step14} formAction={socialSecurity} />
      ),
    },
    {
      // step 15
      title:
        "Based on the information you have shared with us, we have  come up with a plan specifically for you.",
      key:"finish",
      subtitle: "Lets go see it together and tweak it to more accurately fit your needs.",
      body: "",
      image: "/assets/screen-puzzle-step-14.svg",
      children: (
        <TwelfthPiece
          formAction={() => {
            saveMagicPlan();
            navigate("/magic-screen");            
          }}
        />
      ),
    },
  ];

function nextStep(currentStepName : string) {  
  return(screenOrder.indexOf(currentStepName) + 1)
}

function prevStep(currentStepName : string) {  
  return(screenOrder.indexOf(currentStepName) === 0 ? 0 : screenOrder.indexOf(currentStepName) - 1)
}

function rewindStep(currentStepNum : number) {

  changeStep(screenOrder.indexOf(screens[currentStepNum].key) - 1)
  
}

  function changeStep(position: number) {
    //look up desired position in order number, return screen number

    const nextScreen = screens.findIndex( obj => obj.key === screenOrder[position])

    if (process.env.REACT_APP_AWS_BRANCH === "main") {
      ReactGA.send({ hitType: "pageview", page: "/welcome", title: (position + ":" + screens[nextScreen].key) });
    }
    setStep(nextScreen);
  }


  function createProfile({ data }: any) {
    setStepsData({ ...stepsData, step3: { ...data } });
    localStorage.setItem(
      "StepsData",
      JSON.stringify({ ...stepsData, step3: { ...data } })
    );
    changeStep(nextStep("name"));
  }

  function addExpenses({ data }: any) {
    setStepsData({ ...stepsData, step4: { ...data } });
    localStorage.setItem(
      "StepsData",
      JSON.stringify({ ...stepsData, step4: { ...data } })
    );
    changeStep(nextStep("income"));
  }

  function retirementDetails({ data }: any) {
    setStepsData({ ...stepsData, step5: { ...data } });
    localStorage.setItem(
      "StepsData",
      JSON.stringify({ ...stepsData, step5: { ...data } })
    );
    changeStep(nextStep("savings"));
  }

  async function socialSecurity({ data }: any) {
    setStepsData({ ...stepsData, step14: { ...data } });
    localStorage.setItem(
      "StepsData",
      JSON.stringify({ ...stepsData, step14: { ...data } })
    );


   
    changeStep(nextStep("social"));
  }

  function customProfile() {
    changeStep(nextStep("chart"));
      
  }


  function saveMagicPlan() {

    const magicLocalData = {
      futureSaving: Math.round(returnYearlyIncome(stepsData?.step4?.income,stepsData?.step4?.unit) * ProposalInfo.SAVINGS_RATE),
      retirementAge: RetirementInfo.STANDARD_RETIREMENT_AGE,
      postRetirementJob: 0,
      Housing: HOUSING_BUTTON_TEXT[returnHousingValue(stepsData.step11.selectOption)],
      Lifestyle: LIFESTYLE_BUTTON_TEXT[returnLifestyleValue(stepsData.step12.selectOption)],
      Income:returnYearlyIncome(stepsData?.step4?.income,stepsData?.step4?.unit),
      currentSavings: stepsData.step5.range,
      currentOther: stepsData.step5.other,
      savingsBreakdown: figureSavingsPerYear(returnYearlyIncome(stepsData?.step4?.income,stepsData?.step4?.unit) * ProposalInfo.SAVINGS_RATE,
        stepsData.step5.range,
        getYearsToRetirement(RetirementInfo.STANDARD_RETIREMENT_AGE,stepsData.step3.age),
        getYearsAfterRetirement(Constants.LIFEEXPECTANCY,RetirementInfo.STANDARD_RETIREMENT_AGE),
        Rates.INTEREST_RATE,Rates.INTEREST_RATE_AFTER_RETIREMENT,Rates.INFLATION_RATE),
      ageBounds: {
        LifeExpectancy:Constants.LIFEEXPECTANCY,
        Age:stepsData.step3.age},
      socialSecurityInfo:calculateSocialSecurityAdjustments(stepsData.step14.social_security_estimate,RetirementInfo.STANDARD_RETIREMENT_AGE,stepsData.step3.age,0)
    }
    localStorage.setItem("MagicPlan", JSON.stringify(magicLocalData));

  }



  async function hostUIsignUp() {
    await signUp()
  }

  async function google() {
    await googleSignIn();
  }

  async function facebook() {
    await facebookSignIn();
  }


  useEffect(() => {
    localStorage.setItem("CurrentStep", `${step}`);
    setShow(true);
  }, [step]);

  

  return (
    <Layout>
      <Screen
        title={screens[step].title}
        subtitle={screens[step].subtitle}
        link={screens[step].link}
        linkText={screens[step].linkText}
        link2={screens[step].link2}
        link2text={screens[step].link2text}
        body={screens[step].body}
        body2={screens[step].body2}
        image={screens[step].image}
        handleChart={screens[step].handleChart}
        hideBack={screens[step].hideBack}
        planOptions={screens[step].planOptions}
        safetyInfo={screens[step].safetyInfo}
        estimates={screens[step].estimates}
        //learnMore={screens[step].learnMore}
        expensesDetails={screens[step].expensesDetails}
        retirementPlan={screens[step].retirementPlan}
        currentStep={step}
        changeStep={changeStep}
        rewindStep={rewindStep}
        steps={steps}
        formsInfo={screens[step].formsInfo}
        disclamer={screens[step].disclamer}
        helpText={screens[step].helpText}
        helpTextBody={screens[step].helpTextBody}
      >
        {screens[step].children}
      </Screen>
    </Layout>
  );
};
