import { useState, useEffect, useContext } from "react";
import { MagicProposal } from "../magicMath";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {RetirementInfo, ProposalInfo} from "../common/settings"
import {returnYearlyIncome} from "../magicMath"
import { Constants, Rates } from "../common/settings";

import { Pie } from 'react-chartjs-2';
import { estimateSocialSecurity } from "../socialSecurityMath";



ChartJS.register(ArcElement, Tooltip, Legend);



export const chartData = {
  labels: ['Current Retirement Savings', 'Future Retirement Savings', 'Social Security', 'Retirement Side Gig'],
  datasets: [
    {
      label: 'Sources of Retirement Income',
      data: [1],
      backgroundColor: [
        'rgb(30, 193, 200)',
        'rgb(35, 229, 238)',
        'rgb(27, 179, 187)',
        'rgb(15, 105, 110)',
      ],
      
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
  ]

};


const PieChart = (data:any) => {
    
    const income = returnYearlyIncome(data?.data?.step4?.income,data?.data?.step4?.unit)

    const name=data.data.step3.name
    const age= data.data.step3.age
    const ss=estimateSocialSecurity(income)
    const sidejob=RetirementInfo.EARNINGS_LIMIT
    const futureSaving = income * ProposalInfo.SAVINGS_RATE
    const currentSavings = data.data.step5.range
    const proposedRetirementAge = RetirementInfo.STANDARD_RETIREMENT_AGE > age ? RetirementInfo.STANDARD_RETIREMENT_AGE : age+1


    const haveBreakdown = MagicProposal(age,ss,proposedRetirementAge, sidejob, futureSaving, currentSavings)
    const totalFVSavings = haveBreakdown.currentSavingsTotal + haveBreakdown.futureSavingsTotal
    const FVCurrentSavingsPct = haveBreakdown.currentSavingsTotal / (totalFVSavings)
    const FVFutureSavingsPct = haveBreakdown.futureSavingsTotal / (totalFVSavings)
    const savingsPctTotal = haveBreakdown.totalSavingsPerYear / haveBreakdown.totalHavePerYear
    const currentSavingsPct = Math.round(savingsPctTotal * FVCurrentSavingsPct * 100)
    const futureSavingsPct = Math.round(savingsPctTotal * FVFutureSavingsPct * 100)
    const ssPct = Math.round(100 * (haveBreakdown.socialSecurityTotal) / haveBreakdown.totalHavePerYear)
    const sidejobPct = Math.round(100 * sidejob / haveBreakdown.totalHavePerYear)
    chartData.datasets[0].data=[currentSavingsPct,futureSavingsPct,ssPct,sidejobPct]
    chartData.labels = ['Current Retirement Savings: '+currentSavingsPct.toString()+"%", 
                        'Future Retirement Savings: '+futureSavingsPct.toString()+"%", 
                        'Social Security: '+ssPct.toString()+"%",
                        'Retirement Side Gig: '+sidejobPct.toString()+"%"]
  

    return ( 
        <>
        <div className="flex flex-col w-full items-center justify-center xl:min-w-[640px] cursor-pointer max-w-[1320px] md:h-[40rem] lg:h-[40rem] font-satoshi text-[#0B4547]">
          <div className="leading-[1.2] text-[1.6rem] text-4xl max-[1000px]:text-2xl font-bold font-satoshi mb-1 text-[#0B4547]">
            {name}'s Retirement Plan
          </div>
          <div>
            <p className="mb-1">If you retire at {proposedRetirementAge}, you could have ${haveBreakdown.totalHavePerYear.toLocaleString()} per year with these steps.</p>
             <ul className="list-disc list-inside my-2 font-satoshi mb-6 text-sm">
              <li className="font-bold">Save ${futureSaving.toLocaleString()} per year now (15% of your income). </li>              
              <li>About ${(haveBreakdown.socialSecurityTotal).toLocaleString()} will be from social security (based off current national averages). </li>
              <li>You'll have a retirement side gig making about ${sidejob.toLocaleString()} per year.</li>
              <li>The money you have already saved will grow to ${Math.round(haveBreakdown.currentSavingsTotal).toLocaleString()} (assuming a {Rates.INTEREST_RATE}% growth rate).</li>
            </ul>
          </div>
          <div className="h-fit w-2/3">
            <Pie  data={chartData} />
          </div>
        </div>
        </>
    )
}

export default PieChart;
