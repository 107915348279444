import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button } from "../Button";
import { FirstPieceValidation } from "../../common/utils";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import {Boundaries} from "../../common/settings"


const FirstPiece = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const [defaultValues, setDefaultValues] = useState<any>({
    name: data?.name || "",
    age: data?.age || "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(FirstPieceValidation),
  });

  const formData = (data: any) => {
    formAction({ data });
  };

  return (
    <div>
      <div className="my-12 mb-0 relative flex flex-col flex-end" id="form_name_age">
        <div className="flex text-base text-white max-[1000px]:text-sm my-2  font-satoshi border-b-2 py-1">
          <div className="flex grow justify-items-start">
            <input
              className="input_firstPiece min-w-[185px] bg-inherit text-white text-base"
              type="text"
              placeholder="What is your first name?*"
              {...register("name")}
              required
            />

          </div>
          <img className="h-5" src="/assets/user.png" alt="user" />
          <span className="absolute top-[35px]">
            {errors.name && (
              <p className="text-green-600 text-[13px]">
                {errors.name?.message?.toString()}
              </p>
            )}
          </span>
        </div>
        <div className="flex text-base text-white  max-[1000px]:text-sm my-2  font-satoshi border-b-2 py-1">
          <div className="flex grow justify-items-start">
            <input
              className="input_firstPiece min-w-[185px] bg-inherit text-white text-base"
              type="number"
              min={Boundaries.MINAGE.toString()}
              max={Boundaries.MAXAGE.toString()}
              step="1"
              placeholder="How old are you?*"
              {...register("age")}
              required
            />
            {/* <Tooltip title="Knowing your age helps us estimate how long you have to save before your desired retirement age!">
              <HelpOutlineIcon
                className="helpIcon ml-[10px] !w-[15px]"
                fontSize="small"
              />
            </Tooltip> */}
          </div>
          <img className="h-5" src="/assets/user-edit.png" alt="edit-user" />
          <span className="absolute top-[85px]">
            {errors.age && (
              <p className="text-green-600 text-[13px]">
                {errors.age?.message?.toString()}
              </p>
            )}
          </span>
        </div>
      </div>
      <div className="mt-3 text-xs font-satoshi">
        Retirement planning looks different at different ages... so please no
        cheating here.. :-)
      </div>
      <Button onClick={handleSubmit(formData)} fullWidth={false} label="NEXT" left={true} />
    </div>
  );
};

export default FirstPiece;
