
export const Constants: any = {
    LIFEEXPECTANCY:89
}

export const Boundaries: any = {    
    MINAGE: 18,
    MAXAGE: Constants.LIFEEXPECTANCY-1,
    MININCOME: 20000,
    MAXINCOME: 2000000,
    MIN_RETIREMENT_AGE:62,
    MAX_RETIREMENT_AGE:72,
    SOLO_LIMIT: 69000
}

export const RetirementInfo: any = {
    STANDARD_RETIREMENT_AGE : 67,
    EARNINGS_LIMIT : 22320
}

export const ProposalInfo: any = {
    SAVINGS_RATE:.15
}

export const LifestyleOptions = [
    "Very frugal",
    "More frugal",
    "Same as now",
    "More extravagant",
    "Very extravagant",
  ]
  
  export const LifestyleAdjustments = [
    -.3,
    -.15,
    0,
    .15,
    .3
  ]
  
  export const HousingOptions = 
    [
      "Minimalistic",
      "Cost less",
      "Same as now",
      "Cost more",
      "Lavish",
    ]
  
    export const HousingAdjustments = [
      -.3,
      -.15,
      0,
      .15,
      .3
    ]

    
    export const Rates = {
        INTEREST_RATE:7,
        INTEREST_RATE_AFTER_RETIREMENT: 4,
        INFLATION_RATE: 2,
        SOCIAL_SECURITY_INCREASE:2,
        TAX_RATE:.22        
    }


    export const IRA_AGI_SINGLE_NO_EMPLOYER_PLAN = {
      Traditional: 153000,
      Roth: 153000
    }

    export const IRA_AGI_SINGLE_EMPLOYER_PLAN = {
      Traditional: 77000,
      Roth: 153000
    }

    export const IRA_AGI_MARRIED_NO_EMPLOYER_PLAN = {
      Traditional: 153000,
      Roth: 228000
    }

    export const IRA_AGI_MARRIED_SPOUSE_EMPLOYER_PLAN = {
      Traditional: 240000,
      Roth: 240000
    }

    export const IRA_AGI_MARRIED_EMPLOYER_PLAN = {
      Traditional: 136000,
      Roth: 228000
    }

    export const IRA_LIMITS = {
      IRA_AGI_SINGLE_NO_EMPLOYER_PLAN,
      IRA_AGI_SINGLE_EMPLOYER_PLAN,
      IRA_AGI_MARRIED_NO_EMPLOYER_PLAN,
      IRA_AGI_MARRIED_SPOUSE_EMPLOYER_PLAN,
      IRA_AGI_MARRIED_EMPLOYER_PLAN
    }