import { useState } from "react";
import { Title } from "../components/Title";
import { BodyText } from "../components/BodyText";
import { SubtitleText } from "../components/Subtitle";
import { LineIcon } from "../components/LineIcon";
import { BackButton } from "../components/BackButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";



interface ScreenProps {
  children?: JSX.Element;
  title: string;
  subtitle?:string;
  link?:string,
  linkText?:string,
  link2?:string,
  link2text?:string,
  body?: string;
  body2?: string;
  hideBack?: boolean;
  image: string;
  handleChart?:JSX.Element;
  estimates?: string;
  learnMore?: boolean;
  expensesDetails?: string;
  retirementPlan?: string;
  currentStep: number;
  safetyInfo?: string;
  planOptions?: string;
  formsInfo?: string;
  steps: Array<number>;
  changeStep(step: number): void;
  rewindStep(step: number): void;
  disclamer?: string;
  helpText?: string;
  helpTextBody?: string;
  signInMode?: boolean;
}

export const Screen = ({
  children,
  title,
  subtitle,
  link,
  linkText,
  link2,
  link2text,
  body,
  body2,
  hideBack = true,
  image,
  handleChart,
  currentStep,
  estimates,
  expensesDetails,
  learnMore,
  retirementPlan,
  planOptions,
  safetyInfo,
  formsInfo,
  steps,
  changeStep,
  rewindStep,
  disclamer,
  helpText,
  helpTextBody,
  signInMode
}: ScreenProps) => {

  return (
    <div className="flex grow overflow-auto  justify-center items-center px-5 py-10">
      <div className="flex max-w-[1320px] min-h-[32rem] md:h-[40rem] lg:h-[40rem] justify-center items-center max-md:flex-col md:rounded-xl md:shadow-lg ">
        <div className="flex h-full max-w-[600px]  min-w-[335px]  sm:w-full md:max-w-[28rem] p-8 max-[767px]:rounded-xl md:rounded-l-xl bg-[#0B4547]">
          <div className="flex flex-col overflow-y-auto overflow-x-hidden">
            {hideBack && (
              <BackButton onClick={() => rewindStep(currentStep)} />
            )}
            <div className={`h-full flex flex-col justify-center text-white mb-4 ${signInMode ? "text-center": ""}`}>
              {title && (
                <Title
                  helpIcon={helpText ? true : false}
                  title={title}
                  helpText={helpText}
                />
              )}
              {subtitle && <SubtitleText text={subtitle} link={link} linkText={linkText}/>}

              {body && 
              <BodyText
                helpIcon={helpTextBody ? true : false}
                text={body}                
                helpText={helpTextBody}
              />}
              
              {body2 && <BodyText link2={link2} link2text={link2text} text={body2} />}
              {retirementPlan && (
                <BodyText textSmall={true} text={retirementPlan} />
              )}
              {planOptions && <BodyText text={planOptions} />}
              {safetyInfo && <BodyText text={safetyInfo} />}
              {formsInfo && <BodyText text={formsInfo} />}
              {estimates && <BodyText textSmall={false} text={estimates} />}
              {expensesDetails && (
                <BodyText
                  textSmall={true}
                  text={expensesDetails}
                  helpText={helpTextBody}
                />
              )}
              {disclamer && <BodyText text={disclamer} />}
              {learnMore && (
                <a
                  href="/welcome"
                  target={"_blank"}
                  className="inline-flex w-[100px] hover:border-b-transparent font-satoshi items-center text-[0.9rem] border-b border-b-white cursor-pointer"
                >
                  <span>Learn More </span>
                  <OpenInNewIcon className="ml-2 !w-[15px]"></OpenInNewIcon>
                </a>
              )}              
              {children}
            </div>
            <div className="flex justify-end items-center">
              {!signInMode && (
                <div className="flex justify-center items-center h-1.5 w-[calc(100%-30px)]">
                  {steps.map((eachStep, index) => {
                    if (index === currentStep) {
                      return (
                        <button
                          className="transition-all"
                          key={index}
                          // onClick={() => changeStep(index)}
                        >
                          <LineIcon slideAnimationDots={false} />
                        </button>
                      );
                    } else {
                      return (
                        <button
                          className="transition-all mx-[3px] lg:mx-[5px]"
                          key={index}
                          // onClick={() => changeStep(index)}
                        >
                          <LineIcon slideAnimationDots={true} />
                        </button>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center h-full w-full min-[890px]:min-w-[30rem] sm:pl-6 sm:pr-3 pt-12 md:py-10 lg:pl-16 lg:pr-16 rounded-r-xl bg-[#FFFFFF] items-center justify-center">
         {handleChart ? handleChart : (
                  <div className="max-w-[40rem] w-full xl:min-w-[640px] flex items-center justify-center">                       
                  <img className="md:object-cover " src={image} alt="welcome" />
                  </div>
            )
         }            
        </div>
      </div>
    </div>
  );
};
