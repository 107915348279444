import LoginForm from "../components/onBoardingForms/LoginForm";
import SignIn from "../pages/signin";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OpenSnakBarContext, SnakBarMessageContext, UserContext } from "../Context/Provider";
import { googleSignIn, facebookSignIn, signUp } from "../cognitoAuth";
import { figureSavingsPerYear, getYearsToRetirement, getYearsAfterRetirement, returnRangeText } from "../magicMath"
import { calculateSocialSecurityAdjustments } from "../socialSecurityMath"
import {
  Constants,
  Rates,
  ProposalInfo,
  RetirementInfo,
  HousingAdjustments,
  LifestyleAdjustments,
} from "../common/settings";
import { getAPIData, postAPIData } from "../utils/apiClient";
import FifthPiece from "../components/onBoardingForms/FifthPiece";
import { Modal } from "./Modal";
import Libra from "./Libra";
import { getPlanData } from "./DashBoard/DashBoard";
import { useMediaQuery } from "@mui/material";
import classNames from "classnames";
import RoundedButton from "./RoundedButton";
import CommonMyPlan from "./CommonMyPlan";


const AGE = 36
const SOCIALSECURITYBASE = 2400
const CURRENTSAVINGS = 100000
const CURRENTINCOME = 60000

export const LIFESTYLE_BUTTON_TEXT =
    [
      "Very frugal",
      "More frugal",
      "Same as now",
      "More extravagant",
      "Very extravagant",
    ]

export const HOUSING_BUTTON_TEXT = [
  "Minimalistic",
  "Cost less",
  "Same as now",
  "Cost more",
  "Lavish",
]

const defaultPlanData = {
  futureSaving: 1000,
  retirementAge: 67,
  postRetirementJob: 0,
  Housing: "Same as now",
  Lifestyle: "Same as now",
  Income: CURRENTINCOME,
  currentSavings: CURRENTSAVINGS,
  savingsBreakdown: figureSavingsPerYear(31415,
      CURRENTSAVINGS, 20, 20, Rates.INTEREST_RATE, Rates.INTEREST_RATE_AFTER_RETIREMENT, Rates.INFLATION_RATE),
  ageBounds: {
    LifeExpectancy: Constants.LIFEEXPECTANCY,
    Age: AGE,
  },
  socialSecurityInfo: calculateSocialSecurityAdjustments(SOCIALSECURITYBASE, 67, AGE, 0),
}


const MagicContainer = ({
                          dashboard = false,
                        }: {
  dashboard?: boolean;
}) => {

  const {openSnakBar, setOpenSnakBar} = useContext(OpenSnakBarContext);
  const {snakBarMessage, setSnakBarMessage} = useContext(
      SnakBarMessageContext,
  );
  const navigate = useNavigate();

  const [touched, setTouched] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState(localStorage.getItem("firstLoad") !== "true");
  const [planData, setPlanData] = useState<any>(getPlanData());
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [loginMessage, setLoginMessage] = useState<string>("To save and access your plan, please create a secure account with Nia, and find out more about retirement plan options that can help you save on taxes.")
  const {authUser, setAuthUser} = useContext(UserContext);


  useEffect(() => {
    if (authUser) {
      //console.log({retirement: authUser?.snapshot?.retirement, age: authUser?.age, savings: authUser?.savings})
      setPlanData((current: any) => ({
        ...current,
        futureSaving: authUser?.snapshot?.retirement?.future_savings || 1000,
        retirementAge: authUser?.snapshot?.retirement?.retirement_age || RetirementInfo.STANDARD_RETIREMENT_AGE,
        Income: authUser?.yearly_income || 60000,
        ageBounds: {
          LifeExpectancy: Constants.LIFEEXPECTANCY,
          Age: authUser?.age || current.age,
        },
        postRetirementJob: authUser?.snapshot?.retirement?.sidejob_amount || 0,
        currentSavings: authUser?.savings[0]?.amount || 0,
        Housing: HOUSING_BUTTON_TEXT[authUser?.snapshot?.retirement?.housing_adjustment || 0],
        Lifestyle: LIFESTYLE_BUTTON_TEXT[authUser?.snapshot?.retirement?.lifestyle_adjustment || 0],
        savingsBreakdown: figureSavingsPerYear(authUser?.snapshot?.retirement?.future_savings, authUser?.savings[0]?.amount || 0,
            getYearsToRetirement(authUser?.snapshot?.retirement?.retirement_age || RetirementInfo.STANDARD_RETIREMENT_AGE, authUser?.age || current.age),
            getYearsAfterRetirement(Constants.LIFEEXPECTANCY, authUser?.snapshot?.retirement?.retirement_age || RetirementInfo.STANDARD_RETIREMENT_AGE),
            Rates.INTEREST_RATE, Rates.INTEREST_RATE_AFTER_RETIREMENT, Rates.INFLATION_RATE),
        socialSecurityInfo: calculateSocialSecurityAdjustments(authUser?.snapshot.retirement.social_security_estimate || 0, current.retirementAge, authUser?.age || 35, 0),
      }))
    }
  }, [authUser])

  const validationCheck = () => {

    if (!planData?.Lifestyle) {
      setOpenSnakBar(true);
      setSnakBarMessage({
        severity: "error",
        msg: "Please select Magic LifeStyle option",
      });
    } else if (!planData?.Housing) {
      setOpenSnakBar(true);
      setSnakBarMessage({
        severity: "error",
        msg: "Please select Magic Housing option",
      });
    } else {
      setOpenSnakBar(true);
      setSnakBarMessage({
        severity: "success",
        msg: "Successfull",
      });

      let magicPlanData = {...planData};
      if (magicPlanData?.futureSaving >= 1000) {
        magicPlanData.futureSaving = `$${Number(
            (magicPlanData?.futureSaving / 1000).toFixed(1),
        )}k`;
      }

      setOpen(true);


      // const res = await postAPIData({
      //   url: "v1/users/update-ss",
      //   data: payload,        
      // });

      if (!authUser) {
        console.log("no authuser")
        // SignIn()
        //hostUIsignUp()
      }

      if (authUser) {
        updateUserInfo()
      }

    }
  };

  async function createUser(payload: any) {
    // console.log("create user")
    // //hostUIsignUp().then(() => {
    //   // Code to execute when hostUIsignUp() is done
    //   console.log("User creation is complete");
    // }).catch((error) => {
    //   // Code to handle errors that occurred during sign up
    //   console.log("Error during user creation:", error);
    // });


  }

  async function updateUserInfo() {

    localStorage.setItem("MagicPlan", JSON.stringify(planData));
    const payload = {
      future_savings: planData?.futureSaving,
      housing_adjustment: HOUSING_BUTTON_TEXT.indexOf(planData.Housing),
      lifestyle_adjustment: LIFESTYLE_BUTTON_TEXT.indexOf(planData.Lifestyle),
      retirement_age: planData.retirementAge,
      sidejob_amount: planData?.postRetirementJob,
    }

    const res = await postAPIData({
      url: "v1/users/update-ss",
      data: payload,
    });

    if (res.data.success) {
      const user = await getAPIData({url: "v1/users"});
      if (user?.data?.success) {
        setAuthUser(user.data.user);
      }
    }
  }


  const onReset = () => {
    if (!touched) return;
    setPlanData(defaultPlanData);
    setOpenSnakBar(true);
    setSnakBarMessage({
      severity: "success",
      msg: "Reset Successfull",
    });
    setTouched(false);
  };

  function handlePlanDataChange(event: any) {

    setPlanData((prev: any) => (
        {
          ...prev,
          futureSaving: event.futureSaving,
          retirementAge: event.retirementAge,
          postRetirementJob: event.postRetirementJob,
          currentSavings: event.currentSavings,
          Housing: event.Housing,
          Lifestyle: event.Lifestyle,
          savingsBreakdown: figureSavingsPerYear(event.futureSaving,
              event.currentSavings, getYearsToRetirement(event.retirementAge, prev.ageBounds.Age), getYearsAfterRetirement(Constants.LIFEEXPECTANCY, event.retirementAge), Rates.INTEREST_RATE, Rates.INTEREST_RATE_AFTER_RETIREMENT, Rates.INFLATION_RATE),
          socialSecurityInfo: calculateSocialSecurityAdjustments(prev.socialSecurityInfo.base / 12, event.retirementAge, prev.ageBounds.Age, event.postRetirementJob),
        }))

  }

  function handleClose() {
    setOpen(false);
  }

  function handleSignIn() {
    if (userLoggedIn) {
      setOpen(false);
      localStorage.removeItem("CurrentStep");
      localStorage.removeItem("StepsData");
      console.log("removing local")
      navigate("/dashboard");
    } else {
      localStorage.setItem("MagicPlan", JSON.stringify(planData));
      hostUIsignUp()
      setLoginMessage("Saving....")
    }
  }


  const isLarge = useMediaQuery("(min-width: 1024px)");
  const {
    ssAdjustmentText,
    ssSideJobAdjustmentText,
    totalHavePerYear,
    totalWant,
  } = returnRangeText(planData);

  return (
      <>
        {dashboard && (
            <Modal
                open={open}
                onClose={handleClose}
                onButtonClick={() => {
                  handleClose()
                  navigate("/dashboard");
                }}
            />)
        }
        {(!dashboard) && (
            <Modal
                open={open}
                onClose={handleClose}
                heading="Save"
                description={loginMessage}
                onButtonClick={() => {
                  handleSignIn()
                }}
            />)
        }
        <Modal
            open={firstLoad}
            onClose={() => {
              localStorage.setItem("firstLoad", "true");
            }}
            onButtonClick={() => {
              localStorage.setItem("firstLoad", "true");
              setFirstLoad(false);
            }}
            heading="NIA Plan"
            description="So, we’ve been working on gathering your puzzle pieces, now let’s see how they’ll fit together!"
        />

        <div className={classNames("max-w-[1350px] px-[15px] w-5/6 ml-auto mr-auto font-satoshi", {
          "w-full": dashboard,
        })}>
          <div
              className={classNames(
                  "rounded-[25px] mb-[30px] lg:mb-[78px] bg-white overflow-hidden",
                  {
                    "shadow-card": !dashboard,
                  },
              )}
          >
            {/* <p className="bg-[#0B4547] text-white lg:py-[18px] px-[15px] text-[15px] py-[10px] lg:px-[110px] text-center lg:text-[20px] font-medium font-satoshi tracking-[-0.02em]">
            Like what you see so far? Great! If not, make some changes using the
            sliders below! <br></br>We’ll let you know what your updated plan
            looks like as you adjust things a bit!
          </p> */}
            {/* {!isLarge && (
            <div className="w-full lg:w-1/2 flex justify-center lg:pl-10 flex-col items-center mb-4">
              <Libra
                havePay={Math.round(totalHavePerYear) || 0}
                wantPay={totalWant}
              />
              <div className="flex items-center justify-center gap-[30px] -mt-10 z-10">
                <a
                  onClick={() => {
                    if (!touched) return;
                    onReset?.();
                    setTouched(false);
                  }}
                  className="text-[#161616] hover:underline-offset-[8px] transition-all underline underline-offset-[10px] cursor-pointer font-medium text-center tracking-[-0.02em]"
                >
                  Reset
                </a>
                <RoundedButton
                  text={"Save my plan container"}
                  onClick={() => {                    
                    validationCheck();
                    hostUIsignUp()
                  }}
                  buttonClasses="bg-[#EA743C]"
                />
              </div>
            </div>
          )} */}
            <CommonMyPlan
                touched={touched}
                setTouched={setTouched}
                handlePlanDataChange={handlePlanDataChange}
                onReset={onReset}
                planData={planData}
                setPlanData={setPlanData}
                validationCheck={validationCheck}
                dashboard={dashboard}
            />
          </div>
        </div>
      </>
  );
};


async function hostUIsignUp() {
  await signUp()
}


export default MagicContainer;
