export const Button = ({
  label,
  className,
  left,
  onClick,
  fullWidth,
  removeBottomSpace,
}: {
  label: string;
  className?: string;
  left?: boolean;
  fullWidth?: boolean;
  removeBottomSpace?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <div
      className={`flex ${
        left ? "justify-start" : "justify-center"
      } items-center max-[1000px]:text-sm`}
    >
      <button
        onClick={onClick ?? undefined}
        className={` ${fullWidth && "w-full"} max-[650px]:w-full ${
          removeBottomSpace ? "mt-6 mb-0" : "my-6"
        }  ${
          className ? className : ""
        } min-w-[100px] bg-white py-3  px-6 rounded-lg px-2 text-base max-[1000px]:text-sm font-bold font-satoshi text-[#0B4547] text-center`}
      >
        {label}
      </button>
    </div>
  );
};
