import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export const BackButton = ({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      onClick={onClick}
      className="flex text-white text-sm font-satoshi items-center my-2"
    >
      <KeyboardBackspaceIcon />
      <div className="ml-2"></div>
      BACK
    </button>
  );
};
