import React from 'react'
import { Button } from '../Button';

const TwelfthPiece = ({ formAction }: { formAction:Function }) => {
  return (
    <div>
      <Button label="SHOW ME" left={true} onClick={()=>{formAction();}} />
    </div>
  );
};

export default TwelfthPiece