import { FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { EighthPieceValidation } from "../../common/utils";
import { RetirementInfo } from "../../common/settings";

const Arr = [
  "I just started a business and looking to replace my benefits",
  "I want to save lots on taxes",
  "I need to catch up on my retirement savings",
  "I would like to automatically save from the various freelancing platforms (Upwork, Fiverr, Etsy, TaskRabbit, 99designs, Freelancer, etc)",
  "I'm worried that I will never be able to retire",  
  "Other"
];

const ArrValues:any = [
"benefits",
"taxes",
"catch-up",
"platforms",
"worry",
"other"
]

export function returnSurveyChoice(choice:string) {
  return (ArrValues[Arr.indexOf(choice)])
}

const SurveyPiece = ({data, formAction }: {data?:any; formAction: Function }) => {
  const [selectedValue, setSelectedValue] = useState<any>(data?.selectOption || "");
  const [defaultValues, setDefaultValues] = useState<any>({
    selectOption: data?.selectOption || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(EighthPieceValidation),
  });
  const getData = (data: any) => {
    formAction({data});
  };
  return (
    <div>
      {Arr?.map((e: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <FormControlLabel
              className="text-white mt-[10px] w-full !mr-0 !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="selectOption"
                  render={({ field, ...props }) => {
                    return (
                      <Radio
                        checked={e === selectedValue}
                        onChange={() => {
                          field.onChange(e);
                          setSelectedValue(e);
                        }}
                      />
                    );
                  }}
                />
              }
              label={e}
            />
          </React.Fragment>
        );
      })}
      <span>
        {errors?.["selectOption"] && (
          <p className="text-green-600 text-[12px] mt-[5px]">
            {errors?.["selectOption"]?.message?.toString() || ""}
          </p>
        )}
      </span>
      <Button removeBottomSpace={true} onClick={handleSubmit(getData)} label="NEXT" left={true} />
    </div>
  );
};

export default SurveyPiece;
