import { FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { EighthPieceValidation } from "../../common/utils";
import { RetirementInfo } from "../../common/settings";

const Arr = [
  "Continue freelancing at some scale in my current field (i.e. earn some income)",
  "Work in a field I’m new in like being a barista or a bus driver (i.e. earn a small amount of income)",
  "I just want to chill (i.e. no income, no added expenditure from a new hobby or passion pursuit)",
  "Pursue a passion (i.e. does not make money, but is an added expenditure)",
];

const ArrValues:any = [
  RetirementInfo.EARNINGS_LIMIT,
  RetirementInfo.EARNINGS_LIMIT/2,
  0,
  0,
]

export function returnSideGig(choice:string) {
  return (ArrValues[Arr.indexOf(choice)])
}

const EighthPiece = ({data, formAction }: {data?:any; formAction: Function }) => {
  const [selectedValue, setSelectedValue] = useState<any>(data?.selectOption || "");
  const [defaultValues, setDefaultValues] = useState<any>({
    selectOption: data?.selectOption || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(EighthPieceValidation),
  });
  const getData = (data: any) => {
    formAction({data});
  };
  return (
    <div>
      {Arr?.map((e: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <FormControlLabel
              className="text-white mt-[10px] w-full !mr-0 !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="selectOption"
                  render={({ field, ...props }) => {
                    return (
                      <Radio
                        checked={e === selectedValue}
                        onChange={() => {
                          field.onChange(e);
                          setSelectedValue(e);
                        }}
                      />
                    );
                  }}
                />
              }
              label={e}
            />
          </React.Fragment>
        );
      })}
      <span>
        {errors?.["selectOption"] && (
          <p className="text-green-600 text-[12px] mt-[5px]">
            {errors?.["selectOption"]?.message?.toString() || ""}
          </p>
        )}
      </span>
      <Button removeBottomSpace={true} onClick={handleSubmit(getData)} label="NEXT" left={true} />
    </div>
  );
};

export default EighthPiece;
