import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

export const SubtitleText = ({
  text,
  textSmall,
  link,
  linkText,
  helpIcon,
  helpText,
}: {
  text: string;
  textSmall?: boolean;
  link?:string;
  linkText?:string;
  helpIcon?: boolean;
  helpText?: string;
}) => {

  let linkIndex=0
  let beforeText = "";
  let middleText = "";
  let afterText = "";


   if(link && linkText) {
    linkIndex = text.indexOf(linkText)        
    beforeText = text.substring(0, linkIndex);
    middleText = linkText;
    afterText = text.substring(linkIndex + linkText.length);

   }
  return (
    
    <div
      className={`my-0 font-satoshi mb-6 text-gray-200 ${
        textSmall
          ? "max-[1000px]:text-xs text-xs "
          : "max-[1000px]:text-sm text-[0.9rem] leading-[1.5]"        
      }          
      `}
    >
      {link ? (
        <>
        {beforeText}<a className="underline" href={link}>{middleText}</a>{afterText}
        </>
      ) : (
        <>
        {text}
      </>
      )}
      

      
      {helpIcon && (
        <Tooltip title={helpText}>
          <HelpOutlineIcon
            className="ml-1 helpIcon !w-[15px]"
            fontSize="small"
          />
        </Tooltip>
      )}
    </div>
  );
};

