import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Layout } from "./common/Layout";
import { getAPIData, postAPIData } from "./utils/apiClient";
import { useContext } from "react";
import { UserContext } from "./Context/Provider";
import { signOut } from "./cognitoAuth";
import { returnYearlyIncome } from "./magicMath";
import { LIFESTYLE_BUTTON_TEXT, HOUSING_BUTTON_TEXT } from "./components/MagicContainer"

export const AppContainer = () => {
  const {authUser, setAuthUser, setCognitoUser} = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleTokenError = (e?: any, lineNo?: any) => {
    if (e) console.log(lineNo, e);
    localStorage.removeItem("niaTokens");
    localStorage.removeItem("CurrentStep");
    localStorage.removeItem("StepsData");
    localStorage.removeItem("MagicPlan");
    setLoading(false);
    //navigate("/auth/sign-in");
    navigate("/auth/welcome");
  };
  const refreshMyToken = async (user: any) => {
    try {
      const currentSession = await Auth.currentSession();
      const currentRefreshToken = currentSession.getRefreshToken();
      const session = await new Promise((resolve, reject) => {
        user.refreshSession(currentRefreshToken, (err: any, session: any) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });
      await handleSession(session, user);
    } catch (e) {
      handleTokenError(e, 39);
    }
  };
  const handleSession = async (session: any, user: any) => {
    try {
      if (!session) {
        return handleTokenError();
      }
      if (!session.isValid()) {
        await refreshMyToken(user);
      } else {
        const payload = {
          accessToken: session?.accessToken?.jwtToken,
          refreshToken: session?.refreshToken?.token,
          idToken: session?.idToken.jwtToken,
        };
        localStorage.setItem("niaTokens", JSON.stringify(payload));
      }
    } catch (e) {
      handleTokenError(e, 59);
    }
  };

  useEffect(() => {
    const checkUserSession = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCognitoUser(user);

        user.getSession(async (err: any, session: any) => {
          if (err) {
            throw err;
          } else {
            await handleSession(session, user);
          }

          try {
            
            const user = await getAPIData({url: "v1/users"});
            if (user?.data?.success) {
              // add to global state
              setAuthUser(user.data.user);
              
              if (window.location.pathname === "/") {
                if (user.data.user.welcome_form_completed) {
                  //you've been here before!                  
                  navigate("/dashboard");
                } else {

                  // new user, save info and magic plan
                  
                  let payload = getUserData()

                  if (payload === null) {
                    //hasn't completed onboarding
                    navigate("/auth/welcome");
                  }

                  try {
                    const res = await postAPIData({
                      url: "v1/users/update-info",
                      data: payload,
                    });
                    if (res.data.success) {
                      const user = await getAPIData({url: "v1/users"});
                      if (user?.data?.success) {
                        await setAuthUser(user.data.user)
                        //save magic plan
                        const payloadMagic = getMagicData()
                        await postAPIData({
                          url: "v1/users/update-ss",
                          data: payloadMagic,
                        });
                      }
                    }
                    navigate("/dashboard");
                  } catch (err: any) {
                    console.log("cannot post a user");
                    console.log(err.message);
                    if (err?.message?.includes?.("duplicate key")) {
                      await signOut();
                    }
                  }


                }
              }
            }
            //navigate("/auth/welcome");
          } catch (err) {
            // TODO: see if we can remove this
            // logged in but no user profile, need to post user
            console.log('issue')
            let payload = getUserData()
            try {
              const res = await postAPIData({
                url: "v1/users/update-info",
                data: payload,
              });
              if (res.data.success) {
                const user = await getAPIData({url: "v1/users"});
                if (user?.data?.success) {
                  setAuthUser(user.data.user);
                }
              }
              navigate("/dashboard");
            } catch (err: any) {
              console.log("cannot post a user");
              console.log(err.message);
              if (err?.message?.includes?.("duplicate key")) {
                await signOut();
              }
            }
          }
        });
      } catch (err) {
        handleTokenError(err, 128);
      }
    };
    checkUserSession();
  }, []);

  return (
      <div className="flex flex-col w-full min-h-screen md:bg-[#F2FEFF]">
        <Outlet/>
      </div>
  );
};

function getUserData() {
  const data =
      localStorage.getItem("StepsData") &&
      JSON.parse(localStorage.getItem("StepsData") || "");
      
      if (data === null) {
        return null
      }

  const payload = {
    full_name: data?.step3?.name,
    welcome_form_completed: true,
    age: Number(data?.step3?.age) || undefined,
    expense: data?.step4?.expense,
    expense_unit: data?.step4?.unit,
    yearly_income: returnYearlyIncome(data?.step4?.income, data?.step4?.unit),
    current_saving: data?.step5?.range,
    other_saving: data?.step5?.other,
  };
  return payload
}

function getMagicData() {
  const data =
      localStorage.getItem("MagicPlan") &&
      JSON.parse(localStorage.getItem("MagicPlan") || "");

  const payload = {
    future_savings: data.futureSaving,
    housing_adjustment: HOUSING_BUTTON_TEXT.indexOf(data.Housing),
    lifestyle_adjustment: LIFESTYLE_BUTTON_TEXT.indexOf(data.Lifestyle),
    retirement_age: data.retirementAge,
    sidejob_amount: data.postRetirementJob,
    social_security_estimate: data.socialSecurityInfo.base || 0,
  }
  return payload
}