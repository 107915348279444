import React, { useContext, useEffect } from "react";
import MagicContainer from "../../components/MagicContainer";
import MagicContent from "../../components/MagicContent";
import { HeaderFooterContext } from "../../Context/Provider";
import { Layout } from "../../common/Layout";
import ReactGA from 'react-ga4'


const MagicScreen = () => {
  const { show, setShow } = useContext(HeaderFooterContext);
  useEffect(() => {
    setShow(true);
  }, []);
  if (process.env.REACT_APP_AWS_BRANCH === "main") {
    ReactGA.send({ hitType: "pageview", page: "/magic-screen", title: "Magic Screen" });
  }

  return (
    <Layout>
      <>
        <MagicContent />
        <MagicContainer />
      </>
    </Layout>
  );
};

export default MagicScreen;
