import React from 'react';
import ReactDOM from 'react-dom/client';
import { Boundaries } from './common/settings';
import { redirect } from 'react-router';
import { SsidChart } from '@mui/icons-material';

const creditRates = [
    { birthYear: 1917, rate: 3 },
    { birthYear: 1925, rate: 3.5 },
    { birthYear: 1927, rate: 4 },
    { birthYear: 1929, rate: 4.5 },
    { birthYear: 1931, rate: 5 },
    { birthYear: 1933, rate: 5.5 },
    { birthYear: 1935, rate: 6 },
    { birthYear: 1937, rate: 6.5 },
    { birthYear: 1939, rate: 7 },
    { birthYear: 1941, rate: 7.5 },
    { birthYear: 1943, rate: 8 },
  ];
  
  const normalRetirementAges = [
    { birthYear: 1937, age: 65 },
    { birthYear: 1938, age: 65.1667 },
    { birthYear: 1939, age: 65.3333 },
    { birthYear: 1940, age: 65.5 },
    { birthYear: 1941, age: 65.6667 },
    { birthYear: 1942, age: 65.8333 },
    { birthYear: 1943, age: 66 },
    { birthYear: 1955, age: 66.1667 },
    { birthYear: 1956, age: 66.3333 },
    { birthYear: 1957, age: 66.5 },
    { birthYear: 1958, age: 66.6667 },
    { birthYear: 1959, age: 66.8333 },
    { birthYear: 1960, age: 67 },
  ];

  const socialSecurityIncomeEstimates = [
    {income: 20000, monthlySS: 1052 },
    {income: 30000, monthlySS: 1319},
    {income: 40000, monthlySS: 1586},
    {income: 50000, monthlySS: 1852},
    {income: 60000, monthlySS: 2119},
    {income: 70000, monthlySS: 2312},
    {income: 80000, monthlySS: 2437},
    {income: 90000, monthlySS: 2562},
    {income: 100000, monthlySS: 2687},
  ];


const CurrentStandardRetirementAge = 67;
const EarningsLimit = 21240;

function calculateSocialSecurityPercentage(retirementAge:number, age:number) {
    const birthYear = getBirthYear(age)   
    
    let FRA = getNormalRetirementAge(birthYear)
    
    if (retirementAge < Boundaries.MIN_RETIREMENT_AGE) {
        return 0;

    } else if (retirementAge <= FRA) {
      let reductionMonths = (FRA - retirementAge) * 12;      
      let reductionPercent = 0;
      
      if (reductionMonths <= 36) {
        reductionPercent = reductionMonths * 5 / 9;
      } else {
        reductionPercent = 36 * 5 / 9 + (reductionMonths - 36) * 5 / 12;
      }
      
      return (1 - (reductionPercent/100));

    } else {
        let res = retirementAge > Boundaries.MAX_RETIREMENT_AGE ? Boundaries.MAX_RETIREMENT_AGE : retirementAge

        return  (getDelayedRetirementCredit(birthYear) * (res-FRA)/100) + 1      
    }
  }

  export function calculateSocialSecurityAdjustments(socialSecurityBase:any, retirementAge:number, age:number, sidejobIncome:number) {
    let birthYear = getBirthYear(age)

    const retAgeAdjustment =  calculateSocialSecurityPercentage(retirementAge,age)
    const base = socialSecurityBase * 12
    const sidejobAdjustment =  calculateSocialSecurityDeduction(birthYear,retirementAge, sidejobIncome, base * retAgeAdjustment)
    const SSobj = {
        base: base,
        retAgeAdjustment:  retAgeAdjustment,
        sidejobAdjustment: sidejobAdjustment,
        totalSocialSecurity: (base * retAgeAdjustment) - sidejobAdjustment
    }

    return SSobj

  }

  function getNormalRetirementAge(birthYear:number) {
    let normalRetirementAge = CurrentStandardRetirementAge;
    for (let i = 0; i < normalRetirementAges.length; i++) {
      if (birthYear >= normalRetirementAges[i].birthYear) {
        normalRetirementAge = normalRetirementAges[i].age;
      } else {
        break;
      }
    }
    return normalRetirementAge;
  }


  function getDelayedRetirementCredit(birthYear:number) {
    let credit = 0;
    for (let i = 0; i < creditRates.length; i++) {
      if (birthYear >= creditRates[i].birthYear) {
        credit = creditRates[i].rate;        

      } else {
        break;
      }
    }    
    
    return credit;
  }

  function calculateSocialSecurityDeduction(birthyear:number, retireAge:number, income:number, benefit:number) {
 
    let deduction = 0;
    // Determine the deduction based on age and earned income
    if ((retireAge) < getNormalRetirementAge(birthyear)) {    
      if (income > EarningsLimit) {
        deduction = Math.floor((income - EarningsLimit) / 2);
      }    
    }
    deduction = (benefit - deduction) > 0 ? deduction : 0
      
    return deduction;
  }
  
export function getBirthYear(Age:number) {
    const d = new Date();
    let year = d.getFullYear();
    return year - Age
}

export function calculateSocialSecurity(ssObj:any) {
    return (ssObj.base * ssObj.retAgeAdjustment - ssObj.sidejobAdjustment)
}

export function estimateSocialSecurity(income:number) {
  const values = socialSecurityIncomeEstimates.map(bracket => {
    return {value:bracket.income}
  })

  return(socialSecurityIncomeEstimates[findClosestValue(values,income).index].monthlySS)
}

function findClosestValue(arr: string | any[], target: number) {
  let low = 0;
  let high = arr.length - 1;
  let closestValue = null;
  let index = 0;  
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const value = arr[mid].value;
 
    if (closestValue === null || Math.abs(value - target) < Math.abs(closestValue - target)) {
      closestValue = value;
      index = mid;
    }
    
    if (value > target) {
      high = mid - 1;
    } else if (value < target) {
      low = mid + 1;
    } else {      
      return {value: value, index:mid};
    }
  }
  
  return {value: closestValue, index:index};
}