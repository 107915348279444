import { useLocation } from "react-router-dom";
import { getAPIData, postAPIData } from "../../utils/apiClient";

import {accountSlides} from "../../components/lessonContent/accountChoice"
import { saveAmountSlides } from "../../components/lessonContent/saveAmount";
import { useState, useEffect, useContext } from "react";
import { LessonScreen } from "../../common/LessonScreen";
import { Button } from "../../components/Button";
import { HeaderFooterContext, StepsContext, LessonStepContext } from "../../Context/Provider";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4'
import { Layout } from "../../common/Layout";
import { investPlanSlides } from "../../components/lessonContent/investPlan";
import { UserContext } from "../../Context/Provider";




export const Lesson = () => {
  const { authUser, setAuthUser } = useContext(UserContext);
  
  const [step, setStep] = useState(0)
  const { stepFlag, triggerStep } = useContext(LessonStepContext);
  const [screens,setScreens] = useState(accountSlides)
  const [slideStack,setSlide] = useState(new SlideStack)
    
  const navigate = useNavigate();
  const { setShow } = useContext(HeaderFooterContext);



  const location = useLocation();    


  
  useEffect(() => {
    if (location.state.lessonId.lesson === "chooseAccount") {
      setScreens(accountSlides)
    } else if (location.state.lessonId.lesson === "saveAmount"){
      setScreens(saveAmountSlides)
    }  else if (location.state.lessonId.lesson === "investPlan") {
      setScreens(investPlanSlides)
    }    
  }, [location.state]);


  function rewindStep(currentStepNum : number) {  
    const rewindStack = slideStack.pop()
    
    setStep(screens.findIndex(obj => obj.key === rewindStack))
    
  }



  function changeStep(position: number) {
    

    if (process.env.REACT_APP_AWS_BRANCH === "main") {
      ReactGA.send({ hitType: "pageview", page: "/lesson", title: (position + ":" + screens[position].key) });
    }

    slideStack.push(screens[step].key)
    setStep(position);
    
  }


  useEffect(() => {
    localStorage.setItem("CurrentStep", `${step}`);
    setShow(true);
  }, [step]);

  useEffect(() => {    
    if (stepFlag) {
        triggerStep(false)
        const next= screens[step].choice(1)
        if (next==="dashboard") {
          navigate("/dashboard");
        }
        changeStep(screens.findIndex(obj => obj.key === next))
    }
  }, [stepFlag]);


  
  return (
    <Layout>
      <LessonScreen
        title={screens[step].title}
        subtitle={screens[step].subtitle}
        link={screens[step].link}
        linkText={screens[step].linkText}
        link2={screens[step].link2}
        link2text={screens[step].link2text}
        body={screens[step].body}
        body2={screens[step].body2}
        image={screens[step].image}        
        hideBack={screens[step].hideBack}
        currentStep={step}
        changeStep={changeStep}
        rewindStep={rewindStep}
        helpText={screens[step].helpText}
        helpTextBody={screens[step].helpTextBody}
        choice={screens[step].choice}
      >
        {<div>
            {screens[step].children}
        </div>}
      </LessonScreen>
    </Layout>
  );
};

// export function nextStep(currentStepName : string) {  
//     return(screenOrder.indexOf(currentStepName) + 1)
//   }
  
// export function prevStep(currentStepName : string) {  
//     return(screenOrder.indexOf(currentStepName) === 0 ? 0 : screenOrder.indexOf(currentStepName) - 1)
//   }
  
  
  class SlideStack {
    private stack: string[];
  
    constructor() {
      this.stack = [];
    }
  
    public push(item: string): void {
      this.stack.push(item);
    }
  
    public pop(): string | undefined {
      if (this.stack.length === 0) {
        console.log("Slide stack is empty!");
        return undefined;
      }
      return this.stack.pop();
    }
  
    public print(): void {
      console.log(this.stack);
    }
  }
  

