import { facebookSignIn, googleSignIn, signUp } from "../../cognitoAuth";
import { Screen } from "../../common/Screen";
import LoginForm from "../../components/onBoardingForms/LoginForm";

async function hostUIsignUp() {
  await signUp();
}

async function google() {
  await googleSignIn();
}

async function facebook() {
  await facebookSignIn();
}

const SignIn = () => {
  return (
    <Screen
      title="Sign in to your account"
      body=""
      image="/assets/screen-puzzle-step-17.svg"
      currentStep={1}
      steps={[1]}
      changeStep={() => {}}
      rewindStep={()=> {}}
      signInMode={true}
      hideBack={false}
    >
      <LoginForm
        signInGoogle={googleSignIn}
        hostUIsignUp={hostUIsignUp}
        signInFb={facebookSignIn}
        signInMode={true}
      />
    </Screen>
  );
};

export default SignIn;
