import { FormControlLabel, Radio } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Question1Validiation } from "../../../common/utils";
import { LessonStepContext } from "../../../Context/Provider";

const Arr: any = [
    "Not for at least 20 years",
    "In 10 to 20 years",
    "In 5 to 10 years",
    "Not right away, but within 5 years",
    "Immediately"
];

const ArrValues:any = [
  1,
  2,
  3,
  4,
  5
]

export function returnQuestion1Value(choice:string) {
  return (ArrValues[Arr.indexOf(choice)])
}


const Question1Piece = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);

  const [selectedValue, setSelectedValue] = useState<any>(
    data?.question1Answer || ""
  );
  const [defaultValues, setDefaultValues] = useState<any>({
    question1Answer: data?.question1Answer || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(Question1Validiation),
  });
  const getData = (data: any) => {
    formAction({ data });
    triggerStep(true)
  };
  return (
    <div>
      <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]"></div>
      {Arr?.map((e: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <FormControlLabel
              className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="question1Answer"
                  render={({ field, ...props }) => {
                    return (
                      <Radio
                        checked={e === selectedValue}
                        onChange={() => {
                          field .onChange(e);
                          setSelectedValue(e);
                        }}
                      />
                    );
                  }}
                />
              }
              label={e}
            />
          </React.Fragment>
        );
      })}
      <span>
        {errors?.["question1Answer"] && (
          <p className="text-green-600 text-[12px] mt-[5px]">
            {errors?.["businessIncome"]?.message?.toString() || ""}
          </p>
        )}
      </span>
      <Button
        removeBottomSpace={true}
        onClick={handleSubmit(getData)}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default Question1Piece;
