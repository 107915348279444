import React, { useEffect, useState, useContext } from "react";
import { HeaderFooterContext, StepsContext, LessonStepContext, UserContext } from "../../../Context/Provider";
import { Button } from "../../../components/Button";


const IncomeCalculation = () => {
    const { stepFlag, triggerStep } = useContext(LessonStepContext);

    function changeStep() {
        triggerStep(true)
    }

    const { authUser, setAuthUser } = useContext(UserContext);


    return (
        <div>
        <div className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start">
        Your income: <span className="font-bold">${authUser?.yearly_income.toLocaleString()}</span>
        </div>
        <div className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start">
        Your savings goal: <span className="font-bold">${authUser?.snapshot?.retirement?.future_savings.toLocaleString()}</span>
        </div>
        
             <Button
              fullWidth={true}
              label="NEXT"
              onClick={changeStep}
            />
        </div>
    )
}

export default IncomeCalculation;