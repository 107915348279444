import React from "react";

const MagicOptionsDataHeader = ({
  text,
  onClick,
}: {
  text?: string;
  onClick?: () => void;
}) => {
  return (
    <span className="text-white  md:text-[16px] text-[20px] min-[1240px]:text-[16px] font-bold tracking-[-0.02em] font-satoshi">
      {text}
    </span>
  );
};

export default MagicOptionsDataHeader;
