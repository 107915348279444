import React, { useState } from "react";
import { Button } from "../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import Inputs from "../../common/Inputs";
import SelectDropdown from "../../common/SelectDropdown";
import { useForm } from "react-hook-form";
import { SecondPieceValidation } from "../../common/utils";
function SecondPiece({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) {
  const [defaultValues, setDefaultValues] = useState<any>({
    income: data?.income || "",
    unit: data?.unit || "Yearly",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(SecondPieceValidation),
  });
  const formData = (data: any) => {
    formAction({ data });
  };

  return (
    <>
      {/* <Form submit={formAction} /> */}
      {/* <form onSubmit={handleSubmit(formData)}> */}
      <div className="flex flex-wrap lg:flex-nowrap mt-[0px] mb-[15px] justify-between px-[5px]">
        <div className="lg:w-[60%] lg:mr-[15px] w-full mb-[15px] lg:mb-[0] ">
          <Inputs
            label=""
            register={register}
            name="income"
            inputType="number"
            inputText="Income"
            required={true}
            error={errors}
            helpText="To get this figure, just calculate your expenditures over the selected amount of time."
          />
        </div>

        <div className="lg:w-[40%] w-full">
          <SelectDropdown
            label=""
            register={register}
            name="unit"
            defaultValue={data?.unit || "Yearly"}            
            control={control}            
            required={true}
            error={errors}
            options={["Weekly", "Monthly", "Yearly"]}
            helpText="In this field, fill in the value that best represents your yearly or monthly expenses."
          />
        </div>
      </div>
      <Button onClick={handleSubmit(formData)} label="NEXT" left={true} />
    </>
  );
}

export default SecondPiece;
