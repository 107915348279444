import { FormControlLabel, Radio } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { ConfirmPlanValidation } from "../../../common/utils";
import { LessonStepContext } from "../../../Context/Provider";

const Arr: any = [
  "Yes",
  "No",
];

const ArrValues:any = [
  1,
  2,
]

export function returnConfirmPlanValue(choice:string) {
  return (ArrValues[Arr.indexOf(choice)])
}

export function returnRiskProfileDescription(choice:number) {
  return (ArrDescriptionsStart[choice] + ArrDescriptionsMiddle[choice] + ArrDescriptionsEnd[choice])
}

const ArrDescriptionsMiddle = [
  "very aggressive investor",
    "aggressive investor",
    "moderate investor",
    "conservative investor",
    "very conservative investor",
]
const ArrDescriptionsStart = [
  "As a ",
    "As an ",
    "As a ",
    "As a ",
    "As a ",
]
const ArrDescriptionsEnd = [
  ", your portfolio will be heavily concentrated in equities, including speculative areas of the market. The objective is to maximize returns despite the potential for significant short-term fluctuations and even long-term losses. This approach generally outperforms more conservative strategies in terms of potential returns.",
    ", your portfolio will be heavily weighted towards equities, aiming for high returns. While short-term fluctuations are expected, this strategy has the potential to outperform more conservative approaches, particularly over longer time periods.",
    ", your portfolio will have a mix of equities and less risky assets like cash, fixed-income securities, and real estate. This balanced approach aims to achieve a moderate level of stability and returns, though some short-term volatility may be expected. Over longer periods, it typically outperforms a conservative strategy but may lag behind a higher-risk approach in terms of potential returns.",
    ", your portfolio will mainly consist of cash, fixed-income securities, and a limited exposure to equities. This strategy prioritizes stability over maximizing returns and aims to minimize short-term volatility. While the overall return is not guaranteed, it is expected to fall within a relatively narrow range. However, over longer periods, such as more than five years, the returns from this conservative approach may potentially be lower compared to a higher-risk strategy.",
    ", you prioritize stability and minimize risk by focusing on cash and fixed-income securities. While this approach offers a narrow range of returns and reduces short-term volatility, it may underperform higher-risk strategies over longer periods, like five years or more.",
]


const ConfirmPlanPiece = ({
  data,
  formChoice,
  formAction,
}: {
  data?: any;
  formChoice: Function;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);

  const [selectedValue, setSelectedValue] = useState<any>(
    data?.confirmPlan || ""
  );
  const [defaultValues, setDefaultValues] = useState<any>({
    confirmPlan: data?.confirmPlan || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(ConfirmPlanValidation),
  });
  const getData = (data: any) => {
    formAction({ data });
    triggerStep(true)
  };
  


  return (
    <div>
      <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]">{ArrDescriptionsStart[formChoice()-1]} <span className="font-bold">{ArrDescriptionsMiddle[formChoice()-1]}</span>{ArrDescriptionsEnd[formChoice()-1]}</div>
      <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5] mt-10">Does this look like a good match for you?</div>
      {Arr?.map((e: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <FormControlLabel
              className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="confirmPlan"
                  render={({ field, ...props }) => {
                    return (
                      <Radio
                        checked={e === selectedValue}
                        onChange={() => {
                          field .onChange(e);
                          setSelectedValue(e);
                        }}
                      />
                    );
                  }}
                />
              }
              label={e}
            />
          </React.Fragment>
        );
      })}
      <span>
        {errors?.["confirmPlan"] && (
          <p className="text-green-600 text-[12px] mt-[5px]">
            {errors?.["confirmPlan"]?.message?.toString() || ""}
          </p>
        )}
      </span>
      <Button
        removeBottomSpace={true}
        onClick={handleSubmit(getData)}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default ConfirmPlanPiece;
