import { TextField } from "@mui/material";
import classNames from "classnames";
import decode from "jwt-decode";
import { getToken } from "../../utils/apiClient";

const CustomTextField = ({ edit, label, required, ...props }: any) => (
  <div>
    {edit && (
      <div className="text-[#0B4547] mb-1 ml-1 text-[14px]">
        {label} {required && <span className="text-red-600">*</span>}
      </div>
    )}
    <TextField
      variant={edit ? "outlined" : "filled"}
      className="w-[90%] bg-transparent"
      InputProps={{
        style: {
          backgroundColor: "white",
          border: edit ? "2px solid #0B4547" : undefined,
          borderRadius: edit ? 10 : undefined,
        },
      }}
      disabled={!edit}
      {...props}
    />
  </div>
);

const MyInfo = ({
  edit,
  setData,
  data,
}: {
  data?: any;
  edit?: any;
  setData: Function;
  setEdit?: Function;
}) => {
  const token = getToken();
  const decodedToken: any = decode(token);
  const passwordFieldVisible = !decodedToken?.identities;

  return (
    <div
      className={classNames("flex flex-wrap", {
        "gap-y-1": !edit,
        "gap-y-4": edit,
      })}
    >
      <div className="w-2/5">
        <CustomTextField
          required
          label="Name"
          placeholder="Full Name"
          edit={edit}
          onChange={(e: any) =>
            setData((current: any) => ({ ...current, fullName: e.target.value }))
          }
          value={data.fullName}
        />
      </div>
      <div className="w-1/5">
        <CustomTextField
          required
          label="Age"
          placeholder="Age"
          edit={edit}
          type="number"
          onChange={(e: any) =>
            setData((current: any) => ({
              ...current,
              age: Number(e.target.value),
            }))
          }
          value={data.age}
        />
      </div>
      <div className="w-2/5">
        <CustomTextField
          required
          label="Email"
          placeholder="Email"
          edit={edit}
          onChange={(e: any) =>
            setData((current: any) => ({ ...current, email: e.target.value }))
          }
          value={data.email}
          disabled
        />
      </div>
      <div className="w-1/2">
        <CustomTextField
          required
          label="Yearly Income"
          placeholder="Yearly Income"
          edit={edit}
          type="number"
          onChange={(e: any) =>
            setData((current: any) => ({
              ...current,
              yearly_income: Number(e.target.value)
            }))
          }
          value={Number(data.yearly_income)}
        />
      </div>
      <div className="w-1/2">
        <CustomTextField
          required
          label="Projected Social Security Per Month"
          placeholder="Projected Social Security Per Month"
          edit={edit}
          type="number"
          onChange={(e: any) =>
            setData((current: any) => ({
              ...current,
              social_security_estimate: Number(e.target.value),
            }))
          }
          value={Number(data.social_security_estimate)}
        />
      </div>
      <div className="w-1/2 hidden">
        <CustomTextField
          required
          label="Username"
          placeholder="Username"
          edit={edit}
          onChange={(e: any) =>
            setData((current: any) => ({ ...current, username: e.target.value }))
          }
          value={data.username}
          disabled
        />
      </div>
      {edit && passwordFieldVisible && (
        <div className="w-1/2">
          <CustomTextField
            required
            label="Password"
            placeholder="Current Password"
            edit={edit}
            onChange={(e: any) =>
              setData((current: any) => ({
                ...current,
                password: e.target.value,
              }))
            }
            value={data.password}
            type="password"
          />
        </div>
      )}
      {edit && passwordFieldVisible && (
        <div className="w-1/2">
          <CustomTextField
            required
            label="New Password"
            placeholder="New Password"
            edit={edit}
            onChange={(e: any) =>
              setData((current: any) => ({
                ...current,
                newPassword: e.target.value,
              }))
            }
            value={data.newPassword}
            type="password"
          />
        </div>
      )}
      {edit && passwordFieldVisible && (
        <div className="w-1/2">
          <CustomTextField
            required
            label="Confirm Password"
            placeholder="Confirm Password"
            edit={edit}
            onChange={(e: any) =>
              setData((current: any) => ({
                ...current,
                confirmPassword: e.target.value,
              }))
            }
            value={data.confirmPassword}
            type="password"
          />
        </div>
      )}
    </div>
  );
};

export default MyInfo;
