import { Alert, Snackbar } from "@mui/material";
import { useContext, useState } from "react";
import { HeaderFooterContext, OpenSnakBarContext, SnakBarMessageContext } from "../Context/Provider";
import Footer from "./Footer";
import Header from "./Header";

export const Layout = ({ children }: { children: JSX.Element }) => {
  const { show, setShow } = useContext(HeaderFooterContext);
  const { openSnakBar, setOpenSnakBar } = useContext(OpenSnakBarContext);
  const { snakBarMessage, setSnakBarMessage } = useContext(
    SnakBarMessageContext
  );
  function handleClose(event: any, reason: string) {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnakBar(false);
  }
  return (
    <main>
      <Snackbar
        open={openSnakBar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            setOpenSnakBar(false);
          }}
          severity={snakBarMessage?.severity || "error"}
          sx={{ width: "100%" }}
        >
          {snakBarMessage?.msg}
        </Alert>
      </Snackbar>
      {<Header />}
      {children}
      {<Footer />}
    </main>
  );
};
