import React from 'react'

const InfoIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M17.5078 18.959H2.50781C2.16615 18.959 1.88281 18.6757 1.88281 18.334C1.88281 17.9923 2.16615 17.709 2.50781 17.709H17.5078C17.8495 17.709 18.1328 17.9923 18.1328 18.334C18.1328 18.6757 17.8495 18.959 17.5078 18.959Z"
          fill="currentColor"
        />
        <path
          d="M4.67448 6.98438H3.34115C2.88281 6.98438 2.50781 7.35937 2.50781 7.81771V15.001C2.50781 15.4594 2.88281 15.8344 3.34115 15.8344H4.67448C5.13281 15.8344 5.50781 15.4594 5.50781 15.001V7.81771C5.50781 7.35104 5.13281 6.98438 4.67448 6.98438Z"
          fill="currentColor"
        />
        <path
          d="M10.6745 4.32422H9.34115C8.88281 4.32422 8.50781 4.69922 8.50781 5.15755V14.9992C8.50781 15.4576 8.88281 15.8326 9.34115 15.8326H10.6745C11.1328 15.8326 11.5078 15.4576 11.5078 14.9992V5.15755C11.5078 4.69922 11.1328 4.32422 10.6745 4.32422Z"
          fill="currentColor"
        />
        <path
          d="M16.6745 1.66602H15.3411C14.8828 1.66602 14.5078 2.04102 14.5078 2.49935V14.9993C14.5078 15.4577 14.8828 15.8327 15.3411 15.8327H16.6745C17.1328 15.8327 17.5078 15.4577 17.5078 14.9993V2.49935C17.5078 2.04102 17.1328 1.66602 16.6745 1.66602Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
}

export default InfoIcon