import MagicWork from "./MagicWork";
import MagicOptions from "./MagicOptions";
import MagicAssumptions from "./MagicAssumptions";
import { Constants, Rates } from "../common/settings";
import classNames from "classnames";

const CommonMyPlan = ({
  touched,
  setTouched,
  planData,
  handlePlanDataChange,
  validationCheck,
  onReset,
  setPlanData,
  dashboard
}: {
  touched: any;
  setTouched: any;
  planData: any;
  handlePlanDataChange: any;
  validationCheck: any;
  onReset: any;
  setPlanData: any;
  dashboard?: boolean;
}) => {
  return (
    <div className="max-h-[60vh] lg:max-h-[100%] overflow-scroll overflow-x-hidden">
      <div className={classNames("lg:pt-[10px] lg:pb-[10px]", {
        "lg:px-[50px]": !dashboard
      })}>
        <div className="mb-[20px]">
          <MagicWork
            touched={touched}
            setTouched={setTouched}
            data={planData}
            setData={handlePlanDataChange}
            onSave={validationCheck}
            onReset={onReset}
          />
        </div>
        <MagicOptions
          data={planData}
          setData={setPlanData}
          text={"Lifestyle"}
          tooltip="Think about your spending trends today- do they look the same in retirement, or a little different?"
          touched={touched}
          setTouched={setTouched}
          options={[
            "Very frugal",
            "More frugal",
            "Same as now",
            "More extravagant",
            "Very extravagant",
          ]}
        />
        <MagicOptions
          data={planData}
          setData={setPlanData}
          text={"Housing"}
          tooltip="How will housing look for you? Will costs be about the same or differnt?"
          touched={touched}
          setTouched={setTouched}
          options={[
            "Minimalistic",
            "Cost less",
            "Same as now",
            "Cost more",
            "Lavish",
          ]}
        />
        {/* <MagicSuggessions
              options={[
                "Save $300/month more and get a side job.",
                "Retire at 69 and live cheaper.",
                "Reduce your home costs and save $100 more",
              ]}
            /> */}
        <MagicAssumptions
          options={[
            {
              text: "Before retirement interest rate:",
              rate: Rates.INTEREST_RATE + "%",
            },
            {
              text: "After retirement interest rate:",
              rate: Rates.INTEREST_RATE_AFTER_RETIREMENT + "%",
            },
            { text: "Inflation:", rate: Rates.INFLATION_RATE + "%" },
            {
              text: "Life expectancy:",
              rate: Constants.LIFEEXPECTANCY.toString(),
            },
            {
              text: "Social security increases:",
              rate: Rates.SOCIAL_SECURITY_INCREASE + "%",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default CommonMyPlan;
