import { createBrowserRouter, Outlet } from "react-router-dom";
import App from "./App";
import Dashboard from "./pages/dashboard";
import MagicScreen from "./pages/magic-screen";
import { Welcome } from "./pages/welcome";
import { Lesson } from "./pages/lessons";
import ReportScreen from "./pages/report"
import SignIn from "./pages/signin";



export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: 'auth',
        element: <Outlet />,
        children: [
          {
            path: 'welcome',
            element: <Welcome />,
          },
          {
            path: 'forgot-pass',
            element: <div>forgot password</div>,
          },
          {
            path: 'sign-in',
            element: <SignIn />
          },
          {
            path: "about-us",
            element:  <About />
          },
          {
            path: "learn",
            element: <Learn/>
          },
          {
            path: "partners",
            element: <Partners/>
          },
          {
            path: "home",
            element: <Home/>
          }
        ],
      },
      {
        path: "magic-screen",
        element: <MagicScreen />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "lesson",
        element: <Lesson />,
      },
      {
        path: "about-us",
        element:  <About />
      },
      {
        path: "learn",
        element: <Learn/>
      },
      {
        path: "partners",
        element: <Partners/>
      },
      {
        path: "home",
        element: <Home/>
      },
      {
        path:"report",
        element: <ReportScreen/>
      }
    ],
  },
]);

/* I feel like there has got to be a better way to do this, but alas, it works */

function About() {
  window.location.href = 'https://www.niagrowth.com/about-us';
  return null;
}

function Learn() {
  window.location.href = 'https://www.niagrowth.com/learn';
  return null;
}

function Partners() {
  window.location.href = 'https://www.niagrowth.com/partner';
  return null;
}

function Home() {
  window.location.href = 'https://www.niagrowth.com/home';
  return null;
}