import React, { useEffect, useState, useContext } from "react";
import { HeaderFooterContext, StepsContext, LessonStepContext } from "../../../Context/Provider";
import { Button } from "../../../components/Button";


const NextButton = () => {
    const { stepFlag, triggerStep } = useContext(LessonStepContext);

    function changeStep() {
        triggerStep(true)
    }

    return (
             <Button
              fullWidth={true}
              label="NEXT"
              onClick={changeStep}
            />
    )
}

export default NextButton;