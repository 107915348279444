import { FormControlLabel, Radio } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { IraAGIValidation } from "../../../common/utils";
import { LessonStepContext } from "../../../Context/Provider";
import { IRA_LIMITS, Rates, ProposalInfo, RetirementInfo, HousingAdjustments, LifestyleAdjustments } from "../../../common/settings";
import { returnTaxStatusValue } from "./TaxStatus";
import {returnMarriedEmployerPlanValue} from "./MarriedEmployerPlanPiece"
import {returnSingleEmployerPlanValue} from "./SingleEmployerPlanPiece"




const IraAGIPiece = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);

  const [selectedValue, setSelectedValue] = useState<any>(
    data?.agiLimit || ""
  );
  const [defaultValues, setDefaultValues] = useState<any>({
    agiLimit: data?.agiLimit || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(IraAGIValidation),
  });
  
  const getData = (data: any) => {
  
    const picked = optionValues.indexOf(data.agiLimit)
    if (picked === 0) {
        data.traditional = true
        data.roth = true
    } else if (picked === 1 && optionValues.length > 2) {
        data.traditional = true
        data.roth = false
    } else {
        data.traditional = false
        data.roth = false
    }
    formAction({ data });
    triggerStep(true)
  };
  const [optionValues,setOptionValues] = useState<any>([])


  useEffect(() => {
  const accountChoice = localStorage.getItem("accountChoice") &&
  JSON.parse(localStorage.getItem("accountChoice") || "") 
  const taxStatus = accountChoice.taxStatus
  const employerPlan = accountChoice.employerPlan

    let choices = []

  if (returnTaxStatusValue(taxStatus.taxStatus) === 1) {
    //single
    
    if (returnSingleEmployerPlanValue(employerPlan.employerPlan) === 1) {
        choices.push("Under $" + IRA_LIMITS.IRA_AGI_SINGLE_EMPLOYER_PLAN.Traditional.toLocaleString())
        if (IRA_LIMITS.IRA_AGI_SINGLE_EMPLOYER_PLAN.Traditional != IRA_LIMITS.IRA_AGI_SINGLE_EMPLOYER_PLAN.Roth) {
            choices.push("Under $"+ IRA_LIMITS.IRA_AGI_SINGLE_EMPLOYER_PLAN.Roth.toLocaleString())        
        }

        choices.push("Over $"+ IRA_LIMITS.IRA_AGI_SINGLE_EMPLOYER_PLAN.Roth.toLocaleString())        
    } else {
        choices.push("Under $" + IRA_LIMITS.IRA_AGI_SINGLE_NO_EMPLOYER_PLAN.Traditional.toLocaleString())
        if (IRA_LIMITS.IRA_AGI_SINGLE_NO_EMPLOYER_PLAN.Traditional != IRA_LIMITS.IRA_AGI_SINGLE_NO_EMPLOYER_PLAN.Roth) {
            choices.push("Under $"+ IRA_LIMITS.IRA_AGI_SINGLE_NO_EMPLOYER_PLAN.Roth.toLocaleString())       
        } 
        choices.push("Over $"+ IRA_LIMITS.IRA_AGI_SINGLE_EMPLOYER_PLAN.Roth.toLocaleString())        
    }

  } else {
    //married
    
    if (returnMarriedEmployerPlanValue(employerPlan.employerPlan) === 1) {
        choices.push("Under $" + IRA_LIMITS.IRA_AGI_MARRIED_SPOUSE_EMPLOYER_PLAN.Traditional.toLocaleString())
        if (IRA_LIMITS.IRA_AGI_MARRIED_SPOUSE_EMPLOYER_PLAN.Traditional != IRA_LIMITS.IRA_AGI_MARRIED_SPOUSE_EMPLOYER_PLAN.Roth) {
            choices.push("Under $"+ IRA_LIMITS.IRA_AGI_MARRIED_SPOUSE_EMPLOYER_PLAN.Roth.toLocaleString())        
        }
        choices.push("Over $"+ IRA_LIMITS.IRA_AGI_MARRIED_SPOUSE_EMPLOYER_PLAN.Roth.toLocaleString())        
    } else if (returnMarriedEmployerPlanValue(employerPlan.employerPlan) === 2) {
        choices.push("Under $" + IRA_LIMITS.IRA_AGI_MARRIED_NO_EMPLOYER_PLAN.Traditional.toLocaleString())
        if (IRA_LIMITS.IRA_AGI_MARRIED_NO_EMPLOYER_PLAN.Traditional != IRA_LIMITS.IRA_AGI_MARRIED_NO_EMPLOYER_PLAN.Roth) {
            choices.push("Under $"+ IRA_LIMITS.IRA_AGI_MARRIED_NO_EMPLOYER_PLAN.Roth.toLocaleString())        
        }
        choices.push("Over $"+ IRA_LIMITS.IRA_AGI_MARRIED_NO_EMPLOYER_PLAN.Roth.toLocaleString())        
    } else {        
        choices.push("Under $" + IRA_LIMITS.IRA_AGI_MARRIED_EMPLOYER_PLAN.Traditional.toLocaleString())
        if (IRA_LIMITS.IRA_AGI_MARRIED_EMPLOYER_PLAN.Traditional != IRA_LIMITS.IRA_AGI_MARRIED_EMPLOYER_PLAN.Roth) {
            choices.push("Under $"+ IRA_LIMITS.IRA_AGI_MARRIED_EMPLOYER_PLAN.Roth.toLocaleString())        
        }
        choices.push("Over $"+ IRA_LIMITS.IRA_AGI_MARRIED_EMPLOYER_PLAN.Roth.toLocaleString())        
    }
    }
    setOptionValues(choices)

},[])


  return (
    <div>
      <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]"></div>
      {optionValues?.map((e: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <FormControlLabel
              className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="agiLimit"
                  render={({ field, ...props }) => {
                    return (
                      <Radio
                        checked={e === selectedValue}
                        onChange={() => {
                          field .onChange(e);
                          setSelectedValue(e);                                                    
                        }}
                      />
                    );
                  }}
                />
              }
              label={e}
            />
          </React.Fragment>
        );
      })}
      <span>
        {errors?.["agiLimit"] && (
          <p className="text-green-600 text-[12px] mt-[5px]">
            {errors?.["agiLimit"]?.message?.toString() || ""}
          </p>
        )}
      </span>
      <Button
        removeBottomSpace={true}
        onClick={handleSubmit(getData)}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default IraAGIPiece;
