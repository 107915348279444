import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { HousingAdjustments, HousingOptions, LifestyleAdjustments, LifestyleOptions } from "../common/settings";

const MagicOptions = ({
  text,
  options,
  tooltip,
  data,
  touched,
  setTouched = () => {},
  setData = () => {},
  disabled,
}: {
  text: string;
  options?: any;
  tooltip?:string;
  data?: any;
  touched?: any;
  setTouched?: Function;
  setData?: Function;
  disabled?: boolean;
}) => {
  const [selectedText, setSelectedText] = useState<any>(data[text] || "");
  useEffect(() => {
    if (!data[text]) {
      setSelectedText("");
    } else {
      setSelectedText(data[text]);
    }
  }, [data[text]]);
  
  function createAdjustmentString(type: string) {
    let string = ""
      if (type === "Housing") {
        const pct = HousingAdjustments[HousingOptions.indexOf(data[text])]
        string = pct > 0 ? "+" : ""
        string += pct*100 + "%"
        
      } else
      {
        const pct = LifestyleAdjustments[LifestyleOptions.indexOf(data[text])]
        string = pct > 0 ? "+" : ""
        string += pct*100 + "%"

      }
      return (string)
  }

  return (
    <div>
      <h3 className="text-[#0B4547] text-[22px] lg:text-[20px] font-bold text-center tracking-[-0.02em] mb-[5px] flex items-center justify-center">
        {text}{" ("}
          {createAdjustmentString(text)}
        { ")"}
        <Tooltip title={tooltip || ""}>
        <img src="/assets/message-question.svg" alt="question" className="ml-[10px]"
/>
      </Tooltip>     
      
        {/* <img
          src="/assets/message-question.svg"
          alt="question"
        /> */}
      </h3>
      
      <div className="flex flex-wrap items-center justify-center gap-[15px] lg:gap-[30px] mb-[15px]">
        {options?.map((e: any, i: any) => {
          return (
            <div
              onClick={() => {
                setTouched(true);
                setSelectedText(e);
                setData({ ...data, [text]: e });
              }}
              className={`text-[#161616] ${disabled && `pointer-events-none`} ${
                selectedText === e ? "bg-[#0B4547] text-white" : ""
              }  cursor-pointer text-base font-medium text-center tracking-[-0.02em] px-[30px] py-[6px] border-2 border-[#0B4547]`}
              key={i}
            >
              {e}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MagicOptions;
