import { getAPIData, postAPIData } from "../../utils/apiClient";
import NextButton from "../lessonContent/accountForms/NextButton"
import PercentageSavePiece from "./saveAmountForms/percentageSavePiece";
import IncomeCalculation from "./saveAmountForms/IncomeCalculation";
import Taxes from "./saveAmountForms/Taxes";
import Expenses from "./saveAmountForms/Expenses";
import Housing from "./saveAmountForms/Housing";
import Results from "./saveAmountForms/Results";

export const saveAmountSlides = [
  {
    title: "Let's talk savings strategies!",
    key: "start",
    subtitle: "",
    body: "Being self employed can make it more challenging to save automatically compared to a W2 employee. Aside from the lack of infrastructure, there are normal ups and downs that come with self-employment.",
    body2: "With Nia, there are a few strategies you can implement to make saving easy, automatic, and adjustable.",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: false,
    currentStep: 0,
    children: (
        <div>
          <NextButton/>
        </div>
    ),
    choice: (num: number) => {
      return ("history")
    },

  },
  {
    title: "Your Goals",
    key: "history",
    subtitle: "You can change these numbers in the My Info and My Plan sections of your dashboard.",
    body: "",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <div>
          <IncomeCalculation/>
        </div>
    ),
    choice: (num: number) => {
      return ("transaction-approach")
    },
  },
  {
    title: "Taxes",
    key: "taxes",
    subtitle: "",
    body: "The effective tax rate is the average percentage of your income that you pay in taxes.",
    body2: "For instance, if you have an Adjusted Gross Income (before deductions) of $50,000 and your total tax liability is $8,000, your effective tax rate would be 16%.",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <div>
          <Taxes data={{}}
                 formAction={({data}: any) => {
                   localStorage.setItem(
                       "taxes",
                       JSON.stringify(data.percentage))
                 }}/>
        </div>
    ),
    choice: (num: number) => {
      return ("housing")
    },
  },
  {
    title: "Housing",
    key: "housing",
    subtitle: "",
    body: "Experts recommend spending no more than 30% of your monthly gross income on housing. This includes rent and utilities for renters, and mortgage interest, property taxes, and maintenance for homeowners.",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <Housing data={{taxes: JSON.parse(localStorage.getItem("taxes") || "19")}}
                 formAction={({data}: any) => {
                   localStorage.setItem(
                       "housing",
                       JSON.stringify(data.percentage))
                 }}/>
    ),
    choice: (num: number) => {
      return ("expenses")
    },
  },
  {
    title: "Expenses",
    key: "expenses",
    subtitle: "",
    body: "This is your budget for needs like utilities, debt payments, food, transportation, healthcare, and childcare.",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <Expenses data={{}}
                  formAction={({data}: any) => {
                    localStorage.setItem(
                        "expenses",
                        JSON.stringify(data.percentage))
                  }}/>
    ),
    choice: (num: number) => {
      return ("results")
    },
  },
  {
    title: "Results",
    key: "results",
    subtitle: "",
    body: "Let's look at your overall budget.",
    body2: "",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <Results data={{}}
                 formAction={({data}: any) => {
                 }}/>
    ),
    choice: (num: number) => {
      return ("percentage-slider")
    },
  },
  {
    title: "Transaction strategy",
    key: "transaction-approach",
    subtitle: "Set it and forget it",
    body: "We know taxes, savings, and finances are more complex for the self-employed. Nia is here to help “pay yourself first”, especially to your “future-self”.  This way, at tax time, you have one less thing to worry about.",
    body2: "With Nia, you can set-it-and-forget-it. We’ll help you define what x% from each incoming transaction should go to your retirement account, so that you pay yourself first, and can focus on your business.",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <div>
          <NextButton/>
        </div>
    ),
    choice: (num: number) => {
      return ("monthly-approach")
    },
  },
  {
    title: "Smoothing income fluctuations",
    key: "monthly-approach",
    subtitle: "Set it and forget it",
    body: "Self-employed people can temper the ups and downs that come with seasonality or economic shifts by choosing a static number to pay themselves every month and putting the rest into savings. With Nia you can set this up to happen automatically.",
    body2: "During good months with higher earnings, you can save more, while during slower or challenging months, you save a smaller amount.This approach helps maintain a consistent take-home income while still ensuring savings are being set aside for the future.",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <div>
          <NextButton/>
        </div>
    ),
    choice: (num: number) => {
      return ("taxes")
    },
  },
  {
    title: "Set your percentage",
    key: "percentage-slider",
    subtitle: "Commit to saving",
    body: "Adjust the slider below to set your goal savings rate.",
    body2: "We'll set this up as your target in your plan so later you can set up an automatic transfer for each transaction.",
    link: "",
    linkText: "",
    image: "/assets/screen-puzzle.svg",
    hideBack: true,
    currentStep: 0,
    children: (
        <div>
          <PercentageSavePiece
              data={{}}
              formAction={({data}: any) => {
                localStorage.setItem(
                    "saveAmount",
                    JSON.stringify(data.percentage),
                );
              }}/>
        </div>
    ),
    choice: (num: number) => {
      updatePctSaveInfo()

      return ("dashboard")
    },
  },


]

async function updatePctSaveInfo() {
  const amount = localStorage.getItem("saveAmount") &&
      JSON.parse(localStorage.getItem("saveAmount") || "");

  const housing = localStorage.getItem("housing") &&
      JSON.parse(localStorage.getItem("housing") || "");

  const expenses = localStorage.getItem("expenses") &&
      JSON.parse(localStorage.getItem("expenses") || "");

  const taxes = localStorage.getItem("taxes") &&
      JSON.parse(localStorage.getItem("taxes") || "");

  const payload = {
    pct_saved: amount,
    pct_housing: housing,
    pct_expenses: expenses,
    pct_taxes: taxes,
  }

  const res = await postAPIData({
    url: "v1/users/update-ss",
    data: payload,
  });

  const lesson_payload = {
    pct_choice: true,
  }

  const res2 = await postAPIData({
    url: "v1/users/update-lesson",
    data: lesson_payload,
  });

  localStorage.removeItem("saveAmount");
  localStorage.removeItem("housing");
  localStorage.removeItem("expenses");
  localStorage.removeItem("taxes");

}