import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link'

import RoundedButton from "./RoundedButton";
import { getAPIData, postAPIData } from '../utils/apiClient'

interface SetAccessTokenArgs {
  user_id: string
  public_token: string
  institution_id: string
  institution_name: string
}


const CurrentSaving = ({
  amount,
  savings,
}: {
  amount?: number;
  savings?: Array<any> | null;
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const [token, setToken] = useState<string | null>(null)
  // Handle empty document cache
  // https://formidable.com/open-source/urql/docs/basics/document-caching/#adding-typenames
  const context = useMemo(() => ({ additionalTypenames: ['LinkToken'] }), [])

  // todo: get link token

  // todo: set access token

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    postAPIData({
      url: "v1/users/plaid/set-access-token",
      data: {
        public_token: publicToken,
        institution_id: metadata.institution?.institution_id || '',
        institution_name: metadata.institution?.name || '',
        user_id: '1',
      },
    })
    // await setAccessToken({
    //   public_token: publicToken,
    //   institution_id: metadata.institution?.institution_id || '',
    //   institution_name: metadata.institution?.name || '',
    //   user_id: '1',
    // })
  }, [])
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log('Event')
    console.log(eventName, metadata)
  }, [])
  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log('Exit')
    console.log(error, metadata)
  }, [])

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  }
  const {
    open,
    ready,
  } = usePlaidLink(config)

  useEffect(() => {
    if (token && ready) {
      open()
    }
  }, [token, ready])

  return (
    <div className="flex flex-col">
      <div className="flex justify-between border-b border-b-[#D9D9D9] pb-[10px] mb-[30px] items-end">
        <div className="text-base font-medium text-right font-satoshi tracking-[-0.02em] text-[#161616] ">
          Current Saving
        </div>
        <p className="xl:text-[48px] text-[28px] font-bold font-satoshi flex items-center tracking-[-0.02em] text-[#0B4547] leading-[1]">
          ${amount}
          <sub className="leading-[1] font-normal text-[14px] xl:text-[24px]"></sub>
        </p>
      </div>

      <div className="flex justify-end">
        <div>
        <RoundedButton
          text={"Link Account"}
          onClick={async () => {
            const token = await getAPIData({
              url: "v1/users/plaid/link-token",
            });
            setToken(token.data.link_token);
          }}
        />
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Coming Soon!</p>
          </div>
        </div>
        
      )}
      </div>
      </div>

      {/* <div className="flex flex-col gap-2 mt-8 w-3/4 ml-auto">
        {savings?.map((each) => (
          <div className="flex text-center justify-between">
            <div className="font-bold">
              {moment(each.saving_date).format("MMM Do YYYY")}
            </div>
            <div className="text-center">${each.amount}</div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default CurrentSaving;
