import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

export const signUpUsingForm = async ( data : { userName: string, email: string, password: string }) => {
  const { userName, email, password } = data;
  try {
    const user = await Auth.signUp({
      username: userName,
      password,
      attributes: {
        email
      },
      autoSignIn: {
        enabled: true
      }
    })
  } catch (err) {
    console.log(err)
  }
}

export const signUp = async () => {
  await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
}

export const googleSignIn =  async () => {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google})
}

export const facebookSignIn = async () => {
  await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook})
}

export const signOut = async () => {
  await Auth.signOut();
  localStorage.removeItem("niaTokens");
  localStorage.removeItem("CurrentStep");
  localStorage.removeItem("StepsData");
  localStorage.removeItem("MagicPlan");
}

export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, oldPassword, newPassword);
};
