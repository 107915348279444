import { useMemo } from "react";

const range = 300;
const pointerDeg = [
  1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15, -16,
  -17, -18, -19,
];
const pointerTranslate = [
  "-3px -6px",
  "0px 0px",
  "2px 6px",
  "6px 10px",
  "8px 18px",
  "11px 22px",
  "14px 28px",
  "18px 34px",
  "21px 39px",
  "24px 44px",
  "28px 51px",
  "31px 55px",
  "35px 60px",
  "39px 65px",
  "43px 70px",
  "46px 76px",
  "50px 81px",
  "54px 86px",
  "59px 90px",
  "63px 96px",
  "67px 99px",
];

const Libra = ({
  havePay = 0,
  wantPay = 0,
}: {
  havePay?: number;
  wantPay?: number;
}) => {
  const rangeNum = useMemo(() => {
    const num = 10 - Math.round((wantPay - havePay) / range);
    if (num < 0) return 0;
    if (num >= 20) return 20;
    return num;
  }, [havePay, wantPay]);
  

  const pointerStyle = useMemo(() => {
    return {
      transformOrigin: "5px bottom",
      transform: `rotate(${pointerDeg[rangeNum]}deg)`,
      translate: pointerTranslate[rangeNum],
    };
  }, [rangeNum]);

  const platformDeg = useMemo(() => {
    return {
      transform: `rotate(${rangeNum / -2}deg)`,
      transformOrigin: "center",
    };
  }, [rangeNum]);

  const { leftColumn, rightColumn } = useMemo(() => {
    const defaultColumn = {
      leftColumn: 60,
      rightColumn: 60,
    };
    if (rangeNum > 10) {
      defaultColumn.leftColumn =
        defaultColumn.leftColumn - (rangeNum - 10) * 2.5;
    } else {
      defaultColumn.rightColumn =
        defaultColumn.rightColumn + (rangeNum - 10) * 2.5;
    }
    return defaultColumn;
  }, [rangeNum]);

  const { leftColumnTranslate, rightColumnTranslate } = useMemo(() => {
    const defaultColumn = {
      leftColumnTranslate: 4,
      rightColumnTranslate: -20,
    };
    if (rangeNum > 10) {
      defaultColumn.leftColumnTranslate =
        defaultColumn.leftColumnTranslate + (rangeNum - 10) * 2.5;
    } else {
      defaultColumn.rightColumnTranslate =
        defaultColumn.rightColumnTranslate - (rangeNum - 10) * 2.5;
    }
    return defaultColumn;
  }, [rangeNum]);

  return (
    <div className="relative h-96 -mt-16 lg:mt-0 transition-all" style={{ width: 560, zoom: .9 }}>
      <div
        className="w-2.5 absolute"
        style={{
          backgroundColor: "#444C57",
          left: "29.5%",
          bottom: "34%",
          height: leftColumn,
        }}
      ></div>
      <div
        className="w-2.5 absolute"
        style={{
          backgroundColor: "#444C57",
          right: "29.5%",
          bottom: "34%",
          height: rightColumn,
        }}
      ></div>
      <svg
        width="685"
        height="386"
        viewBox="0 0 685 386"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-full"
      >
        {/* <path
          d="M214.02 196.424H201.883V267.56H214.02V196.424Z"
          fill="#444C57"
        />
        <path
          d="M483.799 223.268H471.662V267.561H483.799V223.268Z"
          fill="#444C57"
        /> */}
        <path
          d="M327.129 280.591H357.177L355.421 210.196H330.269L327.129 273.591Z"
          fill="#0C4548"
        />
        <mask
          id="mask1_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="187"
          y="209"
          width="310"
          height="49"
        >
          <path
            d="M483.802 246.979C483.802 244.557 485.768 242.591 488.19 242.591C490.613 242.591 492.579 244.553 492.579 246.979C492.579 249.402 490.613 251.367 488.19 251.367C485.768 251.367 483.802 249.405 483.802 246.979ZM189.823 217.524C189.823 215.105 191.789 213.139 194.211 213.139C196.634 213.139 198.599 215.105 198.599 217.524C198.599 219.947 196.634 221.912 194.211 221.912C191.789 221.909 189.823 219.947 189.823 217.524ZM187.411 224.535L339.92 248.663L496.089 257.143L496.967 239.159L346.502 224.388L188.25 209.318L187.411 224.535Z"
            fill="white"
          />
        </mask>
        <g style={platformDeg}>
          <g mask="url(#mask1_3977_13346)">
            <path
              d="M496.964 209.322H187.412V257.147H496.964V209.322Z"
              fill="url(#paint0_linear_3977_13346)"
            />
          </g>
          <mask
            id="mask2_3977_13346"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="496"
            y="244"
            width="55"
            height="15"
          >
            <path
              d="M496.284 253.331L550.588 258.65V249.877L496.733 244.076L496.284 253.331Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_3977_13346)">
            <path
              d="M550.59 244.076H496.282V258.65H550.59V244.076Z"
              fill="url(#paint1_linear_3977_13346)"
            />
          </g>
          <mask
            id="mask3_3977_13346"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="517"
            y="241"
            width="21"
            height="22"
          >
            <path
              d="M517.32 260.604L536.329 262.652L537.524 244.578L518.879 241.691L517.32 260.604Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_3977_13346)">
            <path
              d="M537.525 241.691H517.321V262.648H537.525V241.691Z"
              fill="url(#paint2_linear_3977_13346)"
            />
          </g>
          <mask
            id="mask4_3977_13346"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="133"
            y="207"
            width="56"
            height="15"
          >
            <path
              d="M133.302 215.432L188.227 221.611L188.723 212.563L134.369 207.197L133.302 215.432Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_3977_13346)">
            <path
              d="M188.722 207.197H133.301V221.611H188.722V207.197Z"
              fill="url(#paint3_linear_3977_13346)"
            />
          </g>
          <mask
            id="mask5_3977_13346"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="148"
            y="202"
            width="21"
            height="23"
          >
            <path
              d="M148.212 223.07L166.903 224.818L168.83 204.282L150.26 202.748L148.212 223.07Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_3977_13346)">
            <path
              d="M168.83 202.748H148.212V224.821H168.83V202.748Z"
              fill="url(#paint4_linear_3977_13346)"
            />
          </g>
          <mask
            id="mask6_3977_13346"
            // style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="685"
            height="386"
          >
            <path d="M685 0H0V385.312H685V0Z" fill="white" />
          </mask>
        </g>

        <g
          mask="url(#mask6_3977_13346)"
          style={{
            transform: `translateY(${leftColumnTranslate}px)`,
          }}
        >
          <path
            d="M291.175 194.511H124.285C117.017 194.511 111.124 188.621 111.124 181.35V165.07H304.333V181.35C304.333 188.617 298.442 194.511 291.175 194.511Z"
            fill="#505C67"
          />
          <path
            d="M283.088 198.865H131.635C128.674 198.865 125.866 197.537 123.983 195.247L123.348 194.473H291.372L290.737 195.247C288.857 197.537 286.049 198.865 283.088 198.865Z"
            fill="#444C57"
          />

          <text
            x="207"
            y="187"
            fill="#FFFFFF"
            fontSize="16"
            fontFamily="Arial"
            textAnchor="middle"
          >
          $PerYear
          </text>
          <text
            x="207"
            y="118"
            fill="#000000"
            fontSize="20"
            fontFamily="Arial"
            textAnchor="middle"
            fontWeight={200}
          >            
            Will Have
          </text>
          <text
            x="207"
            y="150"
            fill="#0B4547"
            fontSize="24"
            fontFamily="Arial"
            textAnchor="middle"
            fontWeight={"bold"}
          >
            ${havePay.toLocaleString()}
          </text>
        </g>
        <mask
          id="mask7_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>
        <mask
          id="mask8_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>
        <g
          mask="url(#mask8_3977_13346)"
          style={{
            transform: `translateY(${rightColumnTranslate}px)`,
          }}
        >
          <path
            d="M561.176 219.741H394.285C387.018 219.741 381.124 213.851 381.124 206.58V190.301H574.333V206.58C574.337 213.848 568.443 219.741 561.176 219.741Z"
            fill="#505C67"
          />
          <path
            d="M553.089 224.095H401.636C398.675 224.095 395.867 222.768 393.987 220.477L393.352 219.703H561.377L560.742 220.477C558.858 222.768 556.05 224.095 553.089 224.095Z"
            fill="#444C57"
          />

          <text
            x="478.5"
            y="212"
            fill="#FFFFFF"
            fontSize="16"
            fontFamily="Arial"
            textAnchor="middle"
          >
            $PerYear
          </text>
          <text
            x="478.5"
            y="142"
            fill="#000000"
            fontSize="20"
            fontFamily="Arial"
            textAnchor="middle"
          >
            Want
          </text>
          <text
            x="478.5"
            y="174"
            fill="#0B4547"
            fontSize="24"
            fontFamily="Arial"
            textAnchor="middle"
            fontWeight={"bold"}
          >
            ${wantPay.toLocaleString()}
          </text>
        </g>

        <mask
          id="mask9_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>

        <mask
          id="mask10_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>

        <mask
          id="mask11_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>

        <mask
          id="mask12_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>

        <mask
          id="mask13_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>

        <mask
          id="mask14_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="338"
          y="230"
          width="8"
          height="8"
        >
          <path
            d="M338.948 233.912C338.948 235.846 340.514 237.412 342.448 237.412C344.381 237.412 345.948 235.846 345.948 233.912C345.948 231.978 344.381 230.412 342.448 230.412C340.514 230.412 338.948 231.978 338.948 233.912Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask14_3977_13346)">
          <path
            d="M345.943 230.412H338.946V237.408H345.943V230.412Z"
            fill="url(#paint5_linear_3977_13346)"
          />
        </g>
        <mask
          id="mask15_3977_13346"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="685"
          height="386"
        >
          <path d="M685 0H0V385.312H685V0Z" fill="white" />
        </mask>
        <g mask="url(#mask15_3977_13346)">
          <path
            d="M386.104 295.841H298.203C295.72 295.841 293.704 293.829 293.704 291.342V289.722C293.704 282.491 299.566 276.629 306.798 276.629H377.506C384.738 276.629 390.6 282.491 390.6 289.722V291.342C390.6 293.829 388.587 295.841 386.104 295.841Z"
            fill="#0C4548"
          />
        </g>
        <path
          d="M404.209 295.841H280.085C278.315 295.841 276.881 294.407 276.881 292.637C276.881 290.868 278.315 289.434 280.085 289.434H404.209C405.978 289.434 407.413 290.868 407.413 292.637C407.413 294.407 405.978 295.841 404.209 295.841Z"
          fill="#093535"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3977_13346"
            x1="187.408"
            y1="233.214"
            x2="496.962"
            y2="233.214"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#062827" />
            <stop offset="0.1536" stopColor="#062827" />
            <stop offset="1" stopColor="#062827" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3977_13346"
            x1="496.282"
            y1="251.359"
            x2="550.589"
            y2="251.359"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#062827" />
            <stop offset="0.1536" stopColor="#062827" />
            <stop offset="1" stopColor="#062827" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3977_13346"
            x1="517.321"
            y1="252.164"
            x2="537.524"
            y2="252.164"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#062827" />
            <stop offset="0.1536" stopColor="#062827" />
            <stop offset="1" stopColor="#062827" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3977_13346"
            x1="133.304"
            y1="214.423"
            x2="188.723"
            y2="214.423"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#062827" />
            <stop offset="0.1536" stopColor="#062827" />
            <stop offset="1" stopColor="#062827" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_3977_13346"
            x1="148.212"
            y1="213.808"
            x2="168.829"
            y2="213.808"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#062827" />
            <stop offset="0.1536" stopColor="#062827" />
            <stop offset="1" stopColor="#062827" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_3977_13346"
            x1="338.946"
            y1="233.926"
            x2="345.944"
            y2="233.926"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#062827" />
            <stop offset="0.1536" stopColor="#062827" />
            <stop offset="1" stopColor="#062827" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Libra;
