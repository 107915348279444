export const LineIcon = ({
  color = "white",
  stroke = 15,
  slideAnimationDots,
}: {
  color?: string;
  stroke?: number;
  slideAnimationDots?: boolean;
}) => {
  return (
    <span>
      <div
        className={`h-[5px]  rounded-xl  mx-[2px] ${
          slideAnimationDots
            ? "w-[5px] bg-[#C7C7C7]"
            : "w-[32px] lg:w-[42px] bg-white"
        }`}
      ></div>
    </span>
  );
};
