import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

export const BodyText = ({
  text,
  textSmall,
  helpIcon,
  helpText,
  link2,
  link2text,
}: {
  text: string;
  textSmall?: boolean;
  helpIcon?: boolean;
  helpText?: string;
  link2?:string;
  link2text?:string;
}) => {
  let linkIndex=0
  let beforeText = "";
  let middleText = "";
  let afterText = "";

  if(link2 && link2text) {
    linkIndex = text.indexOf(link2text)        
    beforeText = text.substring(0, linkIndex);
    middleText = link2text;
    afterText = text.substring(linkIndex + link2text.length);

   }

  return (
    <div
      className={`font-satoshi mb-6 ${
        textSmall
          ? "max-[1000px]:text-xs text-xs "
          : "max-[1000px]:text-sm text-[0.9rem] leading-[1.5]"        
      }     
      `}
    >
            {link2 ? (
        <>
        {beforeText}<a className="underline" href={link2}>{middleText}</a>{afterText}
        </>
      ) : (
        <>
        {text}
      </>
      )}
      
      {helpIcon && (
        <Tooltip title={helpText}>
          <HelpOutlineIcon
            className="ml-1 helpIcon !w-[15px]"
            fontSize="small"
          />
        </Tooltip>
      )}
    </div>
  );
};

