import { FormControlLabel, Radio } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Question4Validiation } from "../../../common/utils";
import { LessonStepContext } from "../../../Context/Provider";

const Arr: any = [
  'closely follow the stock market’s performance',
  'slightly trail the stock market’s performance, but still make good profits',
  'trail the stock market’s performance, but still make moderate profits',
  'have some stability and make modest profits',
  'be quite stable, with small profits'
]

const ArrValues:any = [
  1,
  2,
  3,
  4,
  5
]

export function returnQuestion4Value(choice:string) {
  return (ArrValues[Arr.indexOf(choice)])
}

const Question4Piece = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);

  const [selectedValue, setSelectedValue] = useState<any>(
    data?.question4Answer || ""
  );
  const [defaultValues, setDefaultValues] = useState<any>({
    question4Answer: data?.question4Answer || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(Question4Validiation),
  });
  const getData = (data: any) => {
    formAction({ data });
    triggerStep(true)
  };
  return (
    <div>
      <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]"></div>
      {Arr?.map((e: any, i: any) => {
        return (
          <React.Fragment key={i}>
            <FormControlLabel
              className="text-white w-full mt-[10px] !mr-0  !font-satoshi text-base !items-start"
              value={e}
              control={
                <Controller
                  control={control}
                  name="question4Answer"
                  render={({ field, ...props }) => {
                    return (
                      <Radio
                        checked={e === selectedValue}
                        onChange={() => {
                          field .onChange(e);
                          setSelectedValue(e);
                        }}
                      />
                    );
                  }}
                />
              }
              label={e}
            />
          </React.Fragment>
        );
      })}
      <span>
        {errors?.["question4Answer"] && (
          <p className="text-green-600 text-[12px] mt-[5px]">
            {errors?.["businessIncome"]?.message?.toString() || ""}
          </p>
        )}
      </span>
      <Button
        removeBottomSpace={true}
        onClick={handleSubmit(getData)}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default Question4Piece;
