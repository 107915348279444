import { FormControlLabel, Radio, Slider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { HeaderFooterContext, StepsContext, LessonStepContext, UserContext } from "../../../Context/Provider";


const Housing = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);
  const { authUser, setAuthUser } = useContext(UserContext);

  const [selectedValue, setSelectedValue] = useState<any>(
    data?.question2Answer || ""
  );
  const [defaultValues, setDefaultValues] = useState<any>({
    question2Answer: data?.question2Answer || "",
  });

  const getData = (data: any) => {
    formAction({ data });
    triggerStep(true)
  };

  const [percentage, setPercentage] = useState(28);
  const [income, setIncome] = useState(authUser.yearly_income);
  const [housing, setHousing] = useState(Math.round(income*percentage/100/12));
  const [maxPct, setMax] = useState(0)

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPercentage(newValue as number);
    setHousing(Math.round((newValue as number) * income/100/12));
  };

  
  useEffect(() => {
    let taxes = JSON.parse(localStorage.getItem("taxes") || "19")
    let maxValue = (100 - Number(taxes))
    setMax(maxValue)

  },[])


  return (
    
    <div>
<div className="mb-8">
How much do you spend on housing each month?

        </div>
        <div className="ml-6 mr-6">
        <Slider
        valueLabelDisplay="on"
        aria-label="custom thumb label"
        value={percentage}
        onChange={handleChange}
        aria-labelledby="income-slider"        
        min={0}
        max={maxPct}
        valueLabelFormat={percentage+ "%"}
      /></div>
    <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]">
        Monthly Housing: ${housing.toLocaleString()}
      </div>

<Button
        removeBottomSpace={true}
        onClick={() => getData({percentage})}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default Housing;
