import React from 'react'

const PlanIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M8.32467 18.7916C8.283 18.7916 8.24133 18.7916 8.19967 18.775C5.008 18.125 2.41634 15.775 1.44967 12.6583C1.34967 12.325 1.53301 11.975 1.85801 11.875C2.19134 11.775 2.54134 11.9583 2.64134 12.2833C3.47467 14.9666 5.69967 16.9833 8.44967 17.5416C8.79134 17.6083 9.00801 17.9416 8.93301 18.2833C8.87467 18.5833 8.608 18.7916 8.32467 18.7916Z"
          fill="currentColor"
        />
        <path
          d="M18.2918 9.77435C17.9752 9.77435 17.7002 9.53268 17.6668 9.21602C17.2668 5.26602 13.9669 2.29102 10.0002 2.29102C6.02518 2.29102 2.73352 5.26602 2.33352 9.20768C2.30019 9.54935 2.00019 9.80768 1.65019 9.76602C1.30852 9.73268 1.05852 9.42435 1.09185 9.08268C1.55852 4.49935 5.39185 1.04102 10.0002 1.04102C14.6168 1.04102 18.4502 4.49935 18.9085 9.08268C18.9419 9.42435 18.6918 9.73268 18.3502 9.76602C18.3335 9.77435 18.3085 9.77435 18.2918 9.77435Z"
          fill="currentColor"
        />
        <path
          d="M11.6751 18.7923C11.3834 18.7923 11.1251 18.5924 11.0667 18.2924C11.0001 17.9507 11.2167 17.6257 11.5501 17.559C14.2834 17.0007 16.5084 15.0007 17.3501 12.334C17.4501 12.0007 17.8084 11.8174 18.1334 11.9257C18.4667 12.0257 18.6417 12.384 18.5417 12.709C17.5584 15.809 14.9751 18.134 11.8001 18.784C11.7584 18.784 11.7167 18.7923 11.6751 18.7923Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
}

export default PlanIcon