import { useContext, useEffect } from "react";
import {
  OpenSnakBarContext,
  SnakBarMessageContext,
} from "../../Context/Provider";
import EditIcon from "../../Icons/EditIcon";
import RoundedButton from "../RoundedButton";
import classNames from "classnames";

const DisClaimer = ({
  edit,
  data,
  setData = () => {},
  setEdit = () => {},
  containerClassname,
  questionImgClassname,
  editContainerClassname,
  saveText = "Save",
  buttonPlacement = "buttom",
  onSave
}: {
  edit?: any;
  data?: any;
  setData?: Function;
  setEdit?: Function;
  containerClassname?: string;
  questionImgClassname?: string;
  editContainerClassname?: string;
  saveText?: string;
  buttonPlacement?: "top" | "buttom";
  onSave?: Function;
}) => {
  const { openSnakBar, setOpenSnakBar } = useContext(OpenSnakBarContext);
  const { snakBarMessage, setSnakBarMessage } = useContext(
    SnakBarMessageContext
  );

  const returnButtons = () => (
    <>
      {!edit && (
        <div className={classNames(editContainerClassname)}>
          <button
            onClick={() => {
              setEdit(true);
            }}
            className="hover:bg-[#0B4547] hover:text-white border-[#0B4547] mb-[30px] border-[2px] text-[#0B4547] px-[18px] py-[9px]  transition-all  cursor-pointer text-base font-medium text-center tracking-[-0.02em] rounded-[62px] flex items-center"
          >
            <span className="mr-[14px]">Edit</span>
            <EditIcon />
          </button>
        </div>
      )}
      {edit && (
        <div
          className={classNames(
            "mb-[30px] flex items-center gap-[30px]",
            editContainerClassname
          )}
        >
          <a
            onClick={() => {
              setEdit(false);
            }}
            className="text-[#161616] hover:underline-offset-[8px] transition-all underline underline-offset-[10px] cursor-pointer text-base font-medium text-center tracking-[-0.02em]"
          >
            Cancel
          </a>

          <RoundedButton
            onClick={() => {
              if (!onSave) {
                localStorage.setItem("MagicPlan", JSON.stringify(data));
                setOpenSnakBar(true);
                setSnakBarMessage({
                  severity: "success",
                  msg: "Updated Successfully",
                });
                setEdit(false);
              } else {
                onSave();
              }
            }}
            text={saveText}
          />
        </div>
      )}
    </>
  );

  return (
    <div className={classNames(containerClassname)}>
      {buttonPlacement === "top" && returnButtons()}
      <img
        src="/assets/message-question.svg"
        alt="question"
        className={classNames(questionImgClassname)}
      />
      <h2 className="text-[#0B4547] text-[20px] lg:text-[24px] font-bold tracking-[-0.02em] mb-[5px]">
        Disclaimer
      </h2>
      <p className="text-base text-[#161616] font-normal mb-[24px]">
        Making changes to values here might effect your plan. Be sure to check under "My Plan" to make any needed adjustments!
      </p>
      {buttonPlacement === "buttom" && returnButtons()}
    </div>
  );
};

export default DisClaimer;
