import { FormControlLabel, Radio, Slider } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "../../Button";
import { styled } from '@mui/material';

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Question2Validiation } from "../../../common/utils";
import { HeaderFooterContext, StepsContext, LessonStepContext, UserContext } from "../../../Context/Provider";


const SquareHandle = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 16,
  height: 16,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 4,
}));


const Taxes = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);

  
 const getData = (data: any) => {
    formAction({ data });
    triggerStep(true)
  };

  const { authUser, setAuthUser } = useContext(UserContext);
  const [percentage, setPercentage] = useState(19);
  const [taxes, setTaxes] = useState(authUser?.yearly_income*percentage/100);
  const [income, setIncome] = useState(0);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPercentage(newValue as number);
    setIncome(authUser?.yearly_income);
    setTaxes((newValue as number) * income/100);     
  };

  return (
    
    <div>
        <div className="mb-8">
        What percentage do you pay in taxes?
        </div>
        <div className="ml-6 mr-6">
        <Slider
        valueLabelDisplay="on"
        aria-label="custom thumb label"
        value={percentage}
        onChange={handleChange}
        aria-labelledby="income-slider"        
        min={0}
        max={37}
        valueLabelFormat={percentage+ "%"}
      />
</div>
    <div className="max-[1000px]:text-sm text-[0.9rem] leading-[1.5]">
        Annual Taxes: ${taxes.toLocaleString()}
      </div>
      
      <Button
        removeBottomSpace={true}
        onClick={() => getData({percentage})}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default Taxes;
