import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button } from "../Button";

const schema = yup
  .object({
    social_security_estimate: yup.number().typeError("Estimate is required"),
  })
  .required();

const FourteenthPiece = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const [defaultValues, setDefaultValues] = useState<object>({
    social_security_estimate: data?.social_security_estimate || "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { onChange, onBlur, name, ref } = register("socialSecurity");

  const formData = (data: any) => {
    formAction({ data });
  };

  return (
    <div className="pt-5">
      <input
        className="mb-3 bg-transparent w-full placeholder-white border-2 
        text-white tracking-[0.02em] border-white rounded-[12px] 
        h-[56px] px-[11px] py-[22px] outline-none text-[16px] font-normal font-satoshi"
        placeholder="Social Security Amount Per Month"
        type="number"
        step="1"
        {...register("social_security_estimate")}
        required
      />
      <span className="">
        {errors.social_security_estimate && (
          <p className="text-green-600 text-[13px]">
            {errors.social_security_estimate?.message?.toString()}
          </p>
        )}
      </span>
      <Button left label="NEXT" onClick={handleSubmit(formData)} />
    </div>
  );
};

export default FourteenthPiece;
