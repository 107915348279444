import { Alert, Modal as MuiModal, Snackbar } from "@mui/material";
import right_corner_arrow from "../assets/right_corner_arrow.png";

export const Modal = ({
  open,
  onClose,
  onButtonClick,
  buttonLabel = "Show me how",
  description,
  heading
}: {
  open: boolean;
  onClose: any;
  onButtonClick: Function;
  buttonLabel?: string;
  description?: string;
  heading?: string;
}) => {
  return (
    <MuiModal
      className="flex justify-center"
      open={open}
      onClose={onClose}
      role="dialog"
      aria-modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="flex-col content-center items-center absolute top-10 max-w-2xl rounded-[25px] bg-white px-7 py-12 font-satoshi">
        <h3
          className="text-center text-4xl text-[#0B4547] font-semibold"
          id="modal-title"
        >
          {heading || "Congratulations!"}
        </h3>
        <p className="text-base text-center my-[25px]" id="modal-description">
          {description ||
            `Now that you have saved your plan, find out how to implement it, save on taxes, and invest your savings!`}
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => {
              onButtonClick();
            }}
            className="flex items-center py-2 border-[#0B4547] border-b-2 text-base text-[#161616]"
          >
            {buttonLabel}
            <img src={right_corner_arrow} className="h-4 ml-3" alt="arrow" />
          </button>
        </div>
      </div>
    </MuiModal>
  );
};
