import React, { useState } from "react";
import { Button } from "../Button";
import google from "../../assets/google.svg";
import facebook from "../../assets/facebook.svg";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserContext } from '../../Context/Provider';
import { useContext } from "react";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const FifthPiece = ({
  next, signupGoogle, signupFB, hostUIsignUp }: { next: Function, signupGoogle: Function, signupFB: Function, hostUIsignUp: Function }) => {
  const { authUser, setAuthUser } = useContext(UserContext);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const handleModalOpen = () => setSignUpModalOpen(true);
  const handleModalClose = () => setSignUpModalOpen(false);
  const [defaultValues, setDefaultValues] = useState<any>({
    userName: "",
    email: "",
    password: "",
  });
  // const [signedUp, setSignedUp] = useState(true);
  // const formData = (data: any) => {
  //   signup({ data });
  //   handleModalClose();
  // };

  const render = () => {
    if (authUser) {
      return (
         <Button
          onClick={() => {
            next()
          }}
          label="NEXT"
          left={true}
        />
      )
    } else {
      return (
        <>
          <Button
            onClick={() => hostUIsignUp()}
            label="CREATE AN ACCOUNT"
            left={false}
            fullWidth={true}
          />
          <div className="mb-[30px] mt-[5px] w-full">
            <button
              onClick={() => {
                signupGoogle();
              }}
              className={`bg-[#0B4547] w-full flex items-center justify-center min-h-[56px] tracking-[0.02em]  uppercase py-3  px-6 border-2 border-white rounded-[12px] px-2 text-base max-[1000px]:text-sm font-normal font-satoshi text-white text-center`}
            >
              <img src={google} className="mr-[20px]" />
              <span>Sign up with google</span>
            </button>
          </div>
          <button
            onClick={() => {
              signupFB()
            }}
            className={`bg-[#0B4547]  w-full flex items-center justify-center tracking-[0.02em] min-h-[56px] uppercase py-3  px-6 border-2 border-white rounded-[12px] px-2 text-base max-[1000px]:text-sm font-normal font-satoshi text-white text-center`}
          >
            <img src={facebook} className="mr-[20px]" />
            <span>Sign up with facebook</span>
          </button>
        </>
      )

    }
  }

    return (
      <div className="lg:px-[25px]">
        {render()}
      </div>
    );
  };

  export default FifthPiece