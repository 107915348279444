import axios from 'axios';

export const getToken = () => {
  let data = localStorage.getItem('niaTokens') && JSON.parse(localStorage.getItem('niaTokens') || '{}');
  return data?.idToken;
}

const baseURL = process.env.REACT_APP_API_BASE_URL;

// export const apiClient = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL,
//   headers: {
//     Authorization: `Bearer ${getToken()}`
//   }
// });
//let baseURL="http://localhost:4000/"


export function getAPIData(obj: any) {
  return axios.get(baseURL+obj.url, {
    params: {
      ...obj.data,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

export function postAPIData(obj: any) {
  return axios.post(baseURL+obj.url,
    {
      ...obj.data,
    },
    { headers: { Authorization: `Bearer ${getToken()}` } },
  );
}

export function putAPIData(obj: any) {
  return axios.put(baseURL+obj.url, {
    ...obj.data
  }
  );
}

export function deleteAPIData(obj: any) {
  return axios.delete(baseURL+obj.url);
}

