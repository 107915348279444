import { useCallback, useContext, useEffect, useState } from "react";
import { getAPIData, postAPIData } from "../../utils/apiClient"
import RoundedButton from "../RoundedButton";
import { returnConfirmPlanValue } from "../lessonContent/investPlanForms/ConfirmPlanPiece";
import {returnRiskProfileDescription} from "../lessonContent/investPlanForms/ConfirmPlanPiece"
import {
  OpenSnakBarContext,
  SnakBarMessageContext,
  UserContext,
} from "../../Context/Provider";
import { useNavigate } from "react-router-dom";

const investorRiskType = [
  "a very aggressive",
  "an aggressive",
  "a moderate",
  "a conservative",
  "a very conservative",
]

const investorPortfolioDescription = [
  "Stocks: 80%, Bonds: 15%, Cash: 5%",
  "Stocks: 70%, Bonds: 20%, Cash: 10%",
  "Stocks: 60%, Bonds: 30%, Cash: 15%",
  "Stocks: 40%, Bonds: 40%, Cash: 20%",
  "Stocks: 30%, Bonds: 50%, Cash: 20%"
]


const Report = () => {
  const { setOpenSnakBar } = useContext(OpenSnakBarContext);
  const { authUser, setAuthUser } = useContext(UserContext);
  const { setSnakBarMessage } = useContext(
    SnakBarMessageContext
  );



  const navigate = useNavigate();

  function goHome() {
    navigate("/dashboard")
  }

  useEffect(() => {
  // Refresh authuser when we get here
    updateUser()
  },[])

  async function updateUser() {
    const user = await getAPIData({ url: "v1/users" });
    
    if (user?.data?.success) {
      setAuthUser(user.data.user);
    }
    // if (process.env.REACT_APP_AWS_BRANCH === "main") {
    //   if (!user.data.user.stripePaymentIntentId) goHome()
    // }
  }

  const accts = authUser?.snapshot?.plans

 
  let showTaxAccounts = "";
  

  if (accts?.ira_suggestion && accts?.advanced_suggestion) {
     showTaxAccounts = accts.ira_suggestion + " and a " + accts.advanced_suggestion
  } else if (accts?.ira_suggestion) {
    showTaxAccounts = accts.ira_suggestion
  } else if  (accts?.advanced_suggestion) {
    showTaxAccounts = accts.advanced_suggestion
  }


  const saveAmount = authUser?.snapshot?.plans?.pct_saved



  const investPlanText = investorRiskType[authUser?.snapshot?.plans?.risk_profile-1]


  return (
    <div className="max-w-[1350px] px-[15px] w-full ml-auto mr-auto font-satoshi">
      <div className="shadow-card rounded-[25px] mt-[30px] lg:mt-[120px] mb-[30px] lg:mb-[78px] lg:px-[70px] p-[30px] lg:py-[53px] bg-white overflow-hidden ">
      <div className="text-[#0B4547] text-[36px] lg:text-[62px] font-bold tracking-[-0.02em] mb-[30px] lg:mb-[50px] text-center">
        Report
        </div>

        <div className="text-[#0B4547] text-[24px] lg:text-[30px] font-bold tracking-[-0.02em]">
        Savings Rate:
        </div>

        <p className="text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-[17px]">You plan on saving {saveAmount}% of your income. We recommend setting up an automatic transfer.</p>

        {showTaxAccounts &&
        <div>
        <div className="text-[#0B4547] text-[24px] lg:text-[30px] font-bold tracking-[-0.02em]">
        What Retirement accounts am I eligible for?
        </div>
        <h4 className="text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-[17px]">Your answers indicate that in order to optimize your tax savings, you should consider opening a <span className="font-bold">{showTaxAccounts}.</span>Refer to the <span className="font-bold">Retirement Accounts</span> section at the bottom of this document.</h4>

        <div className="text-[#0B4547] text-[24px] lg:text-[30px] font-bold tracking-[-0.02em] mb-[17px]">

        Investment Strategy:
        </div>
        <p className="text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-[17px]">You indicated you are <span className="font-bold">{investPlanText}</span> investor. Based on your risk profile, we recommend the following breakdown:</p>
        <p className="text-[17px] font-bold font-satoshi tracking-[-0.02em] text-[#161616] mb-[17px]">{investorPortfolioDescription[authUser?.snapshot?.plans?.risk_profile-1]}</p>
       
        <p className="text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-[17px]"><span className="font-bold">Stocks:</span> When you buy a stock, you own a piece of that company called a “share”, and become a partial owner, or “shareholder”, of that company. As a shareholder, you can benefit from the company's growth and profitability. Stocks can be more volatile but also have the potential for higher returns.</p>
        <p className="text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] ml-[34px] mb-[17px]"><span className="font-bold">ETFs:</span> An exchange-traded fund (ETF) is a basket of securities that trades on an exchange just like a stock does. ETFs can track Indexes (like the S&P 500). ETFs offer low expense ratios and fewer broker commissions than buying the stocks individually.</p>
        <p className="text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-[17px]"><span className="font-bold">Bonds:</span> When you buy a bond, you are lending money to an entity (corporation, government, local governments, etc.) and in return you receive a fixed income. Bonds generally offer regular interest payments to you and return the principal amount when the bond matures (a pre-set date). Bonds are considered less risky than stocks but typically offer lower returns.</p>
        <p className="text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-[17px]"><span className="font-bold">Cash:</span> Cash holds a low level of risk, and can be put in secure investment vehicles such as cash investments. Returns are interest payments through Money Market accounts or certificates of deposit (CDs).</p>


        {((accts.ira_suggestion==="Traditional IRA") || (accts.ira_suggestion==="Both"))
        &&
        <div>
          <h4 className="text-[24px] font-medium font-satoshi tracking-[-0.02em] text-[#161616]">Tradiational IRA</h4>
          <p>Traditional individual retirement accounts (IRAs) can be especially advantageous if you expect to be in a lower tax bracket during retirement.</p>
          <ul className="list-disc text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-6">
          <li className="ml-10">A Traditional IRA offers <span className="font-bold">pre-tax contributions</span>. This means that you can lower your taxable income by the amount you contribute into your traditional IRA.</li>
          <li className="ml-10">The funds within a Traditional IRA grow <span className="font-bold">tax-deferred</span>, meaning you won't owe taxes on your contributions or investment gains until you make withdrawals in retirement. At that time, the withdrawals are taxed as ordinary income based on your tax bracket during retirement (after age 59.5).</li>
          <li className="ml-10">However, it's important to note that Traditional IRAs <span className="font-bold">have required minimum distributions (RMDs) </span>starting at age 73. This means you must begin taking withdrawals and paying taxes on them at age 73, regardless of whether you need the funds or not.</li>
          <li className="ml-10">Summary:</li>
          <ol className="pl-5 mt-2 space-y-1 list-disc list-inside">
            <li className="ml-10">Pre-tax contributions (contributions lower your taxable income for that year)</li>
            <li className="ml-10">Funds grow tax deferred (pay taxes at time of withdrawal)</li>
            <li className="ml-10">RMD (required to withdraw starting at 73 whether you need it or not)</li>
          </ol>
          </ul>
        </div>
        }
        {((accts.ira_suggestion==="Roth IRA") || (accts.ira_suggestion==="Both"))
        && 
          <div>          
            
          <h4 className="text-[24px] font-medium font-satoshi tracking-[-0.02em] text-[#161616]">Roth IRA</h4>
          <p>Roth individual retirement accounts (IRAs) can be especially advantageous if you expect to be in a higher tax bracket during retirement or if you are seeking tax diversification in your retirement savings. Note that if you make over a certain income, you can’t contribute to a Roth IRA.</p>
          <ul className="list-disc text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-6">
          <li className="ml-10">A Roth IRA offers <span className="font-bold">post-tax contributions</span>. This means that you contribute income that has already been taxed. </li>
          <li className="ml-10">The key benefit to Roth IRAs is <span className="font-bold">tax-free growth.</span> This means that withdrawals in retirement, of your contributions and investment gains, are tax-free.</li>
          <li className="ml-10">Another advantage of Roth IRAs is that they <span className="font-bold">do not require minimum distributions (RMDs)</span> during the account owner's lifetime.</li>
          <li className="ml-10">Summary:</li>
          <ol className="pl-5 mt-2 space-y-1 list-disc list-inside">
            <li className="ml-10">Post-tax contribution (contribute money that has already been taxed)</li>
            <li className="ml-10">Tax free growth (do not pay taxes at time of withdrawal)</li>
            <li className="ml-10">No RMDs (No age at which you are required to start withdrawing)</li>
          </ol>
          </ul>

          </div>
        }

        {(accts.advanced_suggestion === "Sep IRA") &&
          <div>
            <ul className="list-disc text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-6">

          <h4 className="text-[24px] font-medium font-satoshi tracking-[-0.02em] text-[#161616]">SEP IRA - Simplified Employee Pension Individual Retirement Account </h4>
          <p>One of the key benefits of a SEP IRA is the higher contribution limit compared to Traditional and Roth IRAs.</p>

          <li className="ml-10">Designed for <span className="font-bold">self-employed individuals and small business owners; can have employees</span></li>
          <ol className="pl-5 mt-2 space-y-1 list-disc list-inside">
            <li className="ml-10">Note that SEP IRAs have rules regarding contributions for eligible employees if you have employees</li>
          </ol>
          <li className="ml-10">As of 2023, you can contribute <span className="font-bold"> up to $61,000 or 25% of your eligible compensation, whichever is lower</span></li>

          <li className="ml-10"><span className="font-bold">Tax-deductible contributions,</span> meaning they can reduce your taxable income for the year of contribution; Funds within the account <span className="font-bold"> grow tax-deferred,</span> and withdrawals in retirement are subject to ordinary income tax</li>
          <li className="ml-10"><span className="font-bold">Straightforward set up</span> and maintenance</li>          
          </ul>
        </div>
        }
        {(accts.advanced_suggestion === "Solo 401k") &&
          <div>
        <h4 className="text-[24px] font-medium font-satoshi tracking-[-0.02em] text-[#161616] inline">Solo 401K - also known as an Individual 401(k) or Self-Employed 401(k)</h4>
        <p>One of the significant advantages of a Solo 401(k) is the potential for higher contribution limits compared to other retirement plans that allow self-employed individuals to build a robust retirement nest egg. </p>
        <ul className="list-disc text-[17px] font-normal font-satoshi tracking-[-0.02em] text-[#161616] mb-6">

        <li className="ml-10">Designed for <span className="font-bold">self-employed or small business owners with no full-time employees other than their spouse</span></li>
        <li className="ml-10">The Solo 401(k) allows you to <span className="font-bold">make contributions both as the employer and as the employee</span>, providing an opportunity to save more for retirement.</li>
        <li className="ml-10">As of 2023, you can contribute <span className="font-bold">up to $61,000 or 100% of your eligible compensation, whichever is lower</span></li>
        <ol className="pl-5 mt-2 space-y-1 list-disc list-inside">
            <li className="ml-10">Can contribute as employer and employee, an opportunity to save more</li>
            <li className="ml-10">Allows catch-up contributions</li>
          </ol>
        
        <li className="ml-10"><span className="font-bold">Tax-deductible contributions,</span> meaning they can reduce your taxable income for the year of contribution; Funds within the account <span className="font-bold">grow tax-deferred,</span> and withdrawals in retirement are subject to ordinary income tax (unless you open a Roth Solo 401(k) account) </li>
        <li className="ml-10"><span className="font-bold">Straightforward set up</span> and maintenance; <span className="font-bold">Flexibility</span> in investment choices, allowing you to diversify your retirement savings across various asset</li>          
        </ul>
        </div>
        }

        </div>
        }
        </div>
        <div className="mt-5 mb-10">
        <RoundedButton
          text={"Return to Dashboard"} onClick={goHome}
        />
        </div>
    </div>

  );
};



export default Report;
