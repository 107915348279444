import { FormControlLabel, Radio, Slider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { HeaderFooterContext, StepsContext, LessonStepContext, UserContext } from "../../../Context/Provider";
import exp from "constants";



const Results = ({
  data,
  formAction,
}: {
  data?: any;
  formAction: Function;
}) => {
  const { stepFlag, triggerStep } = useContext(LessonStepContext);
  const { authUser, setAuthUser } = useContext(UserContext);
  const [maxPct, setMax] = useState(0)
  const [percentage, setPercentage] = useState(20);
  const [income, setIncome] = useState(authUser?.yearly_income);
  const [expenses,setExpenses] = useState(0)
  const [housing,setHousing] = useState(0)
  const [taxes,setTaxes] = useState(0)



  const [selectedValue, setSelectedValue] = useState<any>(
    data?.question2Answer || ""
  );
  const [defaultValues, setDefaultValues] = useState<any>({
    question2Answer: data?.question2Answer || "",
  });

  const getData = (data: any) => {
    formAction({ data });
    triggerStep(true)
  };

  useEffect(() => {
    let taxes = JSON.parse(localStorage.getItem("taxes") || "19")
    setTaxes(taxes)
    let housing = JSON.parse(localStorage.getItem("housing") || "30")
    setHousing(housing)
    let expenses = JSON.parse(localStorage.getItem("expenses") || "20")
    setExpenses(expenses)
    let maxValue = (100 - Number(taxes) -  Number(expenses)- Number(housing))
    maxValue = maxValue < 0 ? 0 : maxValue
    setMax(maxValue)

  },[])



  const handleChange = (event: Event, newValue: number | number[]) => {
    setPercentage(newValue as number);
    
  };

  return (
    
    <div>

        <div className="mb-8">
        You told us your income is broken down like so:
        </div>
        <div className={`font-satoshi mb-1`}>Income: ${income.toLocaleString()} per year or ${Math.round(income/12).toLocaleString()} per month</div>
        <div className={`font-satoshi mb-1`}>Taxes: {taxes.toLocaleString()}% or ${Math.round(taxes*income/100/12).toLocaleString()} per month</div>
        <div className={`font-satoshi mb-1`}>Housing: {housing.toLocaleString()}% or ${Math.round(housing*income/100/12).toLocaleString()} per month</div>
        <div className={`font-satoshi mb-1`}>Expenses: {expenses.toLocaleString()}% or ${Math.round(expenses*income/100/12).toLocaleString()} per month</div>
        <div className={`font-satoshi font-bold mb-1`}>Remaining: {maxPct}% or ${Math.round(maxPct*income/100/12).toLocaleString()} per month</div>        

    <div className={`font-satoshi my-5`}>Use these numbers to guide how much you can realistically save for retirement.</div>
<Button
        removeBottomSpace={true}
        onClick={() => getData({percentage})}
        label="NEXT"
        left={true}
      />
    </div>
  );
};

export default Results;
