import React from "react";
import DisClaimer from "./DashBoard/DisClaimer";
import RangeSlider from "./RangeSlider";
import {calculateTaxSavings,figureHave,figureWant, returnRangeText} from "../magicMath";
import Libra from "./Libra";
import { getPlanData } from "./DashBoard/DashBoard";
import RoundedButton from "./RoundedButton";
import { Tooltip, useMediaQuery } from "@mui/material";
import { Boundaries } from "../common/settings";
import ReactGA from 'react-ga4'


const MagicWork = ({
  data,
  touched,
  setTouched = () => {},
  setData = () => {},
  disabled,
  appearance,
  onSave,
  onReset
}: {
  data?: any;
  touched?: any;
  setTouched?: Function;
  setData?: Function;
  disabled?: boolean;
  appearance?: string;
  onSave?: Function;
  onReset?: Function;
}) => {
  const isLarge = useMediaQuery("(min-width: 1024px)");
  const {
    ssAdjustmentText,
    ssSideJobAdjustmentText,
    totalHavePerYear,
    totalWant,
  } = returnRangeText(data);


  const maxSavingsRange = (data.Income * .33) > Boundaries.SOLO_LIMIT ? Math.round(data.Income * .33) : Boundaries.SOLO_LIMIT

  
  return (
    <div id="magicwork">
      <h2 className="text-[#0B4547] text-[25px] lg:text-[28px] font-bold text-center tracking-[-0.02em] mb-[10px] flex justify-center items-center mt-2 lg:mt-0">
        Nia Planning
        <span>
          {/* <Tooltip title="So, we’ve been working on gathering your puzzle pieces, now let’s see how they’ll fit together! Your current plan is to save $X,XXX a year, retire at X age, live X lifestyle, and (selected retirement job status). That sounds totally doable to us?">
            <img
              src="/assets/message-question.svg"
              alt="question"
              className="ml-[10px] cursor-pointer"
            ></img>
          </Tooltip> */}
        </span>
      </h2>
      {!isLarge && (
            <div className="w-full lg:w-1/2 flex justify-center lg:pl-10 flex-col items-center mb-4">
              <Libra
                havePay={Math.round(totalHavePerYear) || 0}
                wantPay={totalWant}
              />
              <div className="flex items-center justify-center gap-[30px] -mt-10 z-10">
                <a
                  onClick={() => {
                    if (!touched) return;
                    onReset?.();
                    setTouched(false);
                  }}
                  className="text-[#161616] hover:underline-offset-[8px] transition-all underline underline-offset-[10px] cursor-pointer font-medium text-center tracking-[-0.02em]"
                >
                  Reset
                </a>
                <RoundedButton
                  text={"Save"}
                  onClick={() => {                    
                    onSave?.();
                  }}
                  buttonClasses="bg-[#EA743C]"
                />
              </div>
            </div>
          )}
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/2 px-5 lg:pr-10">
          <div className="mb-[20px]">
            <RangeSlider
              title="Save Now"
              subtitle={`$${data?.futureSaving.toLocaleString()}/year`}
              name="futureSaving"
              min={0}
              max={maxSavingsRange}
              step={100}
              unit={"$"}
              data={data}
              setData={setData}
              defaultValue={1000}
              touched={touched}
              disabled={disabled}
              setTouched={setTouched}
              bottomLeftText="Save Less"
              bottomRightText="Save More"
              tooltip="How much will you save and invest per year until you retire?"
              lastLeftText={
                "Possible tax savings $" +
                calculateTaxSavings(data?.futureSaving).toLocaleString() +
                " /year"
              }
              lastRightText={
                data?.savingsbreakdownfutureSavingsTotal &&
                "Value at retirement $" +
                  Math.round(data.savingsBreakdown.futureSavingsTotal)
              }
            />
          </div>
          <div className="mb-[20px]">
            <RangeSlider
              title="Retirement Age"
              name="retirementAge"
              subtitle={`${data?.retirementAge} ${
                data?.retirementAge === 1 ? "Year" : "Years"
              } Old`}
              bottomLeftText="Younger"
              min={data?.ageBounds?.Age ? data.ageBounds.Age : 55}
              max={
                data?.ageBounds?.LifeExpectancy
                  ? data?.ageBounds?.LifeExpectancy
                  : 99
              }
              step={1}
              data={data}
              setData={setData}
              defaultValue={67}
              bottomRightText="Older"
              tooltip="When will you retire?"
              touched={touched}
              disabled={disabled}
              setTouched={setTouched}
              lastLeftText={
                "Social Security $" +
                Math.round(data?.socialSecurityInfo?.totalSocialSecurity).toLocaleString() +
                " /year"
              }
              lastRightText={ssAdjustmentText}
            />
          </div>
          <RangeSlider
            title="Post Retirement Gig"
            name="postRetirementJob"
            subtitle={`$${data?.postRetirementJob.toLocaleString()}/year`}
            bottomLeftText="Less"
            bottomRightText="More"
            tooltip="Will you have a retirement gig doing what you love while earning a bit of extra cash?"
            unit={"$"}
            defaultValue={0}
            data={data}
            setData={setData}
            touched={touched}
            disabled={disabled}
            setTouched={setTouched}
            lastRightText={ssSideJobAdjustmentText}
            min={0}
            max={25000}
            step={1000}
          />
        </div>
        {isLarge && (
          <div className="w-full lg:w-1/2 flex justify-center lg:pl-10 flex-col items-center">
            <Libra
              havePay={Math.round(totalHavePerYear) || 0}
              wantPay={totalWant}
            />
            <div className="flex items-center justify-center gap-[30px] -mt-10 mb-32 z-10">
              <a
                onClick={() => {
                  if (!touched) return;
                  onReset?.();
                  setTouched(false);
                }}
                className="text-[#161616] hover:underline-offset-[8px] transition-all underline underline-offset-[10px] cursor-pointer font-medium text-center tracking-[-0.02em]"
              >
                Reset
              </a>
              <RoundedButton
                text={"Save"}
                onClick={() => {
                  if (process.env.REACT_APP_AWS_BRANCH === "main") {
                    ReactGA.send({ hitType: "pageview", page: "/magic-screen", title: "Save Magic Plan" });
                  }

                  onSave?.();
                }}
                buttonClasses="bg-[#EA743C]"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MagicWork;
