import { useState } from "react";
import { Title } from "../components/Title";
import { BodyText } from "../components/BodyText";
import { SubtitleText } from "../components/Subtitle";
import { LineIcon } from "../components/LineIcon";
import { BackButton } from "../components/BackButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";



interface ScreenProps {
  children?: JSX.Element;
  title: string;
  subtitle?:string;
  link?:string,
  linkText?:string,
  link2?:string,
  link2text?:string,
  body?: string;
  body2?: string;
  hideBack?: boolean;
  image: string;
  handleChart?:JSX.Element;
  currentStep: number;  
  changeStep(step: number): void;
  rewindStep(step: number): void;
  choice?(step: number): string;
  helpText?: string;
  helpTextBody?: string;
}

export const LessonScreen = ({
  children,
  title,
  subtitle,
  link,
  linkText,
  link2,
  link2text,
  body,
  body2,
  hideBack = true,
  image,
  handleChart,
  currentStep,
  changeStep,
  rewindStep,
  choice,
  helpText,
  helpTextBody,  
}: ScreenProps) => {
  return (
    <div className="flex grow overflow-auto  justify-center items-center px-5 py-10">
      <div className="flex max-w-[1320px] min-h-[32rem] md:h-[40rem] lg:h-[40rem] justify-center items-center max-md:flex-col md:rounded-xl md:shadow-lg ">
        <div className="flex h-full max-w-[600px]  min-w-[335px]  sm:w-full md:max-w-[28rem] p-8 max-[767px]:rounded-xl md:rounded-l-xl bg-[#0B4547]">
          <div className="flex flex-col overflow-y-auto overflow-x-hidden">
            {hideBack && (
              <BackButton onClick={() => rewindStep(currentStep)} />
            )}
            <div className={`h-full flex flex-col justify-center text-white`}>
              {title && (
                <Title
                  helpIcon={helpText ? true : false}
                  title={title}
                  helpText={helpText}
                />
              )}
              {subtitle && <SubtitleText text={subtitle} link={link} linkText={linkText}/>}

              {body && 
              <BodyText link2="" link2text=""
                helpIcon={helpTextBody ? true : false}
                text={body}                
                helpText={helpTextBody}
              />}
              
              {body2 && <BodyText link2={link2} link2text={link2text} text={body2} />}
              {children}
            </div>
          </div>
        </div>
        <div className="flex items-center h-full w-full min-[890px]:min-w-[30rem] sm:pl-6 sm:pr-3 pt-12 md:py-10 lg:pl-16 lg:pr-16 rounded-r-xl bg-[#FFFFFF] items-center justify-center">
         {handleChart ? handleChart : (
                  <div className="max-w-[40rem] w-full xl:min-w-[640px] flex items-center justify-center">                       
                  <img className="md:object-cover " src={image} alt="welcome" />
                  </div>
            )
         }            
        </div>
      </div>
    </div>
  );
};
