import React, { useContext, useEffect } from "react";
import DashBoard from "../../components/DashBoard/DashBoard";
import Report from "../../components/Report/Report"
import { HeaderFooterContext } from "../../Context/Provider";
import { Layout } from "../../common/Layout";

const ReportScreen = () => {
  const { show, setShow } = useContext(HeaderFooterContext);
  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <Layout>
      <Report />
    </Layout>
  );
};

export default ReportScreen;
