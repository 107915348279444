import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

export const Title = ({
  title,
  helpIcon,
  helpText,
}: {
  title: string;
  helpIcon?: boolean;
  helpText?: string;
}) => {
  return (
    <div className="text-white leading-[1.2] text-[1.6rem] text-4xl max-[1000px]:text-2xl font-bold font-satoshi mb-1">
      {title}{" "}
      {helpIcon && (
        <Tooltip title={helpText}>
          <HelpOutlineIcon
            className="helpIcon !w-[15px] align-top"
            fontSize="small"
          />
        </Tooltip>
      )}
    </div>
  );
};
