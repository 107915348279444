import React from 'react'
import { Button } from '../Button';

const SeventhPiece = ({ formAction }: { formAction:Function }) => {
  return (
    <div>
      {" "}
      <Button
        // onClick={() => {
        //   console.log("clicked");
        // }}
        onClick={() => {
            formAction();
          }}
        label="MY RETIREMENT"
        left={true}
        removeBottomSpace={true}
      />
      {/* <Button
        onClick={() => {
          formAction();
        }}
        label="NEXT"
        left={true}
      /> */}
    </div>
  );
};

export default SeventhPiece