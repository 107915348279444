import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ThirdPieceValidation } from "../../common/utils";
import { Button } from "../Button";
import Inputs from "../../common/Inputs";
const ThirdPiece = ({
  data,
  formAction,
  helpText,
}: {
  data?: any;
  formAction: Function;
  helpText?: string;
}) => {
  const [defaultValues, setDefaultValues] = useState<any>({
    range: data?.range || "",
  });
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(ThirdPieceValidation),
  });
  const formData = (data: any) => {
    formAction({ data });
  };
  return (
    <div>
      <div className="w-full my-[15px]">
        <Inputs
          label=""
          register={register}
          name="range"
          inputType="number"
          inputText="Retirement Savings"
          required={true}
          error={errors}
          helpText={helpText}
        />
      </div>
      <div className="w-full my-[15px]">
        <Inputs
          label=""
          register={register}
          name="other"
          inputType="number"
          inputText="Other Savings"
          required={true}
          error={errors}
          helpText={"This is anything you have saved that isn't for explicitly for retirement."}
        />
      </div>
      <Button onClick={handleSubmit(formData)} label="NEXT" left={true} />
    </div>
  );
};

export default ThirdPiece;
