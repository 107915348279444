import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { HeaderFooterContextProvider, LessonStepProvider, OpenSnakBarContextProvider, SnakBarMessageContextProvider, StepsContextProvider, UserContextProvider } from './Context/Provider';
import { Amplify, Auth } from 'aws-amplify';
import ReactGA from 'react-ga4'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
let signinRedirect = process.env.REACT_APP_COGNITO_REDIRECT_URL
let signoutRedirect = process.env.REACT_APP_COGNITO_REDIRECT_URL

// if (process.env.REACT_APP_AWS_BRANCH != "master" && process.env.REACT_APP_COGNITO_REDIRECT_URL != "http://localhost:3000/") {  
//   signinRedirect = "https://hot-fixes.dynhixh0r28n9.amplifyapp.com/"
//   signoutRedirect = "https://hot-fixes.dynhixh0r28n9.amplifyapp.com/"
// }

Amplify.configure({
  Auth: {
    region: "us-west-2",
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],      
      redirectSignIn: signinRedirect,
      redirectSignOut: signoutRedirect,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is "code"
    },
    hideSocial: true
  },
});

ReactGA.initialize("G-ZSM4KT6RN6")

if (process.env.REACT_APP_AWS_BRANCH === "main") {
  ReactGA.initialize("G-ZSM4KT6RN6")
}


root.render(
  <React.StrictMode>
    <UserContextProvider>
      <SnakBarMessageContextProvider>
        <OpenSnakBarContextProvider>
          <HeaderFooterContextProvider>
            <StepsContextProvider>
              <LessonStepProvider>
                <RouterProvider router={router} />
              </LessonStepProvider>
            </StepsContextProvider>
          </HeaderFooterContextProvider>
        </OpenSnakBarContextProvider>
      </SnakBarMessageContextProvider>
    </UserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
