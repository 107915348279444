import React from 'react'


const MagicContent = () => {
  return (
    <div className="flex justify-center items-center max-w-[1270px] px-[15px] w-full ml-auto mr-auto my-[13px]">
    {/* <div className='md:block hidden h-[200px]'>
        <img src="assets/magic-1.svg" className='max-h-full'/>
    </div> */}
    {/* <div className='md:max-w-[595px] text-center'>
        <h1 className="text-[#0B4547] text-[35px] font-bold">
        NIA Magic!
        </h1>
        <p className='text-base text-[#161616] tracking-[-0.02em]'>So, we’ve been working on gathering your puzzle pieces, now let’s see how they’ll fit together! Your current plan is to save $X,XXX a year, retire at X age, live X lifestyle, and (selected retirement job status). That sounds totally doable to us?</p>
    </div> */}
    {/* <div className='md:block hidden h-[200px]'> <img src="assets/magic-2.svg" className='max-h-full'/></div> */}
    </div>
  );
}

export default MagicContent