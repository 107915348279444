import React from "react";
import MagicOptionsDataHeader from "./MagicOptionsDataHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const MagicAssumptions = ({ options }: { options?: any }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className="bg-[#0B4547] rounded-[20px] md:w-[calc(50%-24px)] lg:w-[calc(33.3%-24px)] assumptions m-4">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <MagicOptionsDataHeader text={"Our Assumptions"} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-wrap gap-[15px]  py-[22px] px-[20px] border-t border-t-white">
            {options?.map((e: any, i: any) => {
              return (
                <React.Fragment key={i}>
                  <div className="flex items-center justify-between w-full">
                    <div className="tracking-[-0.02em] text-[17px] font-normal font-satoshi">
                      {e?.text}
                    </div>
                    <div className="tracking-[-0.02em] text-[17px] font-medium font-satoshi">
                      {e?.rate}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <p className="mr-5 ml-5 tracking-[-0.02em] border-t border-t-white">
            Our return estimate is a conservative estimate of potential investment growth based on the S&P 500 historic returns. Our inflation estimate is based of Federal Reserve's long term target.
            </p>
            <p className="mr-5 ml-5 tracking-[-0.02em] text-[10px]">
            https://www.investopedia.com/ask/answers/042415/what-average-annual-return-sp-500.asp
            </p>
            <p className="mr-5 ml-5 tracking-[-0.02em] text-[10px]">
            https://www.federalreserve.gov/faqs/economy_14400.htm
            </p>

        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MagicAssumptions;
