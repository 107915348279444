import React from 'react'
import { Button } from '../Button';

const FourthPiece = ({ formAction }: { formAction:Function }) => {
  return (
    <div>
      <Button onClick={()=>{formAction();}} label="NEXT" left={true} />
    </div>
  );
};

export default FourthPiece