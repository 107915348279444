import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

const Inputs = ({
  label,
  control,
  name,
  register,
  inputType,
  required,
  error,
  inputText,
  helpText,
}: {
  label?: string;
  control?: any;
  register?: any;
  name?: any;
  required?: boolean;
  inputType?: string;
  error?: any;
  inputText?: string;
  helpText?: string;
}) => {
  return (
    <div className="flex w-full">
      <div className=" w-full">
        {label && (
          <label>
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </label>
        )}
        <input
          type={inputType}
          placeholder={inputText}
          {...control}
          {...register(name)}
          className="bg-transparent w-full placeholder-gray border-2 text-white tracking-[0.02em] border-white rounded-[12px] h-[56px] px-[11px] py-[22px] outline-none text-[16px] font-normal font-satoshi"
        />
        <span>
          {error?.[name ?? ""] && (
            <p className="text-emerald-600 text-[12px] mt-[5px]">
              {error?.[name ?? ""]?.message.toString()}
            </p>
          )}
        </span>
      </div>
      <Tooltip title={helpText}>
        <HelpOutlineIcon
          className="helpIcon ml-[10px] !w-[15px]"
          fontSize="small"
        />
      </Tooltip>
    </div>
  );
};

export default Inputs;
