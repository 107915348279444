import { Button } from "../Button";
import google from "../../assets/google.svg";
import facebook from "../../assets/facebook.svg";
import { useNavigate } from "react-router-dom";

const LoginForm = ({
  signInGoogle,
  signInFb,
  hostUIsignUp,
}: {
  signInGoogle: Function;
  signInFb: Function;
  hostUIsignUp: Function;
  signInMode?: boolean;
}) => {
  const navigate = useNavigate();

  const render = () => {
    return (
      <>
        <Button
          onClick={() => {
            hostUIsignUp();
          }}
          label="Sign In"
          left={false}
          fullWidth={true}
        />

        <div className="mb-[30px] mt-[5px] w-full">
          {/* <button
            onClick={() => {
              signInGoogle();
            }}
            className={`bg-[#0B4547] w-full flex items-center justify-center min-h-[56px] tracking-[0.02em]  uppercase py-3  px-6 border-2 border-white rounded-[12px] px-2 text-base max-[1000px]:text-sm font-normal font-satoshi text-white text-center`}
          >
            <img src={google} className="mr-[20px]" />
            <span>Sign in with google</span>
          </button> */}
        </div>
        {/* <button
          onClick={() => {
            signInFb();
          }}
          className={`bg-[#0B4547]  w-full flex items-center justify-center tracking-[0.02em] min-h-[56px] uppercase py-3  px-6 border-2 border-white rounded-[12px] px-2 text-base max-[1000px]:text-sm font-normal font-satoshi text-white text-center`}
        >
          <img src={facebook} className="mr-[20px]" />
          <span>Sign in with facebook</span>
        </button> */}

        <div className="mt-4 text-sm tracking-wide">
          New to NIA?{" "}
          <span
            onClick={() => {
              navigate("/auth/welcome");
            }}
            className="cursor-pointer font-bold"
          >
            Sign Up
          </span>
        </div>
      </>
    );
  };

  return <div className="lg:px-[25px]">{render()}</div>;
};

export default LoginForm;
