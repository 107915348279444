import React, { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import dropdown from "../assets/arrow-square-down.svg";
import InputLabel from "@mui/material/InputLabel";
import { useForm, Controller, Control } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tooltip } from "@mui/material";

const SelectDropdown = ({
  label,
  control,
  name,
  register,
  required,
  defaultValue,
  error,
  selectText,
  options,
  helpText,
}: {
  label?: string;
  control?: Control<any, any>;
  register?: any;
  name?: any;
  defaultValue?: any;
  required?: boolean;
  error?: any;
  selectText?: string;
  options?: any;
  helpText?: string;
}) => {
  const [value, setValue] = useState<any>(defaultValue || "");
  return (
    <div className="flex w-full">
      <div className="w-full">
        {label && (
          <label>
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </label>
        )}
        <div className="relative w-full">
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">{selectText}</InputLabel> */}
            <Controller
              name={name}
              control={control}
              render={({ field, ...props }) => (
                <Select
                  onChange={(e: any) => {
                    setValue(e?.target?.value);
                    field?.onChange(e?.target?.value);
                  }}
                  displayEmpty
                  value={value}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={selectText}
                  variant="outlined"
                >
                  {selectText && selectText!== defaultValue && (
                    <MenuItem value="">
                      <>{selectText}</>
                    </MenuItem>
                  )}
                  {options?.map((e: any, i: any) => {
                    return (
                      <MenuItem key={i} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </div>
        <span>
          {error?.[name ?? ""] && (
            <p className="text-green-600 text-[12px] mt-[5px]">
              {error?.[name ?? ""]?.message.toString()}
            </p>
          )}
        </span>
      </div>
      <Tooltip title={helpText}>
        <HelpOutlineIcon
          className="helpIcon ml-[10px] !w-[15px]"
          fontSize="small"
        />
      </Tooltip>
    </div>
  );
};

export default SelectDropdown;
