import { Slider } from "@mui/material";
import RoundedButton from "./RoundedButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "./Modal";
import { getAPIData } from "../utils/apiClient";




export const Progress = ({
  user,
  goalAmount,
  currentValue,
  unit
}: {
  user: any;
  goalAmount?: any;
  currentValue?: any;
  unit?:any
}) => {
  const d = new Date();
  let year = d.getFullYear();

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  function handleClose() {
    setOpen(false);
  }

  async function getReport() {

    navigate('/report')    
  
  }

    //disabling stripe for seminars
  //   if (process.env.REACT_APP_AWS_BRANCH === "main") {
    //     const { data } = await getAPIData({url: "v1/users/stripe/session"});
  //     window.location.replace(data.url);
  //   } else {

  //     navigate('/report')    }
  // }

  async function handleReport() {
    setOpen(true)
  }

  const numberData = (sliderData: any) => {
    sliderData=currentValue
    if (!unit) {
      return sliderData;
    }
    if (sliderData >= 1000) {
      sliderData = sliderData / 1000;
      if (sliderData >= 1000) {
        sliderData = sliderData / 1000 + "M";
      } else {
        sliderData = Number(sliderData.toFixed(1)) + "k";
      }
    }
    let x = `${unit}${sliderData}`;
    return x;
  };

    const [savings,setSavings] = useState(0)


  return (
<div>
    <Modal
    description="Get your personalized report!"
    heading="Custom Report"
    open={open}
    onClose={handleClose}
    buttonLabel={"Get My Report"}
    onButtonClick={async () => {
      handleClose()
      await getReport()
    }}/>

    <div className="prograssBar">
      <h2 className="xl:text-[30px] text-[22px] font-bold font-satoshi tracking-[-0.02em] text-[#0B4547] leading-[1]">
        {year} Progress
      </h2>
      <p className="xl:text-base text-[14px] font-medium text-right font-satoshi tracking-[-0.02em] text-[#161616] !leading-[1]">
        Goal ${goalAmount}/yr
      </p>
      <Slider
        valueLabelDisplay="on"
        min={0}
        max={goalAmount}
        aria-label="custom thumb label"
        value={currentValue}
        valueLabelFormat={numberData}
        disabled={true}
      />

      {/* <div className="flex py-8">
      <TextField
        placeholder="savings"
        type="number"
        variant="standard"
        className="w-[50%] bg-transparent"
        onChange={(e: any) =>
          setSavings((current: any) => ({
            ...current,
            savings: Number(e.target.value),
          }))
        }
        InputProps={{
          style: {
            backgroundColor: "white",
            // border: "2px solid border-b #0B4547",
            borderRadius: 10,
          },
        }}
    />
      <div className="pl-5">
      <RoundedButton
          text={"Log Savings"}
          onClick={() => {
            console.log("clicked");
          }}
        />
    </div> */}
    {/* </div> */}
    <div className="mt-5"></div>
      {user?.stripePaymentIntentId ? (
        <RoundedButton text={"See my Report"} onClick={() => navigate('/report')} />
      ) : (
        <RoundedButton text={"Get Report"} onClick={handleReport} />
      )}
    </div>
    </div>
  );
};
