import { createContext, useState } from "react";
interface ContextProviderProps {
  children: React.ReactNode;
}

export const UserContext = createContext<any>(null);
export const CognitoUserContext = createContext<any>(null);
export const StepsContext = createContext<any>(null);
export const HeaderFooterContext = createContext<any>(null);
export const OpenSnakBarContext = createContext<any>(null);
export const SnakBarMessageContext = createContext<any>(null);
export const LessonStepContext = createContext<any>(null);

export const UserContextProvider = ({children}: ContextProviderProps) => {
  const [authUser, setAuthUser] = useState<any>();
  const [cognitoUser, setCognitoUser] = useState<any>();
  const value = {
    setAuthUser: setAuthUser,
    authUser: authUser,
    cognitoUser,
    setCognitoUser
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const LessonStepProvider = ({children}: ContextProviderProps) => {
  const [stepFlag, setStepFlag] = useState(false);

  const triggerStep = (flag:boolean) => {
    setStepFlag(flag);
  };

  return (
    <LessonStepContext.Provider value={{ stepFlag, triggerStep }}>
      {children}
    </LessonStepContext.Provider>
  );

}

export const StepsContextProvider = ({ children }: ContextProviderProps) => {
  const [stepsData, setStepsData] = useState<any>(
    JSON.parse(localStorage.getItem("StepsData") || "{}")
  );

  return (
    <StepsContext.Provider value={{ stepsData, setStepsData }}>
      {children}
    </StepsContext.Provider>
  );
};


export const HeaderFooterContextProvider = ({
  children,
}: ContextProviderProps) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <HeaderFooterContext.Provider value={{ show, setShow }}>
      {children}
    </HeaderFooterContext.Provider>
  );
};
export const OpenSnakBarContextProvider = ({
  children,
}: ContextProviderProps) => {
  const [openSnakBar, setOpenSnakBar] = useState<boolean>(false);

  return (
    <OpenSnakBarContext.Provider value={{ openSnakBar, setOpenSnakBar }}>
      {children}
    </OpenSnakBarContext.Provider>
  );
};
export const SnakBarMessageContextProvider = ({
  children,
}: ContextProviderProps) => {
  const [snakBarMessage, setSnakBarMessage] = useState<any>({
    severity: "",
    msg: "",
  });

  return (
    <SnakBarMessageContext.Provider
      value={{ snakBarMessage, setSnakBarMessage }}
    >
      {children}
    </SnakBarMessageContext.Provider>
  );
};
