import { useCallback, useContext, useEffect, useState } from "react";
import RoundedButton from "../RoundedButton";
import {
  OpenSnakBarContext,
  SnakBarMessageContext,
  UserContext,
} from "../../Context/Provider";
import CurrentSaving from "../CurrentSaving"
import { Progress } from "../Progress";

import DashBoardHeader from "./DashBoardHeader";
import DashBoardTasks from "./DashBoardTasks";
import DisClaimer from "./DisClaimer";

import MyInfo from "./MyInfo";
import { getAPIData, postAPIData } from "../../utils/apiClient";
import { changePassword } from "../../cognitoAuth";

import { calculateSocialSecurityAdjustments} from "../../socialSecurityMath"
import MagicContainer from "../MagicContainer";
import {figureSavingsPerYear} from "../../magicMath"
import { Constants, Rates} from "../../common/settings";


const AGE = 36
const SOCIALSECURITYBASE=2400
const CURRENTSAVINGS=100000
const CURRENTINCOME=60000


export const getPlanData = () => {

  let data = localStorage.getItem("MagicPlan");

  if (data) return JSON.parse(data);
  return {
    futureSaving: 1000,
    retirementAge: 67,
    postRetirementJob: 0,
    Housing: "Same as now",
    Lifestyle: "Same as now",
    Income:CURRENTINCOME,
    currentSavings: CURRENTSAVINGS,
    savingsBreakdown: figureSavingsPerYear(31415,
      CURRENTSAVINGS,20,20,Rates.INTEREST_RATE,Rates.INTEREST_RATE_AFTER_RETIREMENT,Rates.INFLATION_RATE),
    ageBounds: {
      LifeExpectancy:Constants.LIFEEXPECTANCY,
      Age:AGE},
    socialSecurityInfo:calculateSocialSecurityAdjustments(SOCIALSECURITYBASE,67,AGE,0)
  };
};

const DashBoard = () => {
  const {setOpenSnakBar} = useContext(OpenSnakBarContext);
  const {authUser, setAuthUser} = useContext(UserContext);
  const {setSnakBarMessage} = useContext(
      SnakBarMessageContext
  );

  const [data, setData] = useState<any>(getPlanData());
  const [edit, setEdit] = useState<boolean>(false);
  const [editInfo, setEditInfo] = useState<boolean>(false);
  const [header, setHeader] = useState<any>("My progress");

  useEffect(() => {
    setData((current: any) => ({
      ...current,
      email: authUser?.email,
      fullName: authUser?.full_name,
      social_security_estimate: authUser?.snapshot?.retirement?.social_security_estimate,
      age: authUser?.age,
      yearly_income: authUser?.yearly_income,
    }));
  }, [authUser])

  async function updateUser() {
    const user = await getAPIData({url: "v1/users"});
    if (user?.data?.success) {
      setAuthUser(user.data.user);
    }
  }
  useEffect(() => {
    // Refresh authuser when we get here
    updateUser()
  }, [])



  const returnTotalSavings = useCallback(() => {
    //Not function right now; return 0
    if (!authUser?.savings) {
      return 0;
    }

    let totalSavings = 0;
    //totalSavings = authUser?.savings[0].amount || 0;
    // for (let i = 0; i < authUser?.savings?.length; i++) {
    //   totalSavings = totalSavings + Number(authUser.savings[i].amount) || 0;
    // }
    return totalSavings;
  }, [authUser]);

  const handleInfoUpdate = async () => {
    try {
      if (!edit) {
        console.log('edit is false')
        if (data.password?.length && data.confirmPassword?.length) {
          try {
            await changePassword(data.password, data.newPassword);
          } catch (e: any) {
            setOpenSnakBar(true);
            return setSnakBarMessage({
              severity: "error",
              msg: e.message || "Failed to change password",
            });
          }
        }
        const infoData = {
          age: Number(data.age) || undefined,
          full_name: data?.fullName || "",
          yearly_income: Number(data.yearly_income),
        };
        await postAPIData({
          url: "v1/users/update-ss",
          data: {
            social_security_estimate: data.social_security_estimate,
          },
        });
        await postAPIData({
          url: "v1/users/update-info",
          data: infoData,
        });

        setOpenSnakBar(true);
        setSnakBarMessage({
          severity: "success",
          msg: "Updated Successfully",
        });

        const user = await getAPIData({ url: "v1/users" });
        if (user?.data?.success) {
          setAuthUser(user.data.user);
        }
      }
      setEditInfo(false);
    } catch (e) {
      console.log(e);
      setOpenSnakBar(true);
      setSnakBarMessage({
        severity: "error",
        msg: "Failed to update user",
      });
    }
  };

  return (
    <div className="max-w-[1350px] px-[15px] w-full ml-auto mr-auto font-satoshi">
      <div className="shadow-card rounded-[25px] mt-[30px] lg:mt-[120px] mb-[30px] lg:mb-[78px] lg:px-[70px] p-[30px] lg:py-[53px] bg-white overflow-hidden ">
        {header === "My plan" ? (
          <>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2">
                {/* <h1 className="text-[#0B4547] text-[36px] lg:text-[62px] font-bold tracking-[-0.02em] mb-[20px]  text-center ">                   */}
                <h1 className="text-[#0B4547] text-[36px] lg:text-[62px] font-bold tracking-[-0.02em] mb-[30px] lg:mb-[50px] text-center">
                {authUser?.full_name ? addPossessiveSuffix(authUser?.full_name) : ""} Dashboard
                </h1>
                <DashBoardHeader header={header} setHeader={setHeader} />
              </div>
              <div className="w-full lg:w-1/2 mt-[30px] lg:mt-0">
                <div className="flex justify-end">
                  <div className="lg:max-w-[460px]">
                    {/*<DisClaimer*/}
                    {/*  edit={edit}*/}
                    {/*  setEdit={setEdit}*/}
                    {/*  data={data}*/}
                    {/*  setData={setData}*/}
                    {/*/>*/}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 className="text-[#0B4547] text-[36px] lg:text-[62px] font-bold tracking-[-0.02em] mb-[30px] lg:mb-[50px] text-center">
              {authUser?.full_name ? addPossessiveSuffix(authUser?.full_name) : ""} Dashboard
            </h1>

            <DashBoardHeader header={header} setHeader={setHeader} />
          </>
        )}

        {header === "My progress" && (
          <div className="pt-[36px] flex flex-wrap">
            <div className="md:w-[273px] w-full  mb-[50px] md:mb-0">
              <DashBoardTasks
                taskContent={[
                  {
                    title: "Which retirement account do I open?",
                    Date: "Coming soon",
                    niaId: authUser?.full_name ? authUser.full_name[0] : "",
                    lessonId: "chooseAccount",
                    complete:authUser?.lessons?.account_choice
                    // doneBy: "DEV",
                  },
                  {
                    title: "How much should I save?",
                    Date: "Coming soon",
                    niaId: authUser?.full_name ? authUser.full_name[0] : "",
                    lessonId: "saveAmount",
                    complete:authUser?.lessons?.pct_choice

                    // doneBy: "DEV",
                  },
                  {
                    title: "How do I invest what I have saved?",
                    Date: "Coming soon",
                    niaId: authUser?.full_name ? authUser.full_name[0] : "",
                    lessonId: "investPlan",
                    complete:authUser?.lessons?.invest_profile
                    // doneBy: "DEV",
                  },

                ]}
              />
            </div>
            <div className="md:w-[calc(100%-273px)] w-full flex flex-wrap justify-between xl:pl-[80px]  md:px-[15px]">
              <div className="xl:w-[329px]  w-full mb-[50px] md:mb-0">
                <Progress goalAmount={getPlanData().futureSaving} currentValue={returnTotalSavings()} unit={"$"} user={authUser} />
              </div>
              <div className="xl:w-[calc(100%-329px)] xl:pl-[107px]   w-full">
              <CurrentSaving amount={returnTotalSavings()} savings={authUser?.savings}/>
              </div>



            </div>
          </div>
        )}
        {header === "My info" && (
          <>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-2/3">
                <div className="lg:mb-[60px] my-[30px] lg:mt-[30px]">
                  <MyInfo edit={editInfo} data={data} setData={setData} />
                </div>
              </div>
              <div className="w-full lg:w-1/3 mt-[30px] lg:mt-0">
                <div className="flex justify-end">
                  <div className="lg:max-w-[460px] text-right">
                    <DisClaimer
                      edit={editInfo}
                      setEdit={setEditInfo}
                      data={data}
                      setData={setData}
                      containerClassname=""
                      questionImgClassname="ml-auto"
                      editContainerClassname="flex justify-end"
                      saveText="Save"
                      buttonPlacement="top"
                      onSave={handleInfoUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {header === "My plan" && (
          <MagicContainer dashboard={true}/>
        )}


      </div>
    </div>
  );
};

function addPossessiveSuffix(name: string) {
  let suffix = '';
  if (name.endsWith('s')) {
    suffix = "'";
  } else {
    suffix = "'s";
  }
  return name + suffix;
}

export default DashBoard;
