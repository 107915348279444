import React from 'react'
import {useState} from 'react'

const Footer = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
      };

  return (
    <div className="bg-[#0B4547] lg:py-[56px] py-[30px]">
    <div className="max-w-[1350px] px-[15px] w-full ml-auto mr-auto">
        <div className="flex justify-between flex-wrap">
            <div className="lg:w-[688px] w-full text-center lg:text-left">
            <p className="text-base text-white font-satoshi font-medium mb-[10px] text-left">Disclaimer </p>
             <p className="text-white font-satoshi text-[8px] text-left">Fee-Based Investment Advisory offered through Nia Growth, Inc., a pending SEC Registered Investment Advisor. SEC registration does not imply a certain level of skill or training. For information pertaining to the registration status of Nia Growth, Inc., please contact us or visit the             Investment Advisor Public Disclosure web site at www.adviserinfo.sec.gov. For additional             information, including fees and services, see our disclosure statement as set forth on Form CRS and Form ADV Part 2. Please read the disclosure statement carefully before investing. For other important website disclosures please visit www.niagrowth.com/disclosures.<button className="ml-2 text-white font-satoshi text-[10px] text-left underline" onClick={toggleExpand}>
                {isExpanded ? 'Read Less' : 'Read More'}</button></p>
                         
            <div>
            {isExpanded && 
            <div>
            <p className="text-white font-satoshi text-[8px] text-left">THE CONTENT OF THIS SITE IS FOR GENERAL EDUCATIONAL PURPOSES ONLY. IT IS IN NOT INTENDED TO PROVIDE FIDUCIARY INVESTMENT ADVICE. ALL INVESTMENTS INVOLVE RISK AND THE PAST PERFORMANCE OF A SECURITY, OR FINANCIAL PRODUCT DOES NOT GUARANTEE FUTURE RESULTS OR RETURNS. THERE IS ALWAYS THE POTENTIAL OF LOSING MONEY WHEN YOU INVEST IN SECURITIES, OR OTHER FINANCIAL PRODUCTS. INVESTORS SHOULD CONSIDER THEIR INVESTMENT OBJECTIVES AND TOLERANCE FOR RISK CAREFULLY BEFORE INVESTING.</p>
            <p className="text-white font-satoshi text-[8px] text-left">Note: Certain Nia Growth products, services and features discussed within this site are currently
            in development and will be available soon.
            Investment products are:
            • Not A Deposit
            • Not Bank Guaranteed
            • Not FDIC/NCUA Insured
            • Not Insured By Any Federal Government Agency
            • May Lose Value</p>
            <p className="text-white font-satoshi text-[8px] text-left">Third party marks are the property of their respective owners; all other marks are the property
            of Nia Growth.</p>
            </div>
            }
            </div>

            </div>
            <div className="w-full lg:w-auto mt-[30px] lg:mt-0">
                <ul className="flex items-center mb-[30px] justify-center">
                    <li className="mr-[30px]">
                        <a className="text-base cursor-pointer text-white font-satoshi font-medium tracking-[-0.02em] hover:underline">
                        Careers
                        </a>
                    </li>
                    <li className="mr-[30px]"> 
                    <a className="text-base cursor-pointer text-white font-satoshi font-medium tracking-[-0.02em] hover:underline">
                        Partners
                        </a>
                    </li>
                    <li>
                    <a className="text-base cursor-pointer text-white font-satoshi font-medium tracking-[-0.02em] hover:underline">
                        Contact Us
                        </a>
                    </li>
                </ul> 
                <ul className="flex items-center justify-center">
                <li className='mr-[22px]'>
                    <a className="hover:opacity-75 cursor-pointer">
                       <img src='/assets/facebook.svg' alt='facebook'/>
                        </a>
                    </li>
                    <li className='mr-[22px]'>
                    <a className="hover:opacity-75 cursor-pointer">
                       <img src='/assets/instagram.svg' alt='instagram'/>
                        </a>
                    </li>
                    <li className='mr-[22px]'>
                    <a className="hover:opacity-75 cursor-pointer">
                       <img src='/assets/linkedin.svg' alt='linkedin'/>
                        </a>
                    </li>
                    <li>
                    <a className="hover:opacity-75 cursor-pointer">
                       <img src='/assets/twitter.svg' alt='twitter'/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
  );
}

export default Footer