import React from "react";
import InfoIcon from "../../Icons/InfoIcon";
import PlanIcon from "../../Icons/PlanIcon";
import ProgressIcon from "../../Icons/ProgressIcon";
import ReactGA from 'react-ga4'

const DashBoardHeader = ({
  header,
  setHeader = () => {},
}: {
  header?: any;
  setHeader?: Function;
}) => {
  return (
    <ul
      className={`flex items-center overflow-x-auto customScroll ${
        header !== "My progress" && `ml-[-28px]`
      }`}
    >
      <li
        onClick={() => {
          if (process.env.REACT_APP_AWS_BRANCH === "main") {
            ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "Progress" });
          }
          setHeader("My progress");
        }}
      >
        <a
          className={`whitespace-nowrap flex ${
            header === "My progress" ? "bg-[#0B4547] text-white" : ""
          } cursor-pointer rounded-[102px] after:h-[1px] after:bg-[#0B4547] transition-all after:w-[calc(100%-56px)] after:left-0 after:right-0 after:bottom-0 after:mx-auto after:absolute relative after:content-['']  px-[28px] py-[11px]  items-center text-base font-medium font-satoshi tracking-[-0.02em] text-[#161616]`}
        >
          <span className="mr-[14px]">My progress</span>
          <ProgressIcon />
        </a>
      </li>
      <li
        onClick={() => {
          if (process.env.REACT_APP_AWS_BRANCH === "main") {
            ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "My Plan" });
          }
          setHeader("My plan");
        }}
      >
        <a
          className={`whitespace-nowrap flex ${
            header === "My plan" ? "bg-[#0B4547] text-white" : ""
          } cursor-pointer rounded-[102px] after:h-[1px] after:bg-[#0B4547] after:w-[calc(100%-56px)] transition-all after:left-0 after:right-0 after:bottom-0 after:mx-auto after:absolute relative after:content-['']  px-[28px] py-[11px]  items-center text-base font-medium font-satoshi tracking-[-0.02em] text-[#161616]`}
        >
          <span className="mr-[14px]">My plan</span> <PlanIcon />
        </a>
      </li>
      <li
        onClick={() => {
          if (process.env.REACT_APP_AWS_BRANCH === "main") {
            ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "My Info" });
          }
          setHeader("My info");
        }}
      >
        <a
          className={`whitespace-nowrap flex ${
            header === "My info" ? "bg-[#0B4547] text-white" : ""
          }  cursor-pointer rounded-[102px] after:h-[1px] after:bg-[#0B4547] after:w-[calc(100%-56px)] transition-all after:left-0 after:right-0 after:bottom-0 after:mx-auto after:absolute relative after:content-['']  px-[28px] py-[11px]  items-center text-base font-medium font-satoshi tracking-[-0.02em] text-[#161616]`}
        >
          <span className="mr-[14px]">My info</span>
          <InfoIcon />
        </a>
      </li>
    </ul>
  );
};

export default DashBoardHeader;
